// import React from "react";
const Constants = {
  ASSESSMENT_NEW: "New",
  ASSESSMENT_SAVED: "Not Completed",
  ASSESSMENT_COMPLETED: "Completed",
  ASSESSMENT_DELETED: "Deleted",
  // VERSION: "2.267", //2.142 //2.158
  // VER_ADULT : "2.36",
  // VER_ADOLESCENT : "2.302",
  VER_ASI: "2.244",
  VER_ADULT: "2.656",
  VER_ADOLESCENT: "2.345",
  SUPERUSER_EMAIL:
    "mkontsevoy@gmail.com,hafnow@gmail.com,borisgill@gmail.com,johnh@parallelslincoln.com,superadmin@herdmanhealth.com,superadmins@herdmanhealth.com,astein@eccentex.com,kluss242@gmail.com",
  MAXSIZE_EDITOR_ASI: 1000,
  MAXSIZE_EDITOR_ASI_FORM: [
    {
      id: "G-COMMENT",
      section: "GI",
      comment: "gi-comment",
      symbols: 870,
      pages: [{ page: "g_com", symb: 870 }],
    },
    {
      id: "M-COMMENT",
      section: "MS",
      comment: "ms-comment",
      symbols: 800,
      pages: [{ page: "m_com", symb: 800 }],
    },
    {
      id: "E-COMMENT",
      section: "ES",
      comment: "es-comment",
      symbols: 2000,
      pages: [
        { page: "e_com", symb: 1000 },
        { page: "e1_com", symb: 1000 },
      ],
    },
    {
      id: "D-COMMENT",
      section: "DS",
      comment: "ds-comment",
      symbols: 2350,
      pages: [
        { page: "d_com", symb: 1100 },
        { page: "d1_com", symb: 1250 },
      ],
    },
    {
      id: "L-COMMENT",
      section: "LS",
      comment: "ls-comment",
      symbols: 1800,
      pages: [
        { page: "l_com", symb: 1100 },
        { page: "l1_com", symb: 700 },
      ],
    },
    {
      id: "F-COMMENT",
      section: "FS",
      comment: "fs-comment",
      symbols: 1500,
      pages: [{ page: "f1_com", symb: 1500 }],
    },
    {
      id: "F1-COMMENT",
      section: "FS",
      comment: "fs1-comment",
      symbols: 2200,
      pages: [
        { page: "f_com", symb: 1200 },
        { page: "f2_com", symb: 1000 },
      ],
    },
    {
      id: "P-COMMENT",
      section: "PSS",
      comment: "pss-comment",
      symbols: 1700,
      pages: [
        { page: "p_com", symb: 1100 },
        { page: "p1_com", symb: 600 },
      ],
    },
  ],
  SUPERUSER_MODE_PROTECTED: "Propected",
  SUPERUSER_MODE_NORMAL: "Normal",
  CURRENT_SUPERUSER_MODE: "Propected",
  PROTECTED_FIELDS: [
    "firstname",
    "middlename",
    "lastname",
    "gender",
    "sex",
    "gender_explain",
    "marriage",
    "email_client",
    "street",
    "city",
    "state",
    "zip_code",
    "country",
    "county",
    // "evaluator",
    "phone",
    "ssn",
    "dob",
  ],
  SECTION_SHORT_MIDDLENAME: [
    /*"DEMO", "PP",*/ "M",
    "EDO",
    "DAH",
    "LEG",
    "FR",
    "PS",
    "SUM",
    "AC",
    "MSE",
    "CI",
    "SAW",
    "SUG",
  ],
  PROTECTED_SYMBOLS: "***",
  SHORT_VIEW_MIDDLENAME: "SHORT",
  FULL_VIEW_MIDDLENAME: "FULL",
  COURSE: 10,
  REMOTESTATUS_IN_PROGRESS: "progress",
  REMOTESTATUS_DONE: "done",
  MAX_RANK: 3,
  sectionsWhichIgnoreForRemote: [
    "AC",
    "SUM",
    "OPTIONAL",
    "MSE",
    "SAW",
    "CI",
    "SUG",
    "PL",
    "CMG"
  ],
  sectionsWhichIgnoreForProtected: ["DEMO", "FR", "SUM", "SUG"],
  // sectionsToIgnore: ["AC", "SUM", "OPTIONAL"],
  sectionsToIgnore: [/*"SUM",*/ "OPTIONAL", "SUG"/*,"PL","CMG"*/],
  sectionsForCheck: ["AC", "MSE", "SAW", "CI","PL","CMG"],
  fieldToIgnore: [
    "CI1-2",
    "CI1-3",
    "CI1-4",
    "CI1-5",
    "CI1-6",
    "CI1-7",
    "CI1-8",
    "CI1-9",
    "ES1-1",
    "ES2-1",
    "FS0-1-1-C",
    "FS0-1-2-C",
    "FS0-1-3-C",
    "FS0-2-1-C",
    "FS0-2-2-C",
    "FS0-2-3-C",
    "FS0-3-1-C",
    "FS0-3-2-C",
    "FS0-3-3-C",
    "FS0-4-1-C",
    "FS0-4-2-C",
    "FS0-4-3-C",
    "FS0-5-1-C",
    "FS0-5-2-C",
    "FS0-5-3-C",
    "FS0-6-1-C",
    "FS0-6-2-C",
    "FS0-6-3-C",
    "FS0-7-1-C",
    "FS0-7-2-C",
    "FS0-7-3-C",
    "FS0-8-1-C",
    "FS0-8-2-C",
    "FS0-8-3-C",
    "FS0-9-1-C",
    "FS0-9-2-C",
    "FS0-9-3-C",
    "FS0-10-1-C",
    "FS0-10-2-C",
    "FS0-10-3-C",
    "FS0-11-1-C",
    "FS0-11-2-C",
    "FS0-11-3-C",
    "FS0-12-1-C",
    "FS0-12-2-C",
    "FS0-12-3-C",
    "SUM-ADD1",
    "SUM-ADD2",
    "SUM-ADD3",
    "SUM-ADD4",
    "SUM-ADD5",
    "SUM-ADD6",
    "SUM-ADD7",
    "SUM-ADD8",
    "SUM-ADD9",
    "SUM-ADD10",
    "SUM-ADD11",
    "SUM-ADD12",
    "SUM-ADD13",
    "SUM-ADD14",
    "SUM-ADD15",
    "SUM-ADD16",
    "SUM-ADD17",
    "SUM-ADD18",
    "SUM-ADD19",
    "SUM-ADD20",
    "ED15-C-ADOL1",
    "ED16-C-ADOL1",
    "ED17-C-ADOL1",
    "ED18-C-ADOL1",
    "ED19-C-ADOL1",
    "ED20-C-ADOL1",
    "ED21-C-ADOL1",
    "ED22-C-ADOL1",
    "ED23-C-ADOL1",
    "ED24-C-ADOL1",
    "DA17-C-ADOL1",
    "DA18-C-ADOL1",
    "DA19-C-ADOL1",
    "FR22-C-ADOL1",
    "FR23-C-ADOL1",
    "FR24-C-ADOL1",
    "FR25-C-ADOL1",
    "FR26-C-ADOL1",
    "FR27-C-ADOL1",
    "FR28-C-ADOL1",
    "FR29-C-ADOL1",
    "FR30-C-ADOL1",
    "FR31-C-ADOL1",
    "FR32-C-ADOL1",
    "FR33-C-ADOL1",
    "FR34-C-ADOL1",
    "FR35-C-ADOL1",
    "FR36-C-ADOL1",
    "FR37-C-ADOL1",
    "FR66-C-ADOL1",
    "FR68-C-ADOL1",
    "FR69-C-ADOL1",
    "FR70-C-ADOL1",
    "FR71-C-ADOL1",
    "FR72-C-ADOL1",
    "FR73-C-ADOL1",
    "FR74-C-ADOL1",
    "FR75-C-ADOL1",
    "FR76-C-ADOL1",
    "FR77-C-ADOL1",
    "PS01-C-ADOL1",
    "PS02-C-ADOL1",
    "PS03-C-ADOL1",
    "PS04-C-ADOL1",
    "PS05-C-ADOL1",
    "PS06-C-ADOL1",
    "PS07-C-ADOL1",
    "PS08-C-ADOL1",
    "PS09-C-ADOL1",
    "PS10-C-ADOL1",
    "PS11-C-ADOL1",
    "PS12-C-ADOL1",
    "PS13-C-ADOL1",
    "PS14-C-ADOL1",
    "PS15-C-ADOL1",
    "PS16-C-ADOL1",
    "PS17-C-ADOL1",
    "PS18-C-ADOL1",
    "PS19-C-ADOL1",
    "PS20-C-ADOL1",
    "PS21-C-ADOL1",
    "PS22-C-ADOL1",
    "PS23-C-ADOL1",
    "PS24-C-ADOL1",
    "PS25-C-ADOL1",
    "PS26-C-ADOL1",
  ],
  MAJOR_DIFF_VER: "major",
  MINOR_DIFF_VER: "minor",
  EQUAL_VER: "equal",
  TYPEASSESSMENT: [
    {
      id: 0,
      atype: "SUBS",
      description: "Substance Use Evaluation",
      actionType: "SET_SUE_PATTERN_ASSESSMENT",
    },
    {
      id: 1,
      atype: "MH",
      description: "Mental Health Evaluation",
      actionType: "SET_MHA_PATTERN_ASSESSMENT",
    },
    {
      id: 2,
      atype: "CO-OC",
      description: "Co-Occurring Evaluation",
      actionType: "SET_COE_PATTERN_ASSESSMENT",
    },
    {
      id: 3,
      atype: "ASI",
      description: "ASI",
      actionType: "SET_ASI_PATTERN_ASSESSMENT",
    },
    {
      id: 4,
      atype: "ASILite",
      description: "ASI Lite",
      actionType: "SET_ASILITE_PATTERN_ASSESSMENT",
    },
    {
      id: 5,
      atype: "",
      description: "Substance Use Evaluation",
      actionType: "SET_SUE_PATTERN_ASSESSMENT",
    },
  ],
  // ["DEMO","PP","M","EDO","DAH","LEG","FR","PS","SUM","AC","MSE","CI","SAW"];
  SUE_PATTERN: [
    "DEMO",
    "PP",
    "M",
    "EDO",
    "DAH",
    "LEG",
    "FR",
    "PS",
    "SUM",
    "AC",
    "CI",
    "SAW",
    "SUG",
    "PL",
    "CMG"
  ],
  MHA_PATTERN: [
    "DEMO",
    "PP",
    "M",
    "EDO",
    "DAH",
    "LEG",
    "FR",
    "PS",
    "SUM",
    "MSE",
    "CI",
    "SAW",
    "SUG",
    "PL",
    "CMG"
  ],
  COE_PATTERN: [
    "DEMO",
    "PP",
    "M",
    "EDO",
    "DAH",
    "LEG",
    "FR",
    "PS",
    "SUM",
    "AC",
    "MSE",
    "CI",
    "SAW",
    "SUG",
    "PL",
    "CMG"
  ],

  FIELDRULES: [
    {
      id: 1,
      act: "FR16",
      maskField: "with ",
    },
    {
      id: 2,
      act: "ED-NEW52",
      maskField: "due to ",
    },
    {
      id: 3,
      act: "ED-NEW54",
      maskField: "due to ",
    },
    {
      id: 3,
      act: "ED1-ADOL1-1",
      maskField: "due to ",
    },
  ],
  PERIOD_IN_DAY_FOR_CASHFLOW_ANALIZE: 10,
  /*
  CASHFLOW_COLUMNS_FOR_SORT: {
    CASHFLOW_SORT_CREATED_UP: {
      Param_sort: "created",
      param_up_down: "Low to High",
      icon: <i class="fas fa-chevron-circle-up"></i>,
    },
    CASHFLOW_SORT_CREATED_DOWN: {
      Param_sort: "created",
      param_up_down: "High to Low",
      icon: <i class="fas fa-chevron-circle-down"></i>,
    },
    CASHFLOW_SORT_BALANCE_UP: {
      Param_sort: "balance",
      param_up_down: "Low to High",
      icon: <i class="fas fa-chevron-circle-up"></i>,
    },
    CASHFLOW_SORT_BALANCE_DOWN: {
      Param_sort: "balance",
      param_up_down: "High to Low",
      icon: <i class="fas fa-chevron-circle-down"></i>,
    },
  },
  */

  EVENT_NOTIFICATION_SIGNED_BUT_NOT_VERIFIED:
    "Organization signed but not verified",
  EVENT_NOTIFICATION_NON_WORKING_LONG_TIME:
    " Organization signed, verified but never using for a long time",
  EVENT_NOTIFICATION_USE_FREE_CREDITS_BUT_STOP_USING:
    "Organization signed, use free credits (in full or partial) but stop using",
  EVENT_NOTIFICATION_ONLY_ONE_CREDIT_LEFT: "Only one credit left",
  EVENT_NOTIFICATION_FREE_EMAIL_FORMAT: "Free email format",
  EVENT_NOTIFICATION_UPGRADE_ACTION_FORMAT: "Upgrade action notification",

  TRANSFORMRULE_RIGHT_PANEL: 1,
  TRANSFORMRULE_LEFT_PANEL: 0,
  EMPTY_TRULE : "_EMPTY_",
  MAX_PAGESELECTION : 3,
  PRICE_SUBSCRIPTION : 75,
  BASIS_UPDATE_PROGRESS : 30,
  K_MASSIVE_PROGRESS : 50,
  ASI_DOC_FORM : "asi",
  ASI_DOC_NARRATIVE : "narrative",
  DANGER_SECTION : 0,
  NONDANGER_SECTION : 0,
  FIELD_DANGER : 0,
  FIELD_WARNING : 1,
  FIELD_SUCCESS : 2,
  PAYMENT_REFERRAL_CREDITS : "credits",
  PAYMENT_REFERRAL_SUBSCRIPTION : "subscription",
  DEFAULT_EMAIL_ACCOUNT: "hafsvc@gmail.com",
};

const ASI_OUTPUT_DOCUMENT = [
  {
    id: 0,
    type: Constants.ASI_DOC_FORM,
    description: "Form",
  },
  {
    id: 1,
    type: Constants.ASI_DOC_NARRATIVE,
    description: "Narrative",
  }
];

const ruleOperations = {
  radioOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
    { id: 2, operation: "NE" },
  ],
  selectOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
    { id: 2, operation: "NE" },
    { id: 3, operation: "GTE" },
    { id: 4, operation: "LTE" },
  ],
  numberOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
    { id: 2, operation: "NE" },
    { id: 3, operation: "GTE" },
    { id: 4, operation: "LTE" },
  ],
  yearOper: [
    { id: 0, operation: "" },
    // { id: 1, operation: "EQU" },
    // { id: 2, operation: "NE" },
    { id: 1, operation: "OVER_YEARS" },
    { id: 2, operation: "LESS_YEARS" },
  ],
};

const transformruleOperations = {
  // radioOper: [
  //   { id: 0, operation: "" },
  //   { id: 1, operation: "EQU" },
  //   { id: 2, operation: "NE" },
  // ],
  // selectOper: [
  //   { id: 0, operation: "" },
  //   { id: 1, operation: "EQU" },
  //   { id: 2, operation: "NE" },
  //   { id: 3, operation: "GTE" },
  //   { id: 4, operation: "LTE" },
  // ],
  // numberOper: [
  //   { id: 0, operation: "" },
  //   { id: 1, operation: "EQU" },
  //   { id: 2, operation: "NE" },
  //   { id: 3, operation: "GTE" },
  //   { id: 4, operation: "LTE" },
  // ],
  // yearOper: [
  //   { id: 0, operation: "" },
  //   { id: 1, operation: "OVER_YEARS" },
  //   { id: 2, operation: "LESS_YEARS" },
  // ],

  radioOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
  selectOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
  numberOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
  yearOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
  contentOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
  defaultOper: [
    { id: 0, operation: "" },
    { id: 1, operation: "EQU" },
  ],
};

const templateNote = [
  {
    id: 1,
    event: Constants.EVENT_NOTIFICATION_SIGNED_BUT_NOT_VERIFIED,
    subject: `Verify Account Reminder`,
    // title: `Thanks for signing up, {{firstname}}`,
    message:
      "Thanks for signing up, {{firstname}}.<br />We noticed you haven't verified yet. Please verify your email address for {{organization}} to start using HerdmanHealth.",
  },
  {
    id: 2,
    event: Constants.EVENT_NOTIFICATION_NON_WORKING_LONG_TIME,
    subject: `Check-in`,
    // title: `Hi HAF Administrators from {{organization}}`,
    message: `Hi HAF Administrators from {{organization}}.<br />It’s been a some time since you started your trial with HerdmanHealth. HerdmanHealth is an intuitive software that guides providers through a Behavior Health Assessment and generates a professional-looking narrative. It follows the Herdman Assessment Form (HAF) process developed by Dr. John Herdman over his 46 years experience in counseling and running a mental health and addiction center. Our practitioners save an average of 90 minutes per assessment.`,
  },
  {
    id: 3,
    event: Constants.EVENT_NOTIFICATION_USE_FREE_CREDITS_BUT_STOP_USING,
    subject: `Check-in`,
    // title: `Hi HAF Administrators from {{organization}}`,
    message: `Hi HAF Administrators from {{organization}}.<br />It’s been a some time since you started your trial with HerdmanHealth. HerdmanHealth is an intuitive software that guides providers through a Behavior Health Assessment and generates a professional-looking narrative. It follows the Herdman Assessment Form (HAF) process developed by Dr. John Herdman over his 46 years experience in counseling and running a mental health and addiction center. Our practitioners save an average of 90 minutes per assessment.`,
  },
  {
    id: 4,
    event: Constants.EVENT_NOTIFICATION_ONLY_ONE_CREDIT_LEFT,
    subject: `1 credit left`,
    // title: `Hi HAF Administrators from {{organization}}`,
    message: `Hi HAF Administrators from {{organization}}.<br />We're just sending a reminder that you have only 1 credit left in your HerdmanHealth account. Please login to your account and go to Settings to purchase more. Thank you!`,
  },
  {
    id: 5,
    event: Constants.EVENT_NOTIFICATION_FREE_EMAIL_FORMAT,
    subject: ``,
    // title: `Hi HAF Administrators from {{organization}}`,
    message: ``,
  },
  {
    id: 6,
    event: Constants.EVENT_NOTIFICATION_UPGRADE_ACTION_FORMAT,
    subject: `Herdman Health Support Notifications`,
    // title: `Hi HAF Administrators from {{organization}}`,
    message: `Hi HAF Administrators from {{organization}}.<br />We made some critical fixes in the Herdman Assessment Forms to improve the information in the narrative reports. For a recently made Assessment an Update Narrative action will be required to get the new functionality. This update requires that you click on the pencil (edit) button and update your assessment.  Thank you!`,
  },
];

const patternClientInfoContent = [
  { id: 1, key: "assessment", title: "<b>Assessment:</b>", content: "" },
  { id: 2, key: "firstname", title: "<b>Firstname:</b>", content: "" },
  { id: 3, key: "middlename", title: "<b>Middlename:</b>", content: "" },
  { id: 4, key: "lastname", title: "<b>Lastname:</b>", content: "" },
  { id: 5, key: "gender", title: "<b>Gender:</b>", content: "" },
  { id: 6, key: "atype", title: "<b>Type:</b>", content: "" },
  { id: 7, key: "status", title: "<b>Status:</b>", content: "" },
];

const shortNameForExport = [
  "pl1-alcohol-use-disorder-1",
  "pl2-drug-use-disorder-1",
  "pl3-abuse-neglect-1",
  "pl4-anger-1",
  "pl5-anxiety-1",
  "pl6-decision-making-1",
  "pl7-behavior-problems-1",
  "pl8-communication-skills-1",
  "pl9-crime-criminal-thinking-1",
  "pl10-depression-1",
  "pl11-expression-of-feelings-1",
  "pl12-family-conflict-1",
  "pl13-grief-and-loss-1",
  "pl14-harm-to-self-or-others-1",
  "pl15-health-issues-1",
  "pl16-parenting-1",
  "pl17-personal-hygiene-and-self-care-1",
  "pl18-relationships-1",
  "pl19-self-image-1",
  "pl20-sleep-problems-1",
  "pl21-social-skills-1",
  "pl22-stress-1",
  "pl23-suicide-1",
  "pl24-trauma-1",
];

const needRuleClarification = [
  "ed16a",
  "ed16b",
  "ed16c",
  "ed16d",
  "ed16e",
  "ed-new32",
  "ps1",
  "ps2",
  "ps5",
  "ps5-1",
  "ps4",
  "ps4-1",
  "ps11",
  "ps11-1",
  "ps10",
  "ps10-1",
  "ps6",
  "ps6-1",
  "ps9",
  "ps9-1",
  "ps8",
  "ps8-1",
  "ps12",
  "ps12-1",
  "ps7",
  "ps7-1",
  "ms10",
  "ms11",
  "pl1-alcohol-use-disorder-1",
  // "pl1-alcohol-use-disorder-2",
  // "pl1-alcohol-use-disorder-3",
  "pl2-drug-use-disorder-1",
  // "pl2-drug-use-disorder-2",
  // "pl2-drug-use-disorder-3",
  "pl3-abuse-neglect-1",
  // "pl3-abuse-neglect-2",
  // "pl3-abuse-neglect-3",
  "pl4-anger-1",
  // "pl4-anger-2",
  // "pl4-anger-3",
  "pl5-anxiety-1",
  // "pl5-anxiety-2",
  // "pl5-anxiety-3",
  "pl6-decision-making-1",
  // "pl6-decision-making-2",
  // "pl6-decision-making-3",
  "pl7-behavior-problems-1",
  // "pl7-behavior-problems-2",
  // "pl7-behavior-problems-3",
  "pl8-communication-skills-1",
  // "pl8-communication-skills-2",
  // "pl8-communication-skills-3",
  "pl9-crime-criminal-thinking-1",
  // "pl9-crime-criminal-thinking-2",
  // "pl9-crime-criminal-thinking-3",
  "pl10-depression-1",
  // "pl10-depression-2",
  // "pl10-depression-3",
  "pl11-expression-of-feelings-1",
  // "pl11-expression-of-feelings-2",
  // "pl11-expression-of-feelings-3",
  "pl12-family-conflict-1",
  // "pl12-family-conflict-2",
  // "pl12-family-conflict-3",
  "pl13-grief-and-loss-1",
  // "pl13-grief-and-loss-2",
  // "pl13-grief-and-loss-3",
  "pl14-harm-to-self-or-others-1",
  // "pl14-harm-to-self-or-others-2",
  // "pl14-harm-to-self-or-others-3",
  "pl15-health-issues-1",
  // "pl15-health-issues-2",
  // "pl15-health-issues-3",
  "pl16-parenting-1",
  // "pl16-parenting-2",
  // "pl16-parenting-3",
  "pl17-personal-hygiene-and-self-care-1",
  // "pl17-personal-hygiene-and-self-care-2",
  // "pl17-personal-hygiene-and-self-care-3",
  "pl18-relationships-1",
  // "pl18-relationships-2",
  // "pl18-relationships-3",
  "pl19-self-image-1",
  // "pl19-self-image-2",
  // "pl19-self-image-3",
  "pl20-sleep-problems-1",
  // "pl20-sleep-problems-2",
  // "pl20-sleep-problems-3",
  "pl21-social-skills-1",
  // "pl21-social-skills-2",
  // "pl21-social-skills-3",
  "pl22-stress-1",
  // "pl22-stress-2",
  // "pl22-stress-3",
  "pl23-suicide-1",
  // "pl23-suicide-2",
  // "pl23-suicide-3",
  "pl24-trauma-1",
  // "pl24-trauma-2",
  // "pl24-trauma-3",
];
const needRuleIgnore = ["mse1-1-1", "ed11a-new1"];

const needTransformruleClarification = [
  "ms10",
  "ms11",
  "es13",
  "es20",
  "es21",
  "es23",
  "es24",
  "ed16a",
  "ed-new24",
  "ed-new24a",
  "ed16b",
  "ed-new26",
  "ed-new26a",
  "ed16c",
  "ed-new28",
  "ed-new28a",
  "ed16d",
  "ed-new29",
  "ed-new29a",
  "ed16e",
  "ed-new31",
  "ed-new31a",
  "ed-new32",
  "ed-new33",
  "ed-new33a",
  "ds26",
  "ds27",
  "ds34",
  "ds35",
  "ls3",
  "ls4",
  "ls5",
  "ls6",
  "ls7",
  "ls8",
  "ls9",
  "ls10",
  "ls11",
  "ls12",
  "ls13",
  "ls14",
  "ls15",
  "ls16",
  "ls18",
  "ls19",
  "ls20",
  "ls28",
  "ls29",
  "ls31",
  "ls32",
  "fs7",
  "fs8",
  "fr14",
  "fr-new18",
  "fr14a",
  "fr-new18a",
  "fs0-1-1",
  "fs0-1-2",
  "fs0-1-3",
  "fs0-2-1",
  "fs0-2-2",
  "fs0-2-3",
  "fs0-3-1",
  "fs0-3-2",
  "fs0-3-3",
  "fs0-4-1",
  "fs0-4-2",
  "fs0-4-3",
  "fs0-5-1",
  "fs0-5-2",
  "fs0-5-3",

  "fs0-6-1",
  "fs0-6-2",
  "fs0-6-3",
  "fs0-7-1",
  "fs0-7-2",
  "fs0-7-3",
  "fs0-8-1",
  "fs0-8-2",
  "fs0-8-3",
  "fs0-9-1",
  "fs0-9-2",
  "fs0-9-3",
  "fs0-10-1",
  "fs0-10-2",
  "fs0-10-3",

  "fs0-11-1",
  "fs0-11-2",
  "fs0-11-3",
  "fs0-12-1",
  "fs0-12-2",
  "fs0-12-3",
  "fs12",
  "fs13",
  "fs14",
  "fs15",
  "fs16",
  "fs17",
  "fs37",
  "fs38",
  "ps1",
  "ps1-new2",
  "ps2",
  "ps1-new4",
  "pss1",
  "pss2",
  "pss14",
  "pss15",
  "pss16",
  "pss17",
  "pss18",
  "pss19",
  "pss20",
  "pss22",
  "pss23",
  // "ed16a",
  // "ed16b",
  // "ed16c",
  // "ed16d",
  // "ed16e",
  // "ed-new32",
  // "ps1",
  // "ps2",
  // "ps5",
  // "ps5-1",
  // "ps4",
  // "ps4-1",
  // "ps11",
  // "ps11-1",
  // "ps10",
  // "ps10-1",
  // "ps6",
  // "ps6-1",
  // "ps9",
  // "ps9-1",
  // "ps8",
  // "ps8-1",
  // "ps12",
  // "ps12-1",
  // "ps7",
  // "ps7-1",
];
const needTransformruleIgnore = ["mse1-1-1"/*, "ed11a-new1"*/];

const classArray = ["A","B","C","D","E","F","G","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
const setupState = [
  {
    role: "Administrator",
    subrole : "Supervisor",
    organization: false,
    classes: false,
    users: false,
    history: false,
    credits: false,
    recommendations: false,
    preferences: false,
    billing: false,
  },
  {
    role: "Administrator",
    subrole : "Teacher",
    organization: true,
    classes: false,
    users: true,
    history: false,
    credits: true,
    recommendations: true,
    preferences: true,
    billing: true,
  },
  {
    role: "Administrator",
    subrole : "Boss",
    organization: false,
    classes: false,
    users: false,
    history: false,
    credits: false,
    recommendations: false,
    preferences: false,
    treatment_plan: false,
    goal_and_objectivies: false,
    billing: false,
  },
  {
    role: "Counselor",
    subrole : "Student",
    organization: true,
    classes: true,
    users: true,
    history: true,
    credits: true,
    recommendations: true,
    preferences: false,
    treatment_plan: true,
    goal_and_objectivies: true,
    billing: true,
  },
  {
    role: "Guest",
    subrole : "User",
    organization: true,
    classes: true,
    users: true,
    history: true,
    credits: true,
    recommendations: true,
    preferences: true,
    treatment_plan: true,
    goal_and_objectivies: true,
    billing: true,
  },
];

const interview_sections = ["DEMO", "PP", "M", "EDO", "DAH", "LEG", "FR", "PS"];
const asi_sections = ["GI", "MS", "ES", "DS", "LS", "FS", "PSS"];
const postinterview_sections = ["AC", "MSE", "CI", "SAW", "SUM", "SUG","PL","CMG"];

const paymentScheme = {
  adultAssessment: 1,
  adolescentAssessment: 1,
  adultNarrative: 1,
  adolescentNarrative: 2,
  asiAssessment: "subscription",
  asiNarrative: "subscription",
  asiliteAssessment: "subscription",
  asiliteNarrative: "subscription",
  subscriptionPrice: 75,
};

const asiCommentField = [
  "ds-comment",
  "pss-comment",
  "gi-comment",
  "es-comment",
  "fs-comment",
  "fs1-comment",
  "ls-comment",
  "ms-comment",
];
const routeOfAdmin = [
  "DS1-1-1",
  "DS1-1-2",
  "DS1-1-3",
  "DS1-2-1",
  "DS1-2-2",
  "DS1-2-3",
  "DS1-3-1",
  "DS1-3-2",
  "DS1-3-3",
  "DS1-4-1",
  "DS1-4-2",
  "DS1-4-3",
  "DS1-5-1",
  "DS1-5-2",
  "DS1-5-3",
  "DS1-6-1",
  "DS1-6-2",
  "DS1-6-3",
  "DS1-7-1",
  "DS1-7-2",
  "DS1-7-3",
  "DS1-8-1",
  "DS1-8-2",
  "DS1-8-3",
  "DS1-9-1",
  "DS1-9-2",
  "DS1-9-3",
  "DS1-10-1",
  "DS1-10-2",
  "DS1-10-3",
  "DS1-11-1",
  "DS1-11-2",
  "DS1-11-3",
  "DS1-12-1",
  "DS1-12-2",
  "DS1-12-3",
  "DS1-13-1",
  "DS1-13-2",
  "DS1-13-3",
];

const phoneTypeGroup = ["phone"];
const hourminuteTypeGroup = ["hour_minute"];
const yearmonthTypeGroup = ["year_month"];
const yearTypeGroup = ["year"];
const numberTypeGroup = ["number", "money", "rank"];
const textTypeGroup = ["textarea", "editor", "content","text","withcode","withtitle"];
const dateTypeGroup = ["date"];
const complexTypeGroup = ["select", "radio", "checkbox"];
const codeTypeGroup = ["interviewer_code"];
const selectTypeGroup = ["select"];


const mariageHafToAsi = [
  {id:1, adult:"", asi: ""},
  {id:2, adult:"single", asi: "never married"},
  {id:3, adult:"married", asi: "married"},
  {id:4, adult:"divorced", asi: "divorced"},
  {id:5, adult:"widowed", asi: "widowed"},
  {id:6, adult:"in a committed relationship", asi: "common-law marriage"},
  {id:7, adult:"separated", asi: "separated"},
]

const exceptionActionTruleYes = [
  "LS2_RADIO_INPUT_CHANGE",
  "FS12_RADIO_INPUT_CHANGE",
  "FS13_RADIO_INPUT_CHANGE",
  "FS14_RADIO_INPUT_CHANGE",
  "FS15_RADIO_INPUT_CHANGE",
  "FS16_RADIO_INPUT_CHANGE",
  "FS17_RADIO_INPUT_CHANGE",
];

const initializeH1ToNo = [
    {type: "FS0-1-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-1-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-1-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-2-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-2-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-2-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-3-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-3-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-3-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-4-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-4-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-4-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-5-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-5-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-5-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-6-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-6-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-6-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-7-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-7-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-7-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-8-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-8-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-8-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-9-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-9-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-9-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-10-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-10-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-10-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-11-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-11-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-11-3_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-12-1_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-12-2_RADIO_INPUT_CHANGE", payload: "No"},
    {type: "FS0-12-3_RADIO_INPUT_CHANGE", payload: "No"},
];
const sectionsDeskToDisplay = [
{id: 0, section: 'TITLE', display: 'Personal...'},
{id: 1, section: 'DEMO', display: 'Demographic...'},
{id: 2, section: 'PP', display: 'Complaint...'},
{id: 3, section: 'M', display: 'Medical...'},
{id: 4, section: 'EDO', display: 'Education...'},
{id: 5, section: 'DAH', display: 'Drug...'},
{id: 6, section: 'LEG', display: 'Legal...'},
{id: 7, section: 'FR', display: 'Family...'},
{id: 8, section: 'PS', display: 'Behavioral...'},
{id: 9, section: 'AC', display: 'ASAM...'},
{id: 10, section: 'MSE', display: 'Mental...'},
{id: 11, section: 'CI', display: 'Collateral...'},
{id: 12, section: 'SAW', display: 'Strengths...'},
{id: 13, section: 'PL', display: 'Problem...'},
{id: 14, section: 'SUM', display: 'Summary...'},
{id: 15, section: 'SUG', display: 'Recommendations...'},
{id: 16, section: 'CMG', display: 'Goals...'},
{id: 17, section: 'ALL', display: 'Full Report'},
]



const exceptionSelectToYear = [   //exceptionSelectToYear
  // "gi14",
  "demo10",
  // "es6",
  "ed11a",
  // "da16-new117"
];

const exceptionSelectToMonth = [   //exceptionSelectToMonth
  "ed11a-new1",
  // "da16-new117a"
];

const exceptionSelectSumYearToMonth = [
  "da16-new117"
];
const exceptionSelectSumMonthToMonth = [
  "da16-new117a"
];

const exceptionCheckboxToRadio = [
  "fs0-1-1",
  "fs0-1-2",
  "fs0-1-3",
  "fs0-2-1",
  "fs0-2-2",
  "fs0-2-3",
  "fs0-3-1",
  "fs0-3-2",
  "fs0-3-3",
  "fs0-4-1",
  "fs0-4-2",
  "fs0-4-3",
  "fs0-5-1",
  "fs0-5-2",
  "fs0-5-3",
  "fs0-6-1",
  "fs0-6-2",
  "fs0-6-3",
  "fs0-7-1",
  "fs0-7-2",
  "fs0-7-3",
  "fs0-8-1",
  "fs0-8-2",
  "fs0-8-3",
  "fs0-9-1",
  "fs0-9-2",
  "fs0-9-3",
  "fs0-10-1",
  "fs0-10-2",
  "fs0-10-3",
  "fs0-11-1",
  "fs0-11-2",
  "fs0-11-3",
  "fs0-12-1",
  "fs0-12-2",
  "fs0-12-3",
];

const maskCompaniesForTP = [
  { id: 1, mask: "*", title: "All companies" },
  { id: 2, mask: "0", title: "Companies dont used" },
  { id: 3, mask: "1", title: "Companies used" },
];

export {
  Constants,
  templateNote,
  ruleOperations,
  transformruleOperations,
  patternClientInfoContent,
  needRuleClarification,
  shortNameForExport,
  needRuleIgnore,
  setupState,
  interview_sections,
  postinterview_sections,
  asi_sections,
  paymentScheme,
  needTransformruleClarification,
  needTransformruleIgnore,
  asiCommentField,
  routeOfAdmin,
  phoneTypeGroup,
  hourminuteTypeGroup,
  yearmonthTypeGroup,
  yearTypeGroup,
  numberTypeGroup,
  textTypeGroup,
  dateTypeGroup,
  complexTypeGroup,
  codeTypeGroup,
  selectTypeGroup,
  mariageHafToAsi,
  exceptionActionTruleYes,
  exceptionSelectToYear,
  exceptionSelectToMonth,
  exceptionSelectSumYearToMonth,
  exceptionSelectSumMonthToMonth,
  exceptionCheckboxToRadio,
  maskCompaniesForTP,
  ASI_OUTPUT_DOCUMENT,
  initializeH1ToNo,
  sectionsDeskToDisplay,
  classArray
};

/*

const templateNote=[
  {
    id: 1,
    event: Constants.EVENT_NOTIFICATION_SIGNED_BUT_NOT_VERIFIED,
    subject: `Verify Account Reminder`,
    title: `Thanks for signing up,`,
    message: `We noticed you haven't verified yet. Please verify your email address for {{Organization_Name}} to start using HerdmanHealth.`,
  },
  {
    id: 2,
    event: Constants.EVENT_NOTIFICATION_NON_WORKING_LONG_TIME,
    subject: `Check-in`,
    title: `Hi HAF Administrators from `,
    message: `It’s been a some time since you started your trial with HerdmanHealth.
      HerdmanHealth is an intuitive software that guides providers through a Behavior Health Assessment and generates a professional-looking narrative. It follows the Herdman Assessment Form (HAF) process developed by Dr. John Herdman over his 46 years experience in counseling and running a mental health and addiction center.

      Our practitioners save an average of 90 minutes per assessment.`,
  },
  {
    id: 3,
    event: Constants.EVENT_NOTIFICATION_USE_FREE_CREDITS_BUT_STOP_USING,
    subject: `Check-in`,
    title: `Hi HAF Administrators from `,
    message: `It’s been a some time since you started your trial with HerdmanHealth.
      HerdmanHealth is an intuitive software that guides providers through a Behavior Health Assessment and generates a professional-looking narrative. It follows the Herdman Assessment Form (HAF) process developed by Dr. John Herdman over his 46 years experience in counseling and running a mental health and addiction center.

      Our practitioners save an average of 90 minutes per assessment.`,
  },
  {
    id: 4,
    event: Constants.EVENT_NOTIFICATION_ONLY_ONE_CREDIT_LEFT,
    subject: `1 credit left`,
    title: `Hi HAF Administrators from `,
    message: `We're just sending a reminder that you have only 1 credit left in your HerdmanHealth account. Please login to your account and go to Settings to purchase more.
    Thank you!.`,
  },
  {
    id: 5,
    event: Constants.EVENT_NOTIFICATION_FREE_EMAIL_FORMAT,
    subject: ``,
    title: `Hi HAF Administrators from `,
    message: ``,
  },
];

*/
