import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { createHashHistory } from "history";
import { connect } from "react-redux";
import classes from "./error-token.module.css";

class ErrorToken extends Component {
  render() {
    if (this.props.hasErrorToken.error) {
      return (
        <Modal
          isOpen={this.props.hasErrorToken.error}
          className={classes.modal}
          // backdropTransition={{ timeout: 13000000 }}
        >
          <ModalHeader className={classes.modalheader}>Error</ModalHeader>
          <ModalBody className={classes.modalbody}>
            {this.props.hasErrorToken.msg}
          </ModalBody>
          <ModalFooter className={classes.modalfooter}>
            <Button
              color="primary"
              onClick={e => this.props.handlerCloseApp(e)}
            >
              <i className="fa fa-window-close" aria-hidden="true"></i> Exit
            </Button>{" "}
          </ModalFooter>
        </Modal>
      );
    } else {
      return this.props.children;
    }
  }
}

const MapStateToProps = state => {
  return {
    hasErrorToken: state.evtreducer.hasErrorToken
  };
};

const MapDispatchToProps = dispatch => {
  return {
    handlerCloseApp: e => {
      e.preventDefault();
      const history = createHashHistory();
      dispatch({ type: "LOGIN_IN_HAF", payload: false });
      dispatch({ type: "SET_ERROR_TOKEN", payload: { error: false, msg: "" } });
      localStorage.clear();
      history.push("/login");
    }
  };
};
export default connect(MapStateToProps, MapDispatchToProps)(ErrorToken);
