// import assessmentPattern from "./assessmentTest"
import ASIPattern from "./ASIPattern";
import adultPattern from "./adultPattern";
import adolescentPattern from "./adolescentPattern";
import initialState from "./state";
import {
  returnClearValue,
  ArrInArr,
  splitStr,
  contains_rank,
  Constants,
  jsonElem,
  jsonCheckboxElemLink,
  jsonSearchType,
  routeOfAdmin,
} from "../api";

// const getDefaultValue = (el, assessmentPattern) => {
//   let result = [];
//   for (var i = 0; i < assessmentPattern.length; i++) {
//     for (var j = 0; j < assessmentPattern[i].body.length; j++) {
//       if (assessmentPattern[i].body[j].shortname === el.shortname)
//         return assessmentPattern[i].body[j];
//     }
//   }
//   return result;
// };

// const getDefaultValueAdolescent = (el, adolescentPattern) => {
//   let result = [];
//   for (var i = 0; i < adolescentPattern.length; i++) {
//     for (var j = 0; j < adolescentPattern[i].body.length; j++) {
//       if (adolescentPattern[i].body[j].shortname === el.shortname)
//         return adolescentPattern[i].body[j];
//     }
//   }
//   return result;
// };

// const checkDepend = (state, el) => {
//   let result = false;
//   if (jsonElem(state.assessment_state, el.depending)) {
//     switch (el.condition) {
//       case "out":

//         if (el.exception !== jsonElem(state.assessment_state, el.depending).value && jsonElem(state.assessment_state, el.depending).value !== "" && !jsonCheckboxElemLink(state.assessment_state, el.depending).includes(el.exception)) {
//           result=true;
//         }
//         break;
//       case "in":
//         if ((el.exception === jsonElem(state.assessment_state, el.depending).value && jsonElem(state.assessment_state, el.depending).value !== "") || jsonCheckboxElemLink(state.assessment_state, el.depending).includes(el.exception)) {
//           result=true;
//         }
//         break;
//       default:
//         break;
//     }
//     switch (el.condition1) {
//       case "out":
//         if (el.exception1 !== jsonElem(state.assessment_state, el.depending1).value && result===true && jsonElem(state.assessment_state, el.depending1).value !== "") {
//           result=true;
//         } else {
//           result=false;
//         }
//         break;
//       case "in":
//         if (el.exception1 === jsonElem(state.assessment_state, el.depending1).value && result===true && jsonElem(state.assessment_state, el.depending1).value !== "") {
//           result=true;
//         } else{
//           result=false;
//         }
//         break;
//       default:
//         break;
//     }
//   } else {
//     result=true;
//   }
//   return result;
// };

const setDepNext = ([prevState, prevArr]) => {
  let MySet = new Set([]);
  let nextState = [];
  let nextArr = [];
  MySet = new Set(prevArr);
  // console.log("MySet=",MySet);
  nextState = Object.assign({}, prevState, {
    assessment_state: prevState.assessment_state.map((el) =>
      el.topic !== undefined
        ? {
            ...el,
            body: el.body.map((el1) => {
              if (MySet.has(el1.depending)) {
                nextArr.push(el1.act);
                return { ...el1, disabled: "true", value: "" };
              } else {
                return { ...el1 };
              }
            }),
          }
        : el
    ),
  });
  return [nextState, nextArr];
};

// const setDepNextAdolescentOn = ([prevState, prevArr],action) => {
//   let MySet = new Set([]);
//   let nextState = [];
//   let nextArr = [];
//   MySet = new Set(prevArr);

//   nextState = Object.assign({}, prevState, {
//     assessment_state: prevState.assessment_state.map((el) =>
//       el.topic !== undefined
//         ? {
//             ...el,
//             body: el.body.map((el1) => {
//               if (MySet.has(el1.depending)) {
//                 nextArr.push(el1.act);
//                 if (action.payload === "Adolescent") {
//                   return { ...el1 };
//                 }
//                 if (action.payload === "Adult") {
//                   return { ...el1, disabled: "true", value: "" };
//                 }
//               } else {
//                 return { ...el1 };
//               }
//             }),
//           }
//         : el
//     ),
//   });
//   return [nextState, nextArr];
// };

// const setDepNextAdolescentOff = ([prevState, prevArr],action) => {
//   let MySet = new Set([]);
//   let nextState = [];
//   let nextArr = [];
//   MySet = new Set(prevArr);

//   // console.log("MySet=",MySet);
//   // console.log("action=",action);
//   // nextState = Object.assign({}, prevState)
//   nextState = Object.assign({}, prevState, {
//     assessment_state: prevState.assessment_state.map((el) =>
//       el.topic !== undefined
//         ? {
//             ...el,
//             body: el.body.map((el1) => {
//               if (MySet.has(el1.depending)) {
//                 nextArr.push(el1.act);
//                 // console.log("nextArr=",nextArr);
//                 if (action.payload === "Adolescent") {
//                   return { ...el1, disabled: "true", value: "" };
//                 }
//                 if (action.payload === "Adult") {
//                   return { ...el1 };
//                 }
//               } else {
//                 return { ...el1 };
//               }
//             }),
//           }
//         : el
//     ),
//   });
//   return [nextState, nextArr];
// };

const hafreducer = (state = initialState, action) => {
  let tmp_state = {};
  let tmp_state0 = {};
  let tmp_state1 = {};
  let tmp_state2 = {};
  let tmp_state3 = {};
  let arr_exist_rank = [];
  let arr_deep_route = [];
  let arr_ = [];
  let arr__ = [];
  // let arr___ = [];
  let bodyTmp = [];
  let objectTmp = {};
  let valueTmp = [];
  let MySet = new Set([]);
  let MySet1 = new Set([]);
  let MySet0 = new Set([]);
  let act = action.type.split("_");
  // console.log("act[0]=",act[0]);
  // console.log("action.type=",action.type);
  // console.log(">>state=",state);
  switch (action.type) {
    case "AC_CHECKBOX_INPUT_CHANGE":
    case "MSE_CHECKBOX_INPUT_CHANGE":
    case "SAW_CHECKBOX_INPUT_CHANGE":
    case "CI_CHECKBOX_INPUT_CHANGE":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) => {
          // console.log("el.section =", el.section);
          return el.section === act[0]
            ? { ...el, checked: el.checked === "true" ? "false" : "true" }
            : // ? { ...el, checked: "false"}
              el;
        }),
        arrayLabels: state.arrayLabels.map((el) => {
          return el.section === act[0]
            ? { ...el, checked: el.checked === "true" ? "false" : "true" }
            : // ? { ...el, checked: "false"}
              el;
        }),
      });
    case "CHANGE_ATYPE_INPUT_CHANGE":
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, atype: action.payload },
      });
    case "CHANGE_VERSION_INPUT_CHANGE":
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, version: action.payload },
      });
    case "SET_SUE_PATTERN_ASSESSMENT":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state: state.assessment_state.map((el) => {
          return Constants.SUE_PATTERN.includes(el.section)
            ? { ...el, checked: (el.checked = "true") }
            : { ...el, checked: (el.checked = "false") };
        }),
      });
    case "SET_MHA_PATTERN_ASSESSMENT":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state: state.assessment_state.map((el) => {
          return Constants.MHA_PATTERN.includes(el.section)
            ? { ...el, checked: (el.checked = "true") }
            : { ...el, checked: (el.checked = "false") };
        }),
      });
    case "SET_COE_PATTERN_ASSESSMENT":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state: state.assessment_state.map((el) => {
          return Constants.COE_PATTERN.includes(el.section)
            ? { ...el, checked: (el.checked = "true") }
            : { ...el, checked: (el.checked = "false") };
        }),
      });
    case "LOAD_ARRAY_LABELS":
      return Object.assign({}, state, { arrayLabels: action.payload });

    // return Object.assign({}, state, {
    // 	assessment_state: state.assessment_state.map((el) =>
    // 	el.topic !== undefined
    // 		? {
    // 			...el,
    // 			body: el.body.map((el1) =>
    // 			el1.act === act[0] ? { ...el1, value: action.payload } : el1
    // 			),
    // 		}
    // 		: el
    // 	),
    // });
    case "STARTNEWASSESSMENT_FIRSTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, firstname: action.payload },
      });
    case "STARTNEWASSESSMENT_MIDDLENAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, middlename: action.payload },
      });
    case "STARTNEWASSESSMENT_LASTNAMENAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, lastname: action.payload },
      });
    case "STARTNEWASSESSMENT_ASSESSMENT_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, assessment: action.payload },
      });
    case "STARTNEWASSESSMENT_DEGREEPROFESSIONALCREDENTIONAL_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: {
          ...state.newAssessment,
          degree_prof_credential: action.payload,
        },
      });
    case "STARTNEWASSESSMENT_DOB_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, dob: action.payload },
      });
    case "STARTNEWASSESSMENT_PROVIDER_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, provider: action.payload },
      });
    // case "STARTNEWASSESSMENT_EMAIL_INPUT_CHANGE":
    //   return Object.assign({}, state, {
    //     newAssessment: { ...state.newAssessment, email: action.payload }
    //   });
    case "STARTNEWASSESSMENT_STREET_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, street: action.payload },
      });
    case "STARTNEWASSESSMENT_CITY_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, city: action.payload },
      });
    case "STARTNEWASSESSMENT_STATEPROVINCE_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, state: action.payload },
      });
    case "STARTNEWASSESSMENT_ZIPCODE_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, zip_code: action.payload },
      });
    case "STARTNEWASSESSMENT_YOURCOUNTRY_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, country: action.payload },
      });
    case "STARTNEWASSESSMENT_YOURCOUNTY_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, county: action.payload },
      });
    case "STARTNEWASSESSMENT_EVALUATOR_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, evaluator: action.payload },
      });
    case "SET_EVALUATOR": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, evaluator: action.payload },
      });
    }
    case "SET_PROFESSIONALCREDENTIALS": {
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          degree_prof_credential: action.payload,
        },
      });
    }
    case "SET_ATYPE": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, atype: action.payload },
      });
    }
    //========================================================================================
    /*                                                                                      *
     *                                    SET_ADULT_TYPE                                    *
     *                                                                                      */
    //========================================================================================

    //     case "SET_AND_FORK_ADULT_TYPE":
    //       let allAdolescent = [];
    //       let allAdolescentOff = [];
    //       arr_ = [];
    //       arr__ = [];
    // //========================================================================================
    // /*                                                                                      *
    //  *                          allAdolescent - exclusively adolescent fields               *
    //  *                                                                                      */
    // //========================================================================================

    //       for (let i = 0; i < state.assessment_state.length; i++) {
    //         for (let j = 0; j < state.assessment_state[i].body.length; j++) {
    //           if (state.assessment_state[i].body[j].adolescent === "true") allAdolescent.push(state.assessment_state[i].body[j].act);
    //         }
    //       }
    // //========================================================================================
    // /*                                                                                      *
    //  *                          allAdolescent - exclusively adult fields                    *
    //  *                                                                                      */
    // //========================================================================================

    //       for (let i = 0; i < state.assessment_state.length; i++) {
    //         for (let j = 0; j < state.assessment_state[i].body.length; j++) {
    //           if (state.assessment_state[i].body[j].adolescent === "false") allAdolescentOff.push(state.assessment_state[i].body[j].act);
    //         }
    //       }

    //       // console.log("allAdolescentOff=",allAdolescentOff);
    //       // console.log("allAdolescent=",allAdolescent);
    // //========================================================================================
    // /*                                                                                      *
    //  *                                 adolescent depending                                 *
    //  *                                                                                      */
    // //========================================================================================
    //       allAdolescent.forEach(adolescentField => {
    //         tmp_state1 = Object.assign({}, state, {
    //           assessment_state: state.assessment_state.map((el) =>
    //             el.topic !== undefined
    //               ? {
    //                   ...el,
    //                   body: el.body.map((el1) => {
    //                     if (action.payload==="Adolescent") {
    //                       if (el1.depending === adolescentField) {
    //                         // if(el1.act==="M12-0-ADOL2")
    //                         //  console.log("el1=",el1);
    //                         return { ...el1};
    //                       } else {
    //                         return { ...el1 };
    //                       }
    //                     }
    //                       if (action.payload==="Adult") {
    //                         if (el1.depending === adolescentField) {
    //                           // console.log("el1=",el1);
    //                           return { ...el1, disabled: "true", value: "" };
    //                         } else {
    //                           return { ...el1 };
    //                         }
    //                       }
    //                   }),
    //                 }
    //               : el
    //           ),
    //         });
    //       [tmp_state1, arr_] = setDepNextAdolescentOn([tmp_state1, allAdolescent],action);
    //       while (arr_.length > 0) {
    //         [tmp_state1, arr_] = setDepNextAdolescentOn([tmp_state1, arr_],action);
    //       }
    //       })
    //       allAdolescentOff.forEach(adolescentOffField => {
    //         tmp_state2 = Object.assign({}, tmp_state1, {
    //           assessment_state: tmp_state1.assessment_state.map((el) =>
    //             el.topic !== undefined
    //               ? {
    //                   ...el,
    //                   body: el.body.map((el1) => {
    //                     if (action.payload==="Adolescent") {
    //                       if (el1.depending === adolescentOffField) {
    //                         return { ...el1, disabled: "true", value: "" };
    //                       } else {
    //                         return { ...el1 };
    //                       }
    //                     }
    //                       if (action.payload==="Adult") {
    //                         if (el1.depending === adolescentOffField) {
    //                           return { ...el1};
    //                         } else {
    //                           return { ...el1 };
    //                         }
    //                       }
    //                   }),
    //                 }
    //               : el
    //           ),
    //         });
    //         [tmp_state2, arr_] = setDepNextAdolescentOff([tmp_state2, allAdolescentOff],action);
    //         // console.log("tmp_state2=",tmp_state2,"arr_=",arr_);
    //         while (arr_.length > 0) {
    //           [tmp_state2, arr_] = setDepNextAdolescentOff([tmp_state2, arr_],action);
    //         }
    //       })
    //       // console.log("tmp_state2.assessment_state=",tmp_state2.assessment_state);
    //       // console.log("tmp_state2=",tmp_state2);
    // //========================================================================================
    // /*                                                                                      *
    //  *                                 adolescent                                  *
    //  *                                                                                      */
    // //========================================================================================

    //       tmp_state = Object.assign({}, tmp_state2, {
    //         assessment_state: tmp_state2.assessment_state.map((el) =>
    //           el.topic !== undefined
    //             ? {
    //                 ...el,
    //                 body: el.body.map((el1) => {
    //                   if (action.payload==="Adolescent" ) {
    //                     if (el1.adolescent === "true" && checkDepend(state,el1)===true) {
    //                       return { ...el1, disabled: "false", value: el1.value===""?getDefaultValueAdolescent(el1,adolescentPattern).value:el1.value};
    //                     } else {
    //                       // console.log("el1.id=",el.id)
    //                       if (el1.adolescent === "false" || (el1.adolescent === "true" && checkDepend(state,el1)===false)) {
    //                         if(el1.type!=="checkbox") //fix bug chrckbox
    //                           return { ...el1, disabled: "true", value: "" };
    //                         else
    //                           return { ...el1 };
    //                       } else {
    //                         return { ...el1 };
    //                       }
    //                     }
    //                   }
    //                   else {
    //                     // if(el1.adolescent==="false")
    //                     //   console.log(action.payload," el1.id=",el1.id,"el1.adolescent=",el1.adolescent,"checkDepend(state,el1)=",checkDepend(state,el1))
    // //========================================================================================
    // /*                                                                                      *
    //  *                              THIS BLOCK MUST BE DELETED                              *
    //  *                                                                                      */
    // //========================================================================================

    //                     if (action.payload==="Adult") {
    //                       if (el1.adolescent === "true" || (el1.adolescent === "false" && checkDepend(state,el1)===false)) {
    //                         return { ...el1, disabled: "true", value: "" };
    //                       } else {
    //                         if (el1.adolescent === "false" && checkDepend(state,el1)===true ) {
    //                           // console.log(action.payload," el1.id=",el1.id,"el1.adolescent=",el1.adolescent,"checkDepend(state,el1)=",checkDepend(state,el1))
    //                           return { ...el1,disabled: "false",value: el1.value===""?getDefaultValueAdolescent(el1,adolescentPattern).value:el1.value};
    //                         } else {
    //                           return { ...el1 };
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }),
    //               }
    //             : el
    //         ),
    //       });
    //       // console.log("tmp_state=",tmp_state)
    //       return Object.assign({}, tmp_state, {
    //         newAssessment: { ...state.newAssessment, adult_type: action.payload },
    //     });
    case "SET_ADULT_TYPE":
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, adult_type: action.payload },
      });
    case "STARTNEWASSESSMENT_PHONE_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, phone: action.payload },
      });
    case "STARTNEWASSESSMENT_SSN_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, ssn: action.payload },
      });
    case "STARTNEWASSESSMENT_GENDER_INPUT_CHANGE":
      if (action.payload === "Other")
        return Object.assign({}, state, {
          patient_info_changed: true,
          newAssessment: { ...state.newAssessment, gender: action.payload },
        });
      else
        return Object.assign({}, state, {
          patient_info_changed: true,
          newAssessment: {
            ...state.newAssessment,
            gender: action.payload,
            sex: "",
            gender_explain: "",
          },
        });
    case "STARTNEWASSESSMENT_SEX_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: {
          ...state.newAssessment,
          sex: action.payload,
        },
      });
    case "STARTNEWASSESSMENT_GENDER_EXPLAIN_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: {
          ...state.newAssessment,
          gender_explain: action.payload,
        },
      });
    case "STARTNEWASSESSMENT_MARRIAGE_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: {
          ...state.newAssessment,
          marriage: action.payload,
        },
      });
    case "STARTNEWASSESSMENT_EMAIL_CLIENT_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: { ...state.newAssessment, email_client: action.payload },
      });
    case "STARTNEWASSESSMENT_BLUR_EMAIL_CLIENT_INPUT_CHANGE": {
      state.newAssessment.email_client = state.newAssessment.email_client.replace(/^\s+|\s+$/g, "");
      let new_str = "";

      let num =
        typeof state.newAssessment.email_client === "undefined"
          ? 0
          : state.newAssessment.email_client.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          email_client_onblur: state.newAssessment.email_client,
          email_client: new_str,
        },
      });
    }

    case "STARTNEWASSESSMENT_FOCUS_EMAIL_CLIENT_INPUT_CHANGE": {
      let email_client =
        typeof state.newAssessment.email_client_onblur === "undefined"
          ? ""
          : state.newAssessment.email_client_onblur;
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          email_client_onblur: "",
          email_client: email_client,
        },
      });
    }

    case "STARTNEWASSESSMENT_EMAIL_CLIENT_REPEAT_INPUT_CHANGE":
      return Object.assign({}, state, {
        patient_info_changed: true,
        newAssessment: {
          ...state.newAssessment,
          email_client_repeat: action.payload,
        },
      });
    case "STARTNEWASSESSMENT_BLUR_EMAIL_CLIENT_REPEAT_INPUT_CHANGE": {
      state.newAssessment.email_client_repeat = state.newAssessment.email_client_repeat.replace(
        /^\s+|\s+$/g,
        ""
      );
      let new_str = "";
      let num =
        typeof state.newAssessment.email_client_repeat === "undefined"
          ? 0
          : state.newAssessment.email_client_repeat.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          email_client_repeat_onblur: state.newAssessment.email_client_repeat,
          email_client_repeat: new_str,
        },
      });
    }

    case "STARTNEWASSESSMENT_FOCUS_EMAIL_CLIENT_REPEAT_INPUT_CHANGE": {
      let email_client_repeat =
        typeof state.newAssessment.email_client_repeat_onblur === "undefined"
          ? ""
          : state.newAssessment.email_client_repeat_onblur;
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          email_client_repeat_onblur: "",
          email_client_repeat: email_client_repeat,
        },
      });
    }

    case "STARTNEWASSESSMENT_STATUS_SET":
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, status: action.payload },
      });
    case "STARTNEWASSESSMENT_COMPANY_USER_ID_UPDATE":
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
        },
      });
    case "STARTNEWASSESSMENT_USERID_HAF_UPDATE":
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          UserId: action.payload.UserId,
          assessment: action.payload.assessment,
        },
      });
    case "ASSESSMENT_INC_PRINT_STATUS":
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          print_status: Number(state.newAssessment.print_status) + action.payload,
        },
      });
    case "CLEAR_NEW_ASSESSMENT":
      return Object.assign({}, state, {
        newAssessment: {
          ...state.newAssessment,
          assessment: "",
          assessmentOrig: "",
          firstname: "",
          middlename: "",
          lastname: "",
          gender: "",
          sex: "",
          gender_explain: "",
          marriage: "",
          email_client: "",
          email_client_onblur: "",
          email_client_repeat: "",
          email_client_repeat_onblur: "",
          street: "",
          city: "",
          state: "",
          zip_code: "",
          country: "United States (+1)",
          county: "",
          evaluator: "",
          degree_prof_credential: "",
          phone: "",
          ssn: "",
          dob: "",
          provider: "",
          status: "",
          adult_type: "",
          atype: "",
          allow_support: 0,
          shared_class: "",
          shared_group: "",
          print_status: "",
          verifycode: "",
          remotestatus: "",
          payment_method: "",
          // version: "",
          // version_update: ""
        },
      });
    case "SET_FIELD_PROVIDER": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, provider: action.payload },
      });
    }
    case "SET_FIELD_ASSESSMENT": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, assessment: action.payload },
      });
    }
    case "SET_ASSESSMENT_MODE": {
      return Object.assign({}, state, { newAssessmentMode: action.payload });
    }

    //M ADDED
    case "FORM_COMPONENT_MOUNT_STATUS": {
      return Object.assign({}, state, {
        component_mount_status: action.payload,
      });
    }

    case "FORM_FIELDS_REF_TABLE": {
      return Object.assign({}, state, {
        form_fields_ref_table: action.payload,
      });
    }

    /////////////////////////

    case "SET_ACTIVE_TAB": {
      return Object.assign({}, state, {
        assessment_active_tab: action.payload,
      });
    }
    case "TAKE_ITEM_ASSESSMENT": {
      let new_str = "";
      let num =
        typeof action.payload.email_client === "undefined" ? 0 : action.payload.email_client.length;
      while (num-- > 0) new_str += "•";

      let tmp = Object.assign({}, state, { newAssessment: action.payload });
      return Object.assign({}, tmp, {
        newAssessment: {
          ...tmp.newAssessment,
          assessmentOrig: action.payload.assessment,
          email_client: new_str,
          email_client_repeat: new_str,
          email_client_onblur: action.payload.email_client,
          email_client_repeat_onblur: action.payload.email_client,
        },
      });
    }
    case "TAKE_ITEM_DETAIL_ASSESSMENT":
      return Object.assign({}, state, { assessment_state: action.payload });
    case "TAKE_DETAILT_ASSESSMENT": {
      switch (action.payload) {
        case "Adult":
          return Object.assign({}, state, { assessment_state: adultPattern });
        case "Adolescent":
          return Object.assign({}, state, {
            assessment_state: adolescentPattern,
          });
        case "ASI":
          return Object.assign({}, state, { assessment_state: ASIPattern });
        case "ASILite":
          return Object.assign({}, state, { assessment_state: ASIPattern });
        default:
          return Object.assign({}, state, {
            assessment_state: adolescentPattern,
          });
      }
      // if(action.payload==="Adolescent"){
      //   return Object.assign({}, state, { assessment_state: adolescentPattern });
      // } else {
      //   if(action.payload==="Adult"){
      //     return Object.assign({}, state, { assessment_state: adultPattern });
      //   } else {
      //     return Object.assign({}, state, { assessment_state: adolescentPattern });
      //   }
      // }
    }
    // case "COPY_INITIAL_TAB": {
    //   //Backup initial
    //   return Object.assign({}, state, {
    //     initial_assessment_tab: assessmentPattern.filter(
    //       (el) => el.topic === action.payload
    //       // if(el.topic === action.payload) return {...el}
    //     ),
    //   });
    // }
    case "COPY_INITIAL_TAB_ADULT": {
      //Backup initial
      return Object.assign({}, state, {
        initial_assessment_tab: adultPattern.filter(
          (el) => el.topic === action.payload
          // if(el.topic === action.payload) return {...el}
        ),
      });
    }
    case "COPY_INITIAL_TAB_ADOLESCENT": {
      //Backup initial
      return Object.assign({}, state, {
        initial_assessment_tab: adolescentPattern.filter(
          (el) => el.topic === action.payload
          // if(el.topic === action.payload) return {...el}
        ),
      });
    }
    //Backup for undo
    case "COPY_UNDO_TAB":
      // console.log("COPY_UNDO_TAB state.undo_assessment_tab[0]=",state.undo_assessment_tab[0]);
      return Object.assign({}, state, {
        undo_assessment_tab: state.assessment_state.filter(
          (el) => el.topic === action.payload
          // if(el.topic === action.payload) return {...el}
        ),
      });
    case "RELOAD_UNDO_TAB":
      // console.log("RELOAD_UNDO_TAB state.undo_assessment_tab[0]=",state.undo_assessment_tab[0]);
      return Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic === action.payload ? (el = state.undo_assessment_tab[0]) : el
        ),
      });
    /*
    case "RELOAD_INITIAL_TAB": {
      return Object.assign({}, state, {assessment_state_changed : true,
        assessment_state: state.assessment_state.map(el =>
          el.topic === action.payload
            ? el=state.initial_assessment_tab[0]
            : el
        )
      })
    };
*/

    // те, которые дизабл не восстанавливаьб

    case "RELOAD_INITIAL_TAB":
      return Object.assign({}, state, {
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic === action.payload
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (
                    el1.shortname !== "m0" &&
                    el1.shortname !== "demo5" &&
                    el1.shortname !== "demo4" &&
                    el1.shortname !== "demo4-new0" &&
                    el1.shortname !== "m-new14" &&
                    el1.shortname !== "demo4-new1" &&
                    el1.depending !== "DEMO4" &&
                    el1.shortname !== "demo1" &&
                    el1.shortname !== "fr08-00" &&
                    el1.depending !== "DEMO1"
                  )
                    return state.initial_assessment_tab[0].body.find(
                      (el2) => el2.shortname === el1.shortname
                    );
                  else return el1;
                }),
              }
            : el
        ),
      });
    case "FILL_DANGER_ELEM":
      return Object.assign({}, state, { dangerElem: action.payload });
    case "FILL_DANGER_TOPIC":
      return Object.assign({}, state, { dangerTab: action.payload });
    case "FILL_NONDANGER_TOPIC":
      return Object.assign({}, state, { nonDangerTab: action.payload });
    case "FILL_DANGER_OBJ_TOPIC":
      return Object.assign({}, state, { dangerObjTab: action.payload });
    case "FILL_NONDANGER_OBJ_TOPIC":
      return Object.assign({}, state, { nonDangerObjTab: action.payload });
    case "FILL_RULE_ELEM":
      return Object.assign({}, state, { ruleElem: action.payload });

    case "COPY_SUM00_TO_STATE": {
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: action.payload.value.map((el2) => {
                        return el2;
                      }),
                      radio_options: action.payload.radio_options.map((el2) => {
                        return el2;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp=",tmp);
      return tmp;
    }
    case "COPY_SUM04RULE_TO_STATE": {
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: action.payload.value.map((el2) => {
                        // return el2;
                        return true;
                      }),
                      radio_options: action.payload.radio_options.map((el2) => {
                        return el2;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp=",tmp);
      return tmp;
    }

    case "UPDATE_ASSESSMENT_JSON_VAL": {
      return Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return { ...el1, value: action.payload.val };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
    }
    case "UPDATE_ASSESSMENT_JSON_VAL2": {
      return Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return { ...el1, value2: action.payload.val };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
    }

    case "UPDATE_CHECKBOXES_ASSESSMENT_JSON_VAL": {
      return Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) => {
          // console.log("el=",el);
          return {
            ...el,
            checked: action.payload.find((el1) => el1.section === el.section).checked,
          };
        }),
      });
    }
    case "UPDATE_IMPRESSIONS_JSON_VAL": {
      /*const checkbox_options= */ action.payload.data.msg.map((el) => {
        return `${el.shortname}; ${el.checkbox_options.replace(/<\/?[^>]+(>|$)/g, "")}`;
      });
      /*const value = */ action.payload.data.msg.map((el) => {
        return false;
      });
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: action.payload.data.msg.map((el) => {
                        return false;
                      }),
                      radio_options: action.payload.data.msg.map((el) => {
                        return `${el.shortname}; ${el.checkbox_options.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}`;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return tmp;
      // console.log("value=",value)
      // console.log("checkbox_options=",checkbox_options)
      // console.log("actiom.payload=",action.payload)
    }

    case "UPDATE_IMPRESSIONS_JSON_VAL_FOR_OLD": {
      // const checked = state.assessment_state.filter(el =>el.section === "SUM").map(el=>el.body)
      let arr_check = [];
      /*const checked = */ state.assessment_state.forEach((el) => {
        if (el.section === "SUM")
          el.body.forEach((el1) => {
            if (el1.shortname === "sum00") {
              for (let i = 0; i < el1.value.length; i++) {
                // console.log("el1=",el1);
                if (el1.value[i])
                  arr_check.push({
                    value: true,
                    shortname: splitStr(el1.radio_options[i])[0],
                    checkbox_options: splitStr(el1.radio_options[i])[1],
                  });
              }
            }
          });
      });
      // console.log("arr_check=",arr_check);
      const arr_all = action.payload.data.msg.map((el) => {
        return {
          value: false,
          shortname: el.shortname,
          checkbox_options: el.checkbox_options,
        };
      });

      // console.log("arr_check=",arr_check);
      // console.log("arr_all=",arr_all);

      let arr_merge = [...arr_all];

      for (let j = 0; j < arr_check.length; j++) {
        let added = false;
        for (let i = 0; i < arr_all.length; i++) {
          if (arr_all[i].shortname === arr_check[j].shortname) {
            arr_merge[i] = {
              value: arr_check[j].value,
              shortname: arr_check[j].shortname,
              checkbox_options: arr_check[j].checkbox_options,
            };
            added = true;
          }
        }
        if (!added) {
          arr_merge.push(arr_check[j]);
        }
      }
      let arr_sort = arr_merge.sort((a, b) => {
        // console.log("a.shortname",a.shortname,"b.shortname",b.shortname,"a.shortname>b.shortname",a.shortname>b.shortname)
        if (a.shortname > b.shortname) return 1;
        if (b.shortname > a.shortname) return -1;
        return 0;
      });
      // console.log("arr_sort=",arr_sort);
      /*
      const checkbox_options= action.payload.data.msg.map((el) => {
        return `${el.shortname}; ${el.checkbox_options}`
      });
      const value = action.payload.data.msg.map((el) => {
        return false;
      });
*/
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: /*action.payload.data.msg*/ arr_sort.map((el) => {
                        return el.value;
                      }),
                      radio_options: arr_sort.map((el) => {
                        return `${el.shortname}; ${el.checkbox_options.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}`;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      /*
      const tmp1 =  Object.assign({}, tmp, {
        assessment_state: state.assessment_state.map(el =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map(el1 => {
                  if (el1.shortname === "sum00") {
                    return { ...el1,
                    value:  action.payload.data.msg.map((el) => {
                      return false;
                    }),
                    radio_options: action.payload.data.msg.map((el) => {
                      return `${el.shortname}; ${el.checkbox_options}`
                    })};
                  } else {
                    return { ...el1 };
                  }
                })
              }
            : el
        )
      });
*/
      return tmp;
      // console.log("value=",value)
      // console.log("checkbox_options=",checkbox_options)
      // console.log("actiom.payload=",action.payload)
    }
    case "LINK_ALCOHOLTABLE_JSON_VAL": {
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                // body: el.body.map((el1) => {
                //   if (el1.shortname === action.payload.shortname) {
                //     console.log("action.payload.alcoholTable=",action.payload.alcoholTable);
                //     return action.payload.alcoholTable.map(el2=>el2)
                //     // return { ...el1,...action.payload.alcoholTable.map(el2=>el2)};
                //   } else {
                //     return { ...el1 };
                //   }
                // })
                // body: el.body.concat(action.payload.alcoholTable)
                // body: target.slice(0, insertIndex).concat(insertArr, target.slice(insertIndex));
                body: el.body
                  .map((el1) => {
                    if (el1.shortname === action.payload.shortname) {
                      return [el1].concat(action.payload.blockDah);
                    } else {
                      return el1;
                    }
                  })
                  .flat(),
              }
            : el
        ),
      });
      // console.log("action.payload.shortname=",action.payload.shortname,"tmp.assessment_state=",tmp.assessment_state);
      return tmp;
    }
    // function removeArray(state) {
    //   let inArray = false;
    //   let result = state
    //     .map((el) => {
    //       if (inArray === false) {
    //         if (el === "labelStart") {
    //           inArray = true;
    //           return el;
    //         } else {
    //           return el;
    //         }
    //       } else {
    //         if (el === "labelEnd") {
    //           inArray = false;
    //           return el;
    //         } else {
    //           return null;
    //         }
    //       }
    //     })
    //     .filter((el) => el != null);
    //   return result;
    // }
    case "UNLINK_ALCOHOLTABLE_JSON_VAL": {
      // console.log("action.payload.labelstart=",action.payload.labelstart)
      // console.log("action.payload.labelend=",action.payload.labelend)
      let inArray = false;
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body
                  .map((el1) => {
                    if (inArray === false) {
                      if (el1.shortname === action.payload.labelstart) {
                        inArray = true;
                        // console.log("true el1=",el1);
                        return el1;
                      } else {
                        return el1;
                      }
                    } else {
                      if (el1.shortname === action.payload.labelend) {
                        inArray = false;
                        return el1;
                      } else {
                        // console.log("false el1=",el1);
                        return null;
                      }
                    }
                  })
                  .filter((el) => el != null),
              }
            : el
        ),
      });
      // console.log("tmp.assessment_state=",tmp.assessment_state);
      // debugger;
      return tmp;
    }
    //========================================================================================
    /*                                                                                      *
     *                                         RULES                                        *
     *                                                                                      */
    //========================================================================================

    case "UPDATE_RULES_JSON_VAL": {
      /*const checkbox_options= */ action.payload.data.msg.map((el) => {
        return `${el.rulename}`;
      });
      /*const value = */ action.payload.data.msg.map((el) => {
        return false;
      });
      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: action.payload.data.msg.map((el) => {
                        return true;
                      }),
                      radio_options: action.payload.data.msg.map((el) => {
                        return `${el.rulename}`;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return tmp;
    }
    case "UPDATE_RULES_JSON_VAL_FOR_OLD": {
      // const checked = state.assessment_state.filter(el =>el.section === "SUM").map(el=>el.body)
      let arr_check = [];
      /*const checked = */ state.assessment_state.forEach((el) => {
        if (el.section === "SUG")
          el.body.forEach((el1) => {
            if (el1.shortname === "sum04rule") {
              for (let i = 0; i < el1.value.length; i++) {
                // console.log("el1=",el1);
                if (el1.value[i])
                  arr_check.push({
                    value: true,
                    // rulename: splitStr(el1.radio_options[i])[0]
                    rulename: el1.radio_options[i],
                  });
              }
            }
          });
      });
      // console.log(arr_check)
      const arr_all = action.payload.data.msg.map((el) => {
        return {
          value: false,
          rulename: el.rulename,
        };
      });

      // console.log("arr_check=",arr_check);
      // console.log("arr_all=",arr_all);

      let arr_merge = [...arr_all];

      for (let j = 0; j < arr_check.length; j++) {
        let added = false;
        for (let i = 0; i < arr_all.length; i++) {
          if (arr_all[i].rulename === arr_check[j].rulename) {
            arr_merge[i] = {
              value: arr_check[j].value,
              rulename: arr_check[j].rulename,
            };
            added = true;
          }
        }
        // if (!added) {
        //   arr_merge.push(arr_check[j]);
        // }
      }
      let arr_sort = arr_merge.sort((a, b) => {
        // console.log("a.shortname",a.shortname,"b.shortname",b.shortname,"a.shortname>b.shortname",a.shortname>b.shortname)
        if (a.rulename > b.rulename) return 1;
        if (b.rulename > a.rulename) return -1;
        return 0;
      });

      const tmp = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: arr_sort.map((el) => {
                        // return el.value;
                        return true;
                      }),
                      radio_options: arr_sort.map((el) => {
                        return `${el.rulename}`;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return tmp;
    }
    case "UPDATE_RULES_RESULT_JSON_VAL": {
      // console.log("action.payload.data.msg=",action.payload.data.msg);
      if (!action.payload.data.msg.length) {
        return state;
      }
      /*const checkbox_options= */ action.payload.data.msg.map((el) => {
        return `${el.result}`;
      });
      /*const value = */ action.payload.data.msg.map((el) => {
        return false;
      });
      const tmp = Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: action.payload.data.msg.map((el) => {
                        // return true;
                        return false;
                      }),
                      radio_options: action.payload.data.msg.map((el) => {
                        if (el.dataValues.id === 0)
                          return `<span style="background-color: white; font-weight: bold;">${el.dataValues.rulename}</span>; ${el.result}`;
                        else
                          return `<span style="background-color: lightgrey">${el.dataValues.rulename}</span>; ${el.result}`;
                      }),
                      tooltip: action.payload.data.msg.map((el) => {
                        return `${el.dataValues.rule_window}`;
                      }),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp.assessment_state=",tmp.assessment_state);
      return tmp;
    }
    // case "UPDATE_RULES_RESULT_JSON_VAL": {
    //   /*const checkbox_options= */ action.payload.data.map((el) => {
    //     return `${el.result}`;
    //   });
    //   /*const value = */ action.payload.data.map((el) => {
    //     return false;
    //   });
    //   const tmp = Object.assign({}, state, {
    //     wasClick: state.wasClick + 1,
    //     assessment_state_changed: true,
    //     assessment_state: state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) => {
    //               if (el1.shortname === action.payload.shortname) {
    //                 return {
    //                   ...el1,
    //                   value: action.payload.data.map((el) => {
    //                     return true;
    //                   }),
    //                   radio_options: action.payload.data.map((el) => {
    //                     return `<span style="background-color: lightgrey">${el.rulename}</span>; ${el.result}`;
    //                   }),
    //                   tooltip: action.payload.data.map((el) => {
    //                     return `${el.rule_window}`;
    //                   }),
    //                 };
    //               } else {
    //                 return { ...el1 };
    //               }
    //             }),
    //           }
    //         : el
    //     ),
    //   });
    //   return tmp;
    // }

    case "CLEAR_RULES_RESULT_JSON_VAL": {
      const tmp = Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return {
                      ...el1,
                      value: [],
                      radio_options: [],
                      tooltip: [],
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return tmp;
    }
    case "SET_QUANTITY_SUBSTANCES": {
      return Object.assign({}, state, { QuantitySubstances: action.payload });
    }
    case "SET_RANK_STATUS": {
      return Object.assign({}, state, { RankStatus: action.payload });
    }
    case "MAKE_ITEM_DISABLE": {
      return Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.shortname === action.payload.shortname) {
                    return { ...el1, disabled: action.payload.disable };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
    }
    /*
      return Object.assign({}, state, {
        undo_assessment_tab: state.assessment_state.filter(el =>
        {
          if(el.topic === action.payload) return {...el}
        })
    }
    )}
*/

    case "PP4-1_FIELD_INPUT_CHANGE":
    case "PP4-2_FIELD_INPUT_CHANGE":
    case "PP-NEW1_FIELD_INPUT_CHANGE":
    case "PP-NEW1A_FIELD_INPUT_CHANGE":
    case "PP-NEW1B_FIELD_INPUT_CHANGE":
    case "PP-NEW1C_FIELD_INPUT_CHANGE":
    case "PP1_FIELD_INPUT_CHANGE":
    case "PP-NEW3_FIELD_INPUT_CHANGE":
    case "PP3_FIELD_INPUT_CHANGE":
    case "M6_FIELD_INPUT_CHANGE":
    case "M6-ADD_FIELD_INPUT_CHANGE":
    case "PP7_FIELD_INPUT_CHANGE":
    case "PP8-1_FIELD_INPUT_CHANGE":
    case "PP4-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PP-NEW11_FIELD_INPUT_CHANGE":
    case "PP-NEW12_FIELD_INPUT_CHANGE":
    case "M-NEW3_FIELD_INPUT_CHANGE":
    case "M-NEW5_FIELD_INPUT_CHANGE":
    case "M-NEW5-ADD_FIELD_INPUT_CHANGE":
    case "M3_FIELD_INPUT_CHANGE":
    case "M4_FIELD_INPUT_CHANGE":
    case "M18_FIELD_INPUT_CHANGE":
    case "M8_FIELD_INPUT_CHANGE":
    case "M8-PAIN-1_FIELD_INPUT_CHANGE":
    case "M8-ADD_FIELD_INPUT_CHANGE":
    case "M8-PAIN-2_FIELD_INPUT_CHANGE":
    case "M-NEW15_FIELD_INPUT_CHANGE":
    // case "M-NEW16_FIELD_INPUT_CHANGE":
    case "M-NEW18_FIELD_INPUT_CHANGE":
    case "M-NEW19_FIELD_INPUT_CHANGE":
    case "M11_FIELD_INPUT_CHANGE":
    case "M12_FIELD_INPUT_CHANGE":
    case "M13_FIELD_INPUT_CHANGE":
    case "M20_FIELD_INPUT_CHANGE":
    case "MH16B_FIELD_INPUT_CHANGE":
    case "M-NEW26_FIELD_INPUT_CHANGE":
    case "M-NEW28_FIELD_INPUT_CHANGE":
    case "MH16C_FIELD_INPUT_CHANGE":
    case "DEMO2-ADD1_FIELD_INPUT_CHANGE":
    case "DEMO6_FIELD_INPUT_CHANGE":
    case "DEMO3-1_FIELD_INPUT_CHANGE":
    case "DEMO3-2_FIELD_INPUT_CHANGE":
    case "DEMO7_FIELD_INPUT_CHANGE":
    case "DEMO8_FIELD_INPUT_CHANGE":
    case "DEMO9-NEW1_FIELD_INPUT_CHANGE":
    case "DEMO9-NEW2_FIELD_INPUT_CHANGE":
    case "DEMO10_FIELD_INPUT_CHANGE":
    case "ED-NEW2_FIELD_INPUT_CHANGE":
    case "ED-NEW4_FIELD_INPUT_CHANGE":
    case "ED2_FIELD_INPUT_CHANGE":
    case "ED3_FIELD_INPUT_CHANGE":
    case "ED4_FIELD_INPUT_CHANGE":
    case "ED6_FIELD_INPUT_CHANGE":
    case "ED6-ADD_FIELD_INPUT_CHANGE":
    case "ED6-ADD1_FIELD_INPUT_CHANGE":
    case "ED8_FIELD_INPUT_CHANGE":
    case "ED9_FIELD_INPUT_CHANGE":
    case "ED11A_FIELD_INPUT_CHANGE":
    case "ED11A-NEW1_FIELD_INPUT_CHANGE":
    case "ED11B_FIELD_INPUT_CHANGE":
    case "ED12_FIELD_INPUT_CHANGE":
    case "ED14_FIELD_INPUT_CHANGE":
    case "ED-NEW24_FIELD_INPUT_CHANGE":
    case "ED-NEW26_FIELD_INPUT_CHANGE":
    case "ED-NEW28_FIELD_INPUT_CHANGE":
    case "ED-NEW29_FIELD_INPUT_CHANGE":
    case "ED-NEW31_FIELD_INPUT_CHANGE":
    case "ED-NEW33_FIELD_INPUT_CHANGE":
    case "ED18_FIELD_INPUT_CHANGE":
    case "ED20_FIELD_INPUT_CHANGE":
    case "ED24_FIELD_INPUT_CHANGE":
    case "ED25_FIELD_INPUT_CHANGE":
    case "ED26_FIELD_INPUT_CHANGE":
    case "ED27_FIELD_INPUT_CHANGE":
    case "ED25-NEW1_FIELD_INPUT_CHANGE":
    case "ED29_FIELD_INPUT_CHANGE":
    case "ED31A_FIELD_INPUT_CHANGE":
    case "ED31B_FIELD_INPUT_CHANGE":
    case "ED31C_FIELD_INPUT_CHANGE":
    case "ED-NEW52_FIELD_INPUT_CHANGE":
    case "ED-NEW54_FIELD_INPUT_CHANGE":
    case "ED34_FIELD_INPUT_CHANGE":
    case "ED35B_FIELD_INPUT_CHANGE":
    case "ED35C_FIELD_INPUT_CHANGE":
    case "DA0_FIELD_INPUT_CHANGE":
    case "DA1_FIELD_INPUT_CHANGE":
    case "DA1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA1-ADOL2_FIELD_INPUT_CHANGE":
    case "DA1-ADOL3_FIELD_INPUT_CHANGE":
    case "DA1-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA5-ADOL1_FIELD_INPUT_CHANGE":
    case "DA5-ADOL2_FIELD_INPUT_CHANGE":
    case "DA5-ADOL3_FIELD_INPUT_CHANGE":
    case "DA5-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA6-ADOL1_FIELD_INPUT_CHANGE":
    case "DA6-ADOL2_FIELD_INPUT_CHANGE":
    case "DA6-ADOL3_FIELD_INPUT_CHANGE":
    case "DA6-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA7-NEW-ADOL1_FIELD_INPUT_CHANGE":
    case "DA7-NEW-ADOL2_FIELD_INPUT_CHANGE":
    case "DA7-NEW-ADOL3_FIELD_INPUT_CHANGE":
    case "DA7-NEW-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA7-NEW1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA7-NEW1-ADOL2_FIELD_INPUT_CHANGE":
    case "DA7-NEW1-ADOL3_FIELD_INPUT_CHANGE":
    case "DA7-NEW1-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA8-NEW-ADOL1_FIELD_INPUT_CHANGE":
    case "DA8-NEW-ADOL2_FIELD_INPUT_CHANGE":
    case "DA8-NEW-ADOL3_FIELD_INPUT_CHANGE":
    case "DA8-NEW-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA8-ADOL1_FIELD_INPUT_CHANGE":
    case "DA8-ADOL2_FIELD_INPUT_CHANGE":
    case "DA8-ADOL3_FIELD_INPUT_CHANGE":
    case "DA8-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA9-ADOL1_FIELD_INPUT_CHANGE":
    case "DA9-ADOL2_FIELD_INPUT_CHANGE":
    case "DA9-ADOL3_FIELD_INPUT_CHANGE":
    case "DA9-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA10-ADOL1_FIELD_INPUT_CHANGE":
    case "DA10-ADOL2_FIELD_INPUT_CHANGE":
    case "DA10-ADOL3_FIELD_INPUT_CHANGE":
    case "DA10-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA11-ADOL1_FIELD_INPUT_CHANGE":
    case "DA11-ADOL2_FIELD_INPUT_CHANGE":
    case "DA11-ADOL3_FIELD_INPUT_CHANGE":
    case "DA11-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA12-ADOL1_FIELD_INPUT_CHANGE":
    case "DA12-ADOL2_FIELD_INPUT_CHANGE":
    case "DA12-ADOL3_FIELD_INPUT_CHANGE":
    case "DA12-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA13-ADOL1_FIELD_INPUT_CHANGE":
    case "DA13-ADOL2_FIELD_INPUT_CHANGE":
    case "DA13-ADOL3_FIELD_INPUT_CHANGE":
    case "DA13-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA14-ADOL1_FIELD_INPUT_CHANGE":
    case "DA14-ADOL2_FIELD_INPUT_CHANGE":
    case "DA14-ADOL3_FIELD_INPUT_CHANGE":
    case "DA14-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA15-ADOL1_FIELD_INPUT_CHANGE":
    case "DA15-ADOL2_FIELD_INPUT_CHANGE":
    case "DA15-ADOL3_FIELD_INPUT_CHANGE":
    case "DA15-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA16-ADOL1_FIELD_INPUT_CHANGE":
    case "DA16-ADOL2_FIELD_INPUT_CHANGE":
    case "DA16-ADOL3_FIELD_INPUT_CHANGE":
    case "DA16-ADOL4-1_FIELD_INPUT_CHANGE":
    case "DA1-1-ADOL1-1_FIELD_INPUT_CHANGE":
    case "DA1-1-ADOL3-1_FIELD_INPUT_CHANGE":
    case "DA16-NEW117ADD-ADOL1-1_FIELD_INPUT_CHANGE":
    case "DA1-1-ADOL2-1_FIELD_INPUT_CHANGE": //Please specify
    // case "DA1-1-ADOL3_FIELD_INPUT_CHANGE":  //what was the second?
    case "DA1-1-ADOL4-1_FIELD_INPUT_CHANGE": // Please specify
    case "DA16-NEW117ADD-1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA16-NEW117ADD-ADOL2_FIELD_INPUT_CHANGE":
    case "DA16-NEW117ADD-ADOL3_FIELD_INPUT_CHANGE":
    case "DA16-NEW117ADD-ADOL4_FIELD_INPUT_CHANGE":
    case "DA16-ADOL5_FIELD_INPUT_CHANGE":
    case "DA16-ADOL7_FIELD_INPUT_CHANGE":
    case "DA16-ADOL11_FIELD_INPUT_CHANGE":
    case "DA16-ADOL15_FIELD_INPUT_CHANGE":
    case "DA16-ADOL19_FIELD_INPUT_CHANGE":
    case "DA16-ADOL23_FIELD_INPUT_CHANGE":
    case "DA16-ADOL27_FIELD_INPUT_CHANGE":
    case "DA16-ADOL31_FIELD_INPUT_CHANGE":
    case "DA16-ADOL35_FIELD_INPUT_CHANGE":
    case "DA16-ADOL39_FIELD_INPUT_CHANGE":
    case "DA16-ADOL43_FIELD_INPUT_CHANGE":
    case "DA16-ADOL47_FIELD_INPUT_CHANGE":
    case "DA1-1_FIELD_INPUT_CHANGE":
    case "DA1-2_FIELD_INPUT_CHANGE":
    case "DA1-3_FIELD_INPUT_CHANGE":
    case "DA1-4_FIELD_INPUT_CHANGE":
    case "DA2-1_FIELD_INPUT_CHANGE":
    case "DA2-2_FIELD_INPUT_CHANGE":
    case "DA2-3_FIELD_INPUT_CHANGE":
    case "DA2-4_FIELD_INPUT_CHANGE":
    case "DA2-5_FIELD_INPUT_CHANGE":
    case "DA5_FIELD_INPUT_CHANGE":
    case "DA5-1_FIELD_INPUT_CHANGE":
    case "DA5-2_FIELD_INPUT_CHANGE":
    case "DA5-3_FIELD_INPUT_CHANGE":
    case "DA5-4_FIELD_INPUT_CHANGE":
    case "DA6_FIELD_INPUT_CHANGE":
    case "DA6-1_FIELD_INPUT_CHANGE":
    case "DA6-2_FIELD_INPUT_CHANGE":
    case "DA6-3_FIELD_INPUT_CHANGE":
    case "DA6-4_FIELD_INPUT_CHANGE":
    case "DA7-NEW_FIELD_INPUT_CHANGE":
    case "DA7-1-NEW_FIELD_INPUT_CHANGE":
    case "DA7-2-NEW_FIELD_INPUT_CHANGE":
    case "DA7-3-NEW_FIELD_INPUT_CHANGE":
    case "DA7-4-NEW_FIELD_INPUT_CHANGE":
    case "DA7-NEW1_FIELD_INPUT_CHANGE":
    case "DA7-1-NEW1_FIELD_INPUT_CHANGE":
    case "DA7-2-NEW1_FIELD_INPUT_CHANGE":
    case "DA7-3-NEW1_FIELD_INPUT_CHANGE":
    case "DA7-4-NEW1_FIELD_INPUT_CHANGE":
    case "DA8-NEW_FIELD_INPUT_CHANGE":
    case "DA8-1-NEW_FIELD_INPUT_CHANGE":
    case "DA8-2-NEW_FIELD_INPUT_CHANGE":
    case "DA8-3-NEW_FIELD_INPUT_CHANGE":
    case "DA8-4-NEW_FIELD_INPUT_CHANGE":
    case "DA8_FIELD_INPUT_CHANGE":
    case "DA8-1_FIELD_INPUT_CHANGE":
    case "DA8-2_FIELD_INPUT_CHANGE":
    case "DA8-3_FIELD_INPUT_CHANGE":
    case "DA8-4_FIELD_INPUT_CHANGE":
    case "DA9_FIELD_INPUT_CHANGE":
    case "DA9-1_FIELD_INPUT_CHANGE":
    case "DA9-2_FIELD_INPUT_CHANGE":
    case "DA9-3_FIELD_INPUT_CHANGE":
    case "DA9-4_FIELD_INPUT_CHANGE":
    case "DA10_FIELD_INPUT_CHANGE":
    case "DA10-1_FIELD_INPUT_CHANGE":
    case "DA10-2_FIELD_INPUT_CHANGE":
    case "DA10-3_FIELD_INPUT_CHANGE":
    case "DA10-4_FIELD_INPUT_CHANGE":
    case "DA11_FIELD_INPUT_CHANGE":
    case "DA11-1_FIELD_INPUT_CHANGE":
    case "DA11-2_FIELD_INPUT_CHANGE":
    case "DA11-3_FIELD_INPUT_CHANGE":
    case "DA11-4_FIELD_INPUT_CHANGE":
    case "DA12_FIELD_INPUT_CHANGE":
    case "DA12-1_FIELD_INPUT_CHANGE":
    case "DA12-2_FIELD_INPUT_CHANGE":
    case "DA12-3_FIELD_INPUT_CHANGE":
    case "DA12-4_FIELD_INPUT_CHANGE":
    case "DA13_FIELD_INPUT_CHANGE":
    case "DA13-1_FIELD_INPUT_CHANGE":
    case "DA13-2_FIELD_INPUT_CHANGE":
    case "DA13-3_FIELD_INPUT_CHANGE":
    case "DA13-4_FIELD_INPUT_CHANGE":
    case "DA14_FIELD_INPUT_CHANGE":
    case "DA14-1_FIELD_INPUT_CHANGE":
    case "DA14-2_FIELD_INPUT_CHANGE":
    case "DA14-3_FIELD_INPUT_CHANGE":
    case "DA14-4_FIELD_INPUT_CHANGE":
    case "DA15_FIELD_INPUT_CHANGE":
    case "DA15-1_FIELD_INPUT_CHANGE":
    case "DA15-2_FIELD_INPUT_CHANGE":
    case "DA15-3_FIELD_INPUT_CHANGE":
    case "DA15-4_FIELD_INPUT_CHANGE":
    case "DA16_FIELD_INPUT_CHANGE":
    case "DA16-0_FIELD_INPUT_CHANGE":
    case "DA16-1_FIELD_INPUT_CHANGE":
    case "DA16-2_FIELD_INPUT_CHANGE":
    case "DA16-3_FIELD_INPUT_CHANGE":
    case "DA16-4_FIELD_INPUT_CHANGE":
    case "DA16-NEW114_FIELD_INPUT_CHANGE":
    case "DA16-NEW114-ADD_FIELD_INPUT_CHANGE":
    case "DA16-NEW116_FIELD_INPUT_CHANGE":
    case "DA16-NEW117_FIELD_INPUT_CHANGE":
    case "DA16-NEW117A_FIELD_INPUT_CHANGE":
    case "DA16-NEW119_FIELD_INPUT_CHANGE":
    case "DA16-NEW120_FIELD_INPUT_CHANGE":
    case "DA16-NEW122_FIELD_INPUT_CHANGE":
    case "DA16-NEW124_FIELD_INPUT_CHANGE":
    case "DA16-NEW125_FIELD_INPUT_CHANGE":
    case "DA16-NEW126_FIELD_INPUT_CHANGE":
    case "DA16-NEW127-1_FIELD_INPUT_CHANGE":
    case "DA16-NEW128_FIELD_INPUT_CHANGE":
    case "DA16-NEW129_FIELD_INPUT_CHANGE":
    case "DA16-NEW130-1_FIELD_INPUT_CHANGE":
    case "DA16-NEW131_FIELD_INPUT_CHANGE":
    case "DA388A_FIELD_INPUT_CHANGE":
    case "DA388B_FIELD_INPUT_CHANGE":
    case "FR01_FIELD_INPUT_CHANGE":
    case "FR02_FIELD_INPUT_CHANGE":
    case "FR03-ADD2_FIELD_INPUT_CHANGE":
    case "FR04-ADD2_FIELD_INPUT_CHANGE":
    case "FR05-ADD2_FIELD_INPUT_CHANGE":
    case "FR05-NEW2-COUPLE-ADD_FIELD_INPUT_CHANGE":
    case "FR05_FIELD_INPUT_CHANGE":
    case "FR05-NEW1_FIELD_INPUT_CHANGE":
    case "FR05-NEW2_FIELD_INPUT_CHANGE":
    case "FR06_FIELD_INPUT_CHANGE":
    case "FR06-HALF_FIELD_INPUT_CHANGE":
    case "FR06-ADOPTED_FIELD_INPUT_CHANGE":
    case "FR06-STEP_FIELD_INPUT_CHANGE":
    case "FR07_FIELD_INPUT_CHANGE":
    case "FR07-HALF_FIELD_INPUT_CHANGE":
    case "FR07-ADOPTED_FIELD_INPUT_CHANGE":
    case "FR07-STEP_FIELD_INPUT_CHANGE":
    case "FR-NEW11-1_FIELD_INPUT_CHANGE":
    case "FR-NEW11-2_FIELD_INPUT_CHANGE":
    case "FR-NEW11-3_FIELD_INPUT_CHANGE":
    case "FR-NEW11-4_FIELD_INPUT_CHANGE":
    case "FR-NEW11-5_FIELD_INPUT_CHANGE":
    case "FR08_FIELD_INPUT_CHANGE":
    case "FR10-1_FIELD_INPUT_CHANGE":
    case "FR12_FIELD_INPUT_CHANGE":
    case "FR13_FIELD_INPUT_CHANGE":
    case "FR18A-NO_FIELD_INPUT_CHANGE":
    case "FR18B-NO_FIELD_INPUT_CHANGE":
    case "FR18C-NO_FIELD_INPUT_CHANGE":
    case "FR18D-NO_FIELD_INPUT_CHANGE":
    case "FR18E-NO_FIELD_INPUT_CHANGE":
    case "FR18F-NO_FIELD_INPUT_CHANGE":
    case "FR18A-1-NO_FIELD_INPUT_CHANGE":
    case "FR18B-1-NO_FIELD_INPUT_CHANGE":
    case "FR18C-1-NO_FIELD_INPUT_CHANGE":
    case "FR18D-1-NO_FIELD_INPUT_CHANGE":
    case "FR18E-1-NO_FIELD_INPUT_CHANGE":
    case "FR18F-1-NO_FIELD_INPUT_CHANGE":
    case "FR19A-YES_FIELD_INPUT_CHANGE":
    case "FR19B-YES_FIELD_INPUT_CHANGE":
    case "FR19C-YES_FIELD_INPUT_CHANGE":
    case "FR19D-YES_FIELD_INPUT_CHANGE":
    case "FR19E-YES_FIELD_INPUT_CHANGE":
    case "FR19F-YES_FIELD_INPUT_CHANGE":
    case "FR19G-YES_FIELD_INPUT_CHANGE":
    case "FR19H-YES_FIELD_INPUT_CHANGE":
    case "FR19J-YES_FIELD_INPUT_CHANGE":
    case "FR19A-1-YES_FIELD_INPUT_CHANGE":
    case "FR19B-1-YES_FIELD_INPUT_CHANGE":
    case "FR19C-1-YES_FIELD_INPUT_CHANGE":
    case "FR19D-1-YES_FIELD_INPUT_CHANGE":
    case "FR19E-1-YES_FIELD_INPUT_CHANGE":
    case "FR19F-1-YES_FIELD_INPUT_CHANGE":
    case "FR19G-1-YES_FIELD_INPUT_CHANGE":
    case "FR19H-1-YES_FIELD_INPUT_CHANGE":
    case "FR19J-1-YES_FIELD_INPUT_CHANGE":
    case "FR8-FREE_FIELD_INPUT_CHANGE":
    case "FR8-ADD_FIELD_INPUT_CHANGE":
    case "FR-NEW18_FIELD_INPUT_CHANGE":
    case "FR-NEW18A_FIELD_INPUT_CHANGE":
    case "FR16_FIELD_INPUT_CHANGE":
    case "FR17A_FIELD_INPUT_CHANGE":
    case "FR17B_FIELD_INPUT_CHANGE":
    case "FR20A_FIELD_INPUT_CHANGE":
    case "FR20B_FIELD_INPUT_CHANGE":
    case "FR21A_FIELD_INPUT_CHANGE":
    case "FR21B_FIELD_INPUT_CHANGE":
    case "FR22A_FIELD_INPUT_CHANGE":
    case "FR22B_FIELD_INPUT_CHANGE":
    case "FR39-1_FIELD_INPUT_CHANGE":
    case "FR40_FIELD_INPUT_CHANGE":
    case "FR41D_FIELD_INPUT_CHANGE":
    case "FR25-AD2_FIELD_INPUT_CHANGE":
    case "FR25-AD3_FIELD_INPUT_CHANGE":
    case "FR26-AD2_FIELD_INPUT_CHANGE":
    case "FR26-AD3_FIELD_INPUT_CHANGE":
    case "FR27-AD2_FIELD_INPUT_CHANGE":
    case "FR27-AD3_FIELD_INPUT_CHANGE":
    case "FR28-AD2_FIELD_INPUT_CHANGE":
    case "FR28-AD3_FIELD_INPUT_CHANGE":
    case "FR29-AD2_FIELD_INPUT_CHANGE":
    case "FR29-AD3_FIELD_INPUT_CHANGE":
    case "FR30-AD2_FIELD_INPUT_CHANGE":
    case "FR30-AD3_FIELD_INPUT_CHANGE":
    case "FR31-AD2_FIELD_INPUT_CHANGE":
    case "FR31-AD3_FIELD_INPUT_CHANGE":
    case "FR32-AD2_FIELD_INPUT_CHANGE":
    case "FR32-AD3_FIELD_INPUT_CHANGE":
    case "FR33-AD2_FIELD_INPUT_CHANGE":
    case "FR33-AD3_FIELD_INPUT_CHANGE":
    case "FR34-AD2_FIELD_INPUT_CHANGE":
    case "FR34-AD3_FIELD_INPUT_CHANGE":
    case "FR35-AD2_FIELD_INPUT_CHANGE":
    case "FR35-AD3_FIELD_INPUT_CHANGE":
    case "FR41C_FIELD_INPUT_CHANGE":
    case "PS1-NEW2_FIELD_INPUT_CHANGE":
    case "PS1-NEW4_FIELD_INPUT_CHANGE":
    case "PS1-NEW6_FIELD_INPUT_CHANGE":
    case "PS15-17_FIELD_INPUT_CHANGE":
    case "PS13-19_FIELD_INPUT_CHANGE":
    case "PS16-1_FIELD_INPUT_CHANGE":
    case "PS17_FIELD_INPUT_CHANGE":
    case "PS18D_FIELD_INPUT_CHANGE":
    case "PS5-YES_FIELD_INPUT_CHANGE":
    case "PS4-YES_FIELD_INPUT_CHANGE":
    case "PS11-YES_FIELD_INPUT_CHANGE":
    case "PS10-YES_FIELD_INPUT_CHANGE":
    case "PS6-YES_FIELD_INPUT_CHANGE":
    case "PS9-YES_FIELD_INPUT_CHANGE":
    case "PS8-YES_FIELD_INPUT_CHANGE":
    case "PS12-YES_FIELD_INPUT_CHANGE":
    case "PS7-YES_FIELD_INPUT_CHANGE":
    case "PS5-1-YES_FIELD_INPUT_CHANGE":
    case "PS4-1-YES_FIELD_INPUT_CHANGE":
    case "PS11-1-YES_FIELD_INPUT_CHANGE":
    case "PS10-1-YES_FIELD_INPUT_CHANGE":
    case "PS6-1-YES_FIELD_INPUT_CHANGE":
    case "PS9-1-YES_FIELD_INPUT_CHANGE":
    case "PS8-1-YES_FIELD_INPUT_CHANGE":
    case "PS12-1-YES_FIELD_INPUT_CHANGE":
    case "PS7-1-YES_FIELD_INPUT_CHANGE":
    case "PS18C_FIELD_INPUT_CHANGE":
    case "DA16-NEW132_FIELD_INPUT_CHANGE":
    case "LE-NEW2_FIELD_INPUT_CHANGE":
    case "LE-NEW3_FIELD_INPUT_CHANGE":
    case "LE-NEW5_FIELD_INPUT_CHANGE":
    case "LE-NEW6_FIELD_INPUT_CHANGE":
    case "LE31-ADD_FIELD_INPUT_CHANGE":
    case "LE32-ADD_FIELD_INPUT_CHANGE":
    case "LE-NEW9_FIELD_INPUT_CHANGE":
    case "LE-NEW10_FIELD_INPUT_CHANGE":
    case "LE-NEW13_FIELD_INPUT_CHANGE":
    case "LE-NEW14_FIELD_INPUT_CHANGE":
    case "LE-NEW17_FIELD_INPUT_CHANGE":
    case "LE-NEW18_FIELD_INPUT_CHANGE":
    case "LE-NEW21_FIELD_INPUT_CHANGE":
    case "LE-NEW22_FIELD_INPUT_CHANGE":
    case "LE-NEW25_FIELD_INPUT_CHANGE":
    case "LE-NEW26_FIELD_INPUT_CHANGE":
    case "LE-NEW29_FIELD_INPUT_CHANGE":
    case "LE-NEW30_FIELD_INPUT_CHANGE":
    case "LE-NEW33_FIELD_INPUT_CHANGE":
    case "LE-NEW34_FIELD_INPUT_CHANGE":
    case "LE-NEW37_FIELD_INPUT_CHANGE":
    case "LE-NEW38_FIELD_INPUT_CHANGE":
    case "LE-NEW41_FIELD_INPUT_CHANGE":
    case "LE-NEW42_FIELD_INPUT_CHANGE":
    case "LE-NEW45_FIELD_INPUT_CHANGE":
    case "LE-NEW46_FIELD_INPUT_CHANGE":
    case "LE-NEW49_FIELD_INPUT_CHANGE":
    case "LE-NEW50_FIELD_INPUT_CHANGE":
    case "LE-NEW53_FIELD_INPUT_CHANGE":
    case "LE-NEW54_FIELD_INPUT_CHANGE":
    case "LE-NEW57_FIELD_INPUT_CHANGE":
    case "LE-NEW58_FIELD_INPUT_CHANGE":
    case "LE-NEW61_FIELD_INPUT_CHANGE":
    case "LE-NEW62_FIELD_INPUT_CHANGE":
    case "LE-NEW65_FIELD_INPUT_CHANGE":
    case "LE-NEW66_FIELD_INPUT_CHANGE":
    case "LE-NEW69_FIELD_INPUT_CHANGE":
    case "LE-NEW70_FIELD_INPUT_CHANGE":
    case "LE-NEW73_FIELD_INPUT_CHANGE":
    case "LE-NEW74_FIELD_INPUT_CHANGE":
    case "LE-NEW77_FIELD_INPUT_CHANGE":
    case "LE-NEW78_FIELD_INPUT_CHANGE":
    case "LE-NEW79_FIELD_INPUT_CHANGE":
    case "LE-NEW82_FIELD_INPUT_CHANGE":
    case "LE-NEW83_FIELD_INPUT_CHANGE":
    case "LE-NEW86_FIELD_INPUT_CHANGE":
    case "LE-NEW87_FIELD_INPUT_CHANGE":
    case "LE-NEW90_FIELD_INPUT_CHANGE":
    case "LE-NEW91_FIELD_INPUT_CHANGE":
    case "LE-NEW92_FIELD_INPUT_CHANGE":
    case "LE-NEW95_FIELD_INPUT_CHANGE":
    case "LE-NEW96_FIELD_INPUT_CHANGE":
    case "LE-NEW98_FIELD_INPUT_CHANGE":
    case "LE-NEW99_FIELD_INPUT_CHANGE":
    case "LE-NEW100A_FIELD_INPUT_CHANGE":
    case "LE-NEW102_FIELD_INPUT_CHANGE":
    case "LE-NEW103-1_FIELD_INPUT_CHANGE":
    case "LE-NEW104_FIELD_INPUT_CHANGE":
    case "LE30D_FIELD_INPUT_CHANGE":
    case "LE30C_FIELD_INPUT_CHANGE":
    case "DA2-COMM_FIELD_INPUT_CHANGE":
    case "DA5-5-COMM_FIELD_INPUT_CHANGE":
    case "DA6-5-COMM_FIELD_INPUT_CHANGE":
    case "DA7-5-NEW-COMM_FIELD_INPUT_CHANGE":
    case "DA7-5-NEW1-COMM_FIELD_INPUT_CHANGE":
    case "DA8-5-NEW-COMM_FIELD_INPUT_CHANGE":
    case "DA8-5-COMM_FIELD_INPUT_CHANGE":
    case "DA9-5-COMM_FIELD_INPUT_CHANGE":
    case "DA10-5-COMM_FIELD_INPUT_CHANGE":
    case "DA11-5-COMM_FIELD_INPUT_CHANGE":
    case "DA12-5-COMM_FIELD_INPUT_CHANGE":
    case "DA13-5-COMM_FIELD_INPUT_CHANGE":
    case "DA14-5-COMM_FIELD_INPUT_CHANGE":
    case "DA15-5-COMM_FIELD_INPUT_CHANGE":
    case "DA16-5-COMM_FIELD_INPUT_CHANGE":
    case "AC01-1_FIELD_INPUT_CHANGE":
    case "AC02-1_FIELD_INPUT_CHANGE":
    case "AC03-1_FIELD_INPUT_CHANGE":
    case "AC04-1_FIELD_INPUT_CHANGE":
    case "AC05-1_FIELD_INPUT_CHANGE":
    case "AC06-1_FIELD_INPUT_CHANGE":
    case "AC01-1-V4_FIELD_INPUT_CHANGE":
    case "AC02-1-V4_FIELD_INPUT_CHANGE":
    case "AC03-1-V4_FIELD_INPUT_CHANGE":
    case "AC04-1-V4_FIELD_INPUT_CHANGE":
    case "AC05-1-V4_FIELD_INPUT_CHANGE":
    case "AC06-1-V4_FIELD_INPUT_CHANGE":
    case "AC07_FIELD_INPUT_CHANGE":
    case "SUM1_FIELD_INPUT_CHANGE":
    case "SUM1-1_FIELD_INPUT_CHANGE":
    case "SUM2_FIELD_INPUT_CHANGE":
    case "SUM2-1_FIELD_INPUT_CHANGE":
    case "SUM3_FIELD_INPUT_CHANGE":
    case "SUM3-1_FIELD_INPUT_CHANGE":
    case "SUM4_FIELD_INPUT_CHANGE":
    case "SUM4-1_FIELD_INPUT_CHANGE":
    case "SUM02C_FIELD_INPUT_CHANGE":
    case "SUM04GEN_FIELD_INPUT_CHANGE":
    case "SUM01-RAT_FIELD_INPUT_CHANGE":
    case "SAW1_FIELD_INPUT_CHANGE":
    case "SAW2_FIELD_INPUT_CHANGE":
    case "SAW3_FIELD_INPUT_CHANGE":
    case "SAW4_FIELD_INPUT_CHANGE":
    case "MSE1-1_FIELD_INPUT_CHANGE":
    case "MSE1-1-1_FIELD_INPUT_CHANGE":
    case "MSE1-2_FIELD_INPUT_CHANGE":
    case "MSE1-10-1_FIELD_INPUT_CHANGE":
    case "MSE1-11-1_FIELD_INPUT_CHANGE":
    case "MSE1-12-1_FIELD_INPUT_CHANGE":
    case "MSE1-13-1_FIELD_INPUT_CHANGE":
    case "MSE2-BEHATT-13-1_FIELD_INPUT_CHANGE":
    case "MSE2-10-1_FIELD_INPUT_CHANGE":
    case "MSE2-21-1_FIELD_INPUT_CHANGE":
    case "MSE2-33-1_FIELD_INPUT_CHANGE":
    case "MSE3-6-1_FIELD_INPUT_CHANGE":
    case "MSE3-26-1_FIELD_INPUT_CHANGE":
    case "MSE3-39-1_FIELD_INPUT_CHANGE":
    case "MSE5-12-1_FIELD_INPUT_CHANGE":
    case "MSE6-2-1_FIELD_INPUT_CHANGE":
    case "MSE6-4-1_FIELD_INPUT_CHANGE":
    case "MSE6-5-1_FIELD_INPUT_CHANGE":
    case "MSE6-10-1_FIELD_INPUT_CHANGE":
    case "MSE6-11-1_FIELD_INPUT_CHANGE":
    case "MSE7C_FIELD_INPUT_CHANGE":
    case "MSE4-15-1_FIELD_INPUT_CHANGE":
    case "DEMO11-ADOL1_FIELD_INPUT_CHANGE":
    case "DEMO11-ADOL2-1_FIELD_INPUT_CHANGE":
    case "M12-ADOL1_FIELD_INPUT_CHANGE":
    case "M12-ADOL2_FIELD_INPUT_CHANGE":
    case "M12-ADOL3_FIELD_INPUT_CHANGE":
    case "M12-ADOL4_FIELD_INPUT_CHANGE":
    case "ED6-ADOL1-1_FIELD_INPUT_CHANGE":
    case "ED6-ADOL1-2_FIELD_INPUT_CHANGE":
    case "ED6-ADOL1-3_FIELD_INPUT_CHANGE":
    // case "ED1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW36-ADOL1-1_FIELD_INPUT_CHANGE":
    case "ED1-ADOL1-1_FIELD_INPUT_CHANGE":
    case "FR01-ADOL1_FIELD_INPUT_CHANGE":
    case "FR01-T-ADOL1_FIELD_INPUT_CHANGE":
    case "M-NEW15-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW1-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW2-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW3-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW4-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW5-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW6-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW7-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW8-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW9-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW10-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW11-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW12-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW13-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW14-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW15-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW16-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW17-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW18-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW19-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW20-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW21-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW22-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW23-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW24-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW25-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW26-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW27-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW28-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW29-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW30-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW31-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW32-1-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW32-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW35-ADOL1_FIELD_INPUT_CHANGE":
    // case "LE-NEW36-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW37-ADOL1_FIELD_INPUT_CHANGE":
    case "FR06-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR07-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR08-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR06-2-ADOL1_FIELD_INPUT_CHANGE":
    case "FR07-2-ADOL1_FIELD_INPUT_CHANGE":
    case "FR10-ADOL1_FIELD_INPUT_CHANGE":
    case "FR11-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR12-ADOL1_FIELD_INPUT_CHANGE":
    case "FR15-ADOL1_FIELD_INPUT_CHANGE":
    case "FR16-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR17-ADOL1_FIELD_INPUT_CHANGE":
    case "FR20-ADOL1_FIELD_INPUT_CHANGE":
    case "FR22-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR23-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR23-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR24-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR25-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR25-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR26-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR27-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR27-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR28-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR29-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR29-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR30-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR31-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR31-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR32-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR33-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR33-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR34-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR35-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR35-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR36-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR37-WHO-ADOL1_FIELD_INPUT_CHANGE":
    case "FR37-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR38-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR39-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR40-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR41-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR42-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR43-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR44-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR45-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR46-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR47-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR48-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR49-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR50-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR66-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR67-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR68-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR69-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR70-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR71-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR72-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR73-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR74-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR75-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR76-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR77-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR78-82-ADOL1_FIELD_INPUT_CHANGE":
    case "FR79-82-ADOL1_FIELD_INPUT_CHANGE":
    case "FR80-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR81-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR82-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR83-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR84-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR85-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR88-1-ADOL1_FIELD_INPUT_CHANGE":
    case "FR89-ADOL1_FIELD_INPUT_CHANGE":
    case "PS01-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS01-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS02-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS02-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS03-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS03-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS04-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS04-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS05-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS05-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS06-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS07-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS08-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS09-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS10-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS11-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS12-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS13-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS14-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS15-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS16-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS17-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS18-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS19-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS20-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS21-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS22-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS23-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS24-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS25-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS26-1-ADOL1_FIELD_INPUT_CHANGE":
    case "PS07-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS08-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS09-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS10-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS11-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS12-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS13-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS14-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS15-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS16-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS17-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS18-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS19-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS20-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS21-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS22-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS23-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS24-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS25-2-ADOL1_FIELD_INPUT_CHANGE":
    case "PS26-2-ADOL1_FIELD_INPUT_CHANGE":
    case "ED1-1-ADOL2_FIELD_INPUT_CHANGE":
    case "ED1-ADOL3_FIELD_INPUT_CHANGE":
    case "ED1-ADOL3-2_FIELD_INPUT_CHANGE":
    case "ED1-ADOL4_FIELD_INPUT_CHANGE":
    case "ED1-C-ADOL4_FIELD_INPUT_CHANGE":
    case "ED15-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED16-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED17-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED18-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED19-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED20-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED21-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED22-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED23-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED24-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED25-ADOL1_FIELD_INPUT_CHANGE":
    case "ED26-ADOL1_FIELD_INPUT_CHANGE":
    case "ED27-1-ADOL1_FIELD_INPUT_CHANGE":
    case "ED12-ADOL1_FIELD_INPUT_CHANGE":
    case "ED-NEW52-ADOL1_FIELD_INPUT_CHANGE":
    case "ED-NEW54-ADOL1_FIELD_INPUT_CHANGE":
    case "ED34-ADOL1_FIELD_INPUT_CHANGE":
    case "DA17-1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA18-1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA19-1-ADOL1_FIELD_INPUT_CHANGE":
    case "DA16-NEW125-ADOL1_FIELD_INPUT_CHANGE":
    case "DEMO9-ADOL3-1_FIELD_INPUT_CHANGE":
    case "LE-NEW33-ADOL1-1_FIELD_INPUT_CHANGE":
    case "ED15-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED16-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED17-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED18-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED19-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED20-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED21-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED22-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED23-C-ADOL1_FIELD_INPUT_CHANGE":
    case "ED24-C-ADOL1_FIELD_INPUT_CHANGE":
    case "DA17-C-ADOL1_FIELD_INPUT_CHANGE":
    case "DA18-C-ADOL1_FIELD_INPUT_CHANGE":
    case "DA19-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW1-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW2-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW3-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW4-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW5-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW6-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW7-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW8-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW9-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW10-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW11-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW12-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW13-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW14-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW15-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW16-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW17-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW18-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW19-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW20-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW21-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW22-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW23-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW24-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW25-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW26-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW27-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW28-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW29-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW30-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW31-C-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW32-COM-ADOL1_FIELD_INPUT_CHANGE":
    case "FR22-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR23-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR24-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR25-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR26-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR27-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR28-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR29-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR30-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR31-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR32-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR33-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR34-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR35-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR36-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR37-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR66-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR67-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR68-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR69-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR70-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR71-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR72-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR73-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR74-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR75-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR76-C-ADOL1_FIELD_INPUT_CHANGE":
    case "FR77-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS01-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS02-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS03-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS04-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS05-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS06-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS07-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS08-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS09-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS10-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS11-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS12-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS13-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS14-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS15-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS16-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS17-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS18-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS19-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS20-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS21-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS22-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS23-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS24-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS25-C-ADOL1_FIELD_INPUT_CHANGE":
    case "PS26-C-ADOL1_FIELD_INPUT_CHANGE":

    case "ES1-1_FIELD_INPUT_CHANGE":
    case "ES2-1_FIELD_INPUT_CHANGE":
    case "ES3-1_FIELD_INPUT_CHANGE":
    case "GI1_FIELD_INPUT_CHANGE":
    case "GI4_FIELD_INPUT_CHANGE":
    case "GI5_FIELD_INPUT_CHANGE":
    case "GI6_FIELD_INPUT_CHANGE":
    case "GI7_FIELD_INPUT_CHANGE":
    case "GI8_FIELD_INPUT_CHANGE":
    case "GI9_FIELD_INPUT_CHANGE":
    case "GI99_FIELD_INPUT_CHANGE":
    case "GI11_FIELD_INPUT_CHANGE":
    case "GI12_FIELD_INPUT_CHANGE":
    case "GI14_FIELD_INPUT_CHANGE":
    // case "GI16_FIELD_INPUT_CHANGE":
    case "GI17_FIELD_INPUT_CHANGE":
    case "GI18_FIELD_INPUT_CHANGE":
    // case "GI19_FIELD_INPUT_CHANGE":
    case "GI20_FIELD_INPUT_CHANGE":
    case "GI-COMMENT_FIELD_INPUT_CHANGE":
    // case "MS1_FIELD_INPUT_CHANGE":
    case "MS2_FIELD_INPUT_CHANGE":
    case "MS6_FIELD_INPUT_CHANGE":
    case "MS7_FIELD_INPUT_CHANGE":
    case "MS8_FIELD_INPUT_CHANGE":
    case "MS9_FIELD_INPUT_CHANGE":
    case "MS-COMMENT_FIELD_INPUT_CHANGE":
    case "ES1_FIELD_INPUT_CHANGE":
    case "ES2_FIELD_INPUT_CHANGE":
    case "ES6_FIELD_INPUT_CHANGE":
    case "ES7_FIELD_INPUT_CHANGE":
    // case "ES7-1_FIELD_INPUT_CHANGE":
    case "ES10_FIELD_INPUT_CHANGE":
    case "ES11_FIELD_INPUT_CHANGE":

    case "ES18_FIELD_INPUT_CHANGE":
    case "ES19_FIELD_INPUT_CHANGE":
    case "ES20_FIELD_INPUT_CHANGE":
    case "ES21_FIELD_INPUT_CHANGE":
    case "ES22_FIELD_INPUT_CHANGE":
    case "ES-COMMENT_FIELD_INPUT_CHANGE":

    case "DS14_FIELD_INPUT_CHANGE":
    case "DS16_FIELD_INPUT_CHANGE":
    case "DS17_FIELD_INPUT_CHANGE":
    case "DS18_FIELD_INPUT_CHANGE":
    case "DS25_FIELD_INPUT_CHANGE":
    case "DS26_FIELD_INPUT_CHANGE":
    case "DS27_FIELD_INPUT_CHANGE":
    case "DS28_FIELD_INPUT_CHANGE":
    case "DS29_FIELD_INPUT_CHANGE":
    case "DS30_FIELD_INPUT_CHANGE":
    case "DS31_FIELD_INPUT_CHANGE":
    case "DS32_FIELD_INPUT_CHANGE":
    case "DS33_FIELD_INPUT_CHANGE":
    case "DS-COMMENT_FIELD_INPUT_CHANGE":
    // case "LS3_FIELD_INPUT_CHANGE":
    // case "LS4_FIELD_INPUT_CHANGE":
    // case "LS5_FIELD_INPUT_CHANGE":
    // case "LS6_FIELD_INPUT_CHANGE":
    // case "LS7_FIELD_INPUT_CHANGE":
    // case "LS8_FIELD_INPUT_CHANGE":
    // case "LS9_FIELD_INPUT_CHANGE":
    // case "LS10_FIELD_INPUT_CHANGE":
    // case "LS11_FIELD_INPUT_CHANGE":
    // case "LS12_FIELD_INPUT_CHANGE":
    // case "LS13_FIELD_INPUT_CHANGE":
    // case "LS14_FIELD_INPUT_CHANGE":
    // case "LS15_FIELD_INPUT_CHANGE":
    // case "LS16_FIELD_INPUT_CHANGE":
    case "LS17_FIELD_INPUT_CHANGE":
    // case "LS18_FIELD_INPUT_CHANGE":
    // case "LS19_FIELD_INPUT_CHANGE":
    // case "LS20_FIELD_INPUT_CHANGE":
    // case "LS21_FIELD_INPUT_CHANGE":
    case "LS22_FIELD_INPUT_CHANGE":
    case "LS23_FIELD_INPUT_CHANGE":
    case "LS25_FIELD_INPUT_CHANGE":
    case "LS26_FIELD_INPUT_CHANGE":
    case "LS27_FIELD_INPUT_CHANGE":
    case "LS28_FIELD_INPUT_CHANGE":
    case "LS29_FIELD_INPUT_CHANGE":
    case "LS30_FIELD_INPUT_CHANGE":
    case "LS-COMMENT_FIELD_INPUT_CHANGE":
    case "FS-COMMENT_FIELD_INPUT_CHANGE":
    case "FS2_FIELD_INPUT_CHANGE":
    case "FS4_FIELD_INPUT_CHANGE":
    case "FS5_FIELD_INPUT_CHANGE":
    case "FS11_FIELD_INPUT_CHANGE":
    case "FS18-6-1_FIELD_INPUT_CHANGE":
    case "FS18-15-1_FIELD_INPUT_CHANGE":
    case "FS32_FIELD_INPUT_CHANGE":
    case "FS33_FIELD_INPUT_CHANGE":
    case "FS34_FIELD_INPUT_CHANGE":
    case "FS35_FIELD_INPUT_CHANGE":
    case "FS36_FIELD_INPUT_CHANGE":
    case "FS1-COMMENT_FIELD_INPUT_CHANGE":
    case "PSS1_FIELD_INPUT_CHANGE":
    case "PSS2_FIELD_INPUT_CHANGE":
    // case "PSS4_FIELD_INPUT_CHANGE":
    // case "PSS5_FIELD_INPUT_CHANGE":
    // case "PSS6_FIELD_INPUT_CHANGE":
    // case "PSS7_FIELD_INPUT_CHANGE":
    // case "PSS8_FIELD_INPUT_CHANGE":
    // case "PSS9_FIELD_INPUT_CHANGE":
    // case "PSS10_FIELD_INPUT_CHANGE":
    // case "PSS11_FIELD_INPUT_CHANGE":
    case "PSS12_FIELD_INPUT_CHANGE":
    case "PSS13_FIELD_INPUT_CHANGE":
    case "PSS14_FIELD_INPUT_CHANGE":
    case "PSS21_FIELD_INPUT_CHANGE":
    case "PSS-COMMENT_FIELD_INPUT_CHANGE":
    // case "FR51-1-ADOL1_FIELD_INPUT_CHANGE":
    // case "FR52-1-ADOL1_FIELD_INPUT_CHANGE":
    // case "FR53-1-ADOL1_FIELD_INPUT_CHANGE":
    case "DS1-1-1-1_FIELD_INPUT_CHANGE":
    case "DS1-1-2-1_FIELD_INPUT_CHANGE":
    case "DS1-1-3-1_FIELD_INPUT_CHANGE":
    case "DS1-2-1-1_FIELD_INPUT_CHANGE":
    case "DS1-2-2-1_FIELD_INPUT_CHANGE":
    case "DS1-2-3-1_FIELD_INPUT_CHANGE":
    case "DS1-3-1-1_FIELD_INPUT_CHANGE":
    case "DS1-3-2-1_FIELD_INPUT_CHANGE":
    case "DS1-3-3-1_FIELD_INPUT_CHANGE":
    case "DS1-4-1-1_FIELD_INPUT_CHANGE":
    case "DS1-4-2-1_FIELD_INPUT_CHANGE":
    case "DS1-4-3-1_FIELD_INPUT_CHANGE":
    case "DS1-5-1-1_FIELD_INPUT_CHANGE":
    case "DS1-5-2-1_FIELD_INPUT_CHANGE":
    case "DS1-5-3-1_FIELD_INPUT_CHANGE":
    case "DS1-6-1-1_FIELD_INPUT_CHANGE":
    case "DS1-6-2-1_FIELD_INPUT_CHANGE":
    case "DS1-6-3-1_FIELD_INPUT_CHANGE":
    case "DS1-7-1-1_FIELD_INPUT_CHANGE":
    case "DS1-7-2-1_FIELD_INPUT_CHANGE":
    case "DS1-7-3-1_FIELD_INPUT_CHANGE":
    case "DS1-8-1-1_FIELD_INPUT_CHANGE":
    case "DS1-8-2-1_FIELD_INPUT_CHANGE":
    case "DS1-8-3-1_FIELD_INPUT_CHANGE":
    case "DS1-9-1-1_FIELD_INPUT_CHANGE":
    case "DS1-9-2-1_FIELD_INPUT_CHANGE":
    case "DS1-9-3-1_FIELD_INPUT_CHANGE":
    case "DS1-10-1-1_FIELD_INPUT_CHANGE":
    case "DS1-10-2-1_FIELD_INPUT_CHANGE":
    case "DS1-10-3-1_FIELD_INPUT_CHANGE":
    case "DS1-11-1-1_FIELD_INPUT_CHANGE":
    case "DS1-11-2-1_FIELD_INPUT_CHANGE":
    case "DS1-11-3-1_FIELD_INPUT_CHANGE":
    case "DS1-12-1-1_FIELD_INPUT_CHANGE":
    case "DS1-12-2-1_FIELD_INPUT_CHANGE":
    case "DS1-12-3-1_FIELD_INPUT_CHANGE":
    case "DS1-13-1-1_FIELD_INPUT_CHANGE":
    case "DS1-13-2-1_FIELD_INPUT_CHANGE":
    case "DS1-13-3-1_FIELD_INPUT_CHANGE":
    case "MS3-1_FIELD_INPUT_CHANGE":
    case "MS4-1_FIELD_INPUT_CHANGE":
    case "MS5-1_FIELD_INPUT_CHANGE":
    case "LS2-1_FIELD_INPUT_CHANGE":
    case "FS0-1-1-C_FIELD_INPUT_CHANGE":
    case "FS0-1-2-C_FIELD_INPUT_CHANGE":
    case "FS0-1-3-C_FIELD_INPUT_CHANGE":
    case "FS0-2-1-C_FIELD_INPUT_CHANGE":
    case "FS0-2-2-C_FIELD_INPUT_CHANGE":
    case "FS0-2-3-C_FIELD_INPUT_CHANGE":
    case "FS0-3-1-C_FIELD_INPUT_CHANGE":
    case "FS0-3-2-C_FIELD_INPUT_CHANGE":
    case "FS0-3-3-C_FIELD_INPUT_CHANGE":
    case "FS0-4-1-C_FIELD_INPUT_CHANGE":
    case "FS0-4-2-C_FIELD_INPUT_CHANGE":
    case "FS0-4-3-C_FIELD_INPUT_CHANGE":
    case "FS0-5-1-C_FIELD_INPUT_CHANGE":
    case "FS0-5-2-C_FIELD_INPUT_CHANGE":
    case "FS0-5-3-C_FIELD_INPUT_CHANGE":
    case "FS0-6-1-C_FIELD_INPUT_CHANGE":
    case "FS0-6-2-C_FIELD_INPUT_CHANGE":
    case "FS0-6-3-C_FIELD_INPUT_CHANGE":
    case "FS0-7-1-C_FIELD_INPUT_CHANGE":
    case "FS0-7-2-C_FIELD_INPUT_CHANGE":
    case "FS0-7-3-C_FIELD_INPUT_CHANGE":
    case "FS0-8-1-C_FIELD_INPUT_CHANGE":
    case "FS0-8-2-C_FIELD_INPUT_CHANGE":
    case "FS0-8-3-C_FIELD_INPUT_CHANGE":
    case "FS0-9-1-C_FIELD_INPUT_CHANGE":
    case "FS0-9-2-C_FIELD_INPUT_CHANGE":
    case "FS0-9-3-C_FIELD_INPUT_CHANGE":
    case "FS0-10-1-C_FIELD_INPUT_CHANGE":
    case "FS0-10-2-C_FIELD_INPUT_CHANGE":
    case "FS0-10-3-C_FIELD_INPUT_CHANGE":
    case "FS0-11-1-C_FIELD_INPUT_CHANGE":
    case "FS0-11-2-C_FIELD_INPUT_CHANGE":
    case "FS0-11-3-C_FIELD_INPUT_CHANGE":
    case "FS0-12-1-C_FIELD_INPUT_CHANGE":
    case "FS0-12-2-C_FIELD_INPUT_CHANGE":
    case "FS0-12-3-C_FIELD_INPUT_CHANGE":
    case "FR08-00-C_FIELD_INPUT_CHANGE":
    case "PSS20-1-1_FIELD_INPUT_CHANGE":
    case "PSS20-2_FIELD_INPUT_CHANGE":
    case "PL0-BUT_FIELD_INPUT_CHANGE":
      // console.log("1 action.payload=",action.payload,"act[0]=",act[0]);
      let localPayload = action.payload === null ? "" : action.payload;
      let actChanged = action.type.replace("_FIELD_INPUT_CHANGE", "");
      // console.log("hafreducer", actChanged);
      return Object.assign({}, state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: localPayload } : el1
                ),
              }
            : el
        ),
      });
    case "OUTPUT_ADVISOR_ADVICE_TO_JSON":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === "PL2" ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "GI14_FIELD2_INPUT_CHANGE":
    case "MS2_FIELD2_INPUT_CHANGE":
    case "ES1_FIELD2_INPUT_CHANGE":
    case "ES6_FIELD2_INPUT_CHANGE":
    case "FS2_FIELD2_INPUT_CHANGE":
    case "FS5_FIELD2_INPUT_CHANGE":
    case "GI6_FIELD2_INPUT_CHANGE":
    case "GI7_FIELD2_INPUT_CHANGE":
      // state.assessment_state.map((el) => el.body.map((el1) => {if(el1.act === act[0])console.log("el1=",el1)}))
      // console.log("state.assessment_state=",state.assessment_state);
      localPayload = action.payload === null ? "" : action.payload;
      return Object.assign({}, state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value2: localPayload } : el1
                ),
              }
            : el
        ),
      });
    case "DA1-RANK_FIELD_INPUT_CHANGE":
    case "DA5-RANK_FIELD_INPUT_CHANGE":
    case "DA6-RANK_FIELD_INPUT_CHANGE":
    case "DA7-RANK_FIELD_INPUT_CHANGE":
    case "DA7-RANK1_FIELD_INPUT_CHANGE":
    case "DA8-RANK_FIELD_INPUT_CHANGE":
    case "DA8-RANK0_FIELD_INPUT_CHANGE":
    case "DA9-RANK_FIELD_INPUT_CHANGE":
    case "DA10-RANK_FIELD_INPUT_CHANGE":
    case "DA11-RANK_FIELD_INPUT_CHANGE":
    case "DA12-RANK_FIELD_INPUT_CHANGE":
    case "DA13-RANK_FIELD_INPUT_CHANGE":
    case "DA14-RANK_FIELD_INPUT_CHANGE":
    case "DA15-RANK_FIELD_INPUT_CHANGE":
    case "DA16-RANK_FIELD_INPUT_CHANGE":
      actChanged = action.type.replace("_FIELD_INPUT_CHANGE", "");
      // console.log("act[0]=", act[0], "act[0]=", act[0]);
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (
                    (el1.act === "DA1-RANK" ||
                      el1.act === "DA5-RANK" ||
                      el1.act === "DA6-RANK" ||
                      el1.act === "DA7-RANK" ||
                      el1.act === "DA7-RANK1" ||
                      el1.act === "DA8-RANK" ||
                      el1.act === "DA8-RANK0" ||
                      el1.act === "DA9-RANK" ||
                      el1.act === "DA10-RANK" ||
                      el1.act === "DA11-RANK" ||
                      el1.act === "DA12-RANK" ||
                      el1.act === "DA13-RANK" ||
                      el1.act === "DA14-RANK" ||
                      el1.act === "DA15-RANK" ||
                      el1.act === "DA16-RANK") &&
                    action.payload === el1.value
                  ) {
                    return { ...el1, value: "" };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });

      tmp_state1 = Object.assign({}, tmp_state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
      let sumRanks = 0;
      for (var i = 0; i < tmp_state1.assessment_state.length; i++) {
        let topic = tmp_state1.assessment_state[i];
        // console.log("topic=",topic)
        for (var j = 0; j < topic.body.length; j++) {
          let fieldObj = Object.assign({}, topic.body[j]);
          if (fieldObj.type === "rank" && fieldObj.disabled === "false") {
            sumRanks += Number(fieldObj.value);
          }
        }
      }
      return Object.assign({}, tmp_state1, { RankStatus: sumRanks });

    case "DEMO1_FIELD_INPUT_CHANGE":
      // console.log("action=",action,"act[0]=",act[0]);
      // tmp_state = Object.assign({}, state, {
      //   assessment_state: state.assessment_state.map(el =>
      //     el.topic !== undefined
      //       ? {
      //           ...el,
      //           body: el.body.map(el1 => {
      //             if (el1.depending === act[0]) {
      //               if (action.payload === el1.exception || action.payload === "" ) {
      //                 return { ...el1, disabled: "true", value: "" };
      //               } else {
      //                 return { ...el1, disabled: "false" };
      //               }
      //             } else {
      //               return { ...el1 };
      //             }
      //           })
      //         }
      //       : el
      //   )
      // });
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (
                    (el1.act === "FR08" || el1.act === "FR09" || el1.act === "FR10") &&
                    action.payload === "married"
                  ) {
                    return { ...el1, disabled: "false", value: "" };
                  } else {
                    if (el1.act === "FR10-1" && action.payload === "married") {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      if (
                        (el1.act === "FR08" ||
                          el1.act === "FR09" ||
                          el1.act === "FR10" ||
                          el1.act === "FR10-1") &&
                        action.payload !== "married"
                      ) {
                        return { ...el1, disabled: "true", value: "" };
                      }
                      if (el1.depending === act[0]) {
                        if (action.payload === el1.exception && el1.act === "FR08-00-C") {
                          return { ...el1, disabled: "false", value: "" };
                        } else {
                          // console.log("el1=",el1, " action.payload=",action.payload)
                          if (action.payload !== el1.exception && el1.act === "FR08-00-C") {
                            // console.log(">>>>>el1=",el1, " action.payload=",action.payload)
                            return { ...el1, disabled: "true", value: "" };
                          } else {
                            if (action.payload === el1.exception || action.payload === "") {
                              return { ...el1, disabled: "true", value: "" };
                            } else {
                              return { ...el1, disabled: "false", value: "" };
                            }
                          }
                        }
                      } else {
                        return { ...el1 };
                      }
                    }
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });

    // case "ADULT_FIELD_INPUT_CHANGE" :
    //   tmp_state = Object.assign({}, state, {
    //     assessment_state: state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) => {
    //               if (action.payload==="Adolescent") {
    //                 if (el1.adolescent === "true") {
    //                   console.log("Adolescent adolescent === true",el1)
    //                   return { ...el1, disabled: "false" };
    //                 } else {
    //                   return { ...el1 };
    //                 }
    //               }
    //               else {
    //                 if (action.payload==="Adult") {
    //                   if (el1.adolescent === "true") {
    //                     console.log("Adult adolescent === true",el1)
    //                     return { ...el1, disabled: "true", value: "" };
    //                   } else {
    //                     return { ...el1 };
    //                   }
    //                 }
    //               }
    //             }),
    //           }
    //         : el
    //     ),
    //   });
    //   // console.log("ADULT_FIELD_INPUT_CHANGE tmp_state=",tmp_state);
    //   // case "ADULT_FIELD_INPUT_CHANGE" :
    //   //   tmp_state = Object.assign({}, state, {
    //   //     assessment_state: state.assessment_state.map((el) =>
    //   //       el.topic !== undefined
    //   //         ? {
    //   //             ...el,
    //   //             body: el.body.map((el1) => {
    //   //               if (action.payload==="Adolescent") {
    //   //                 if (el1.adolescent === "true") {
    //   //                   return { ...el1, disabled: "false" };
    //   //                 } else {
    //   //                   if (el1.adolescent === "false") {
    //   //                     return { ...el1, disabled: "true", value: "" };
    //   //                   } else {
    //   //                     return { ...el1 };
    //   //                   }
    //   //                 }
    //   //               }
    //   //               else {
    //   //                 if (action.payload==="Adult") {
    //   //                   if (el1.adolescent === "true") {
    //   //                     return { ...el1, disabled: "true", value: "" };
    //   //                   } else {
    //   //                     return { ...el1 };
    //   //                   }
    //   //                 }
    //   //               }
    //   //             }),
    //   //           }
    //   //         : el
    //   //     ),
    //   //   });
    //   // console.log("tmp_state.assessment_state=",tmp_state.assessment_state)
    //   return Object.assign({}, tmp_state, {
    //     newAssessment: { ...state.newAssessment, adult_type: action.payload },
    // });
    // case "M1_RADIO_INPUT_CHANGE":
    case "M8-PAIN-3_RADIO_INPUT_CHANGE":
    case "M14_RADIO_INPUT_CHANGE":
    case "ED19_RADIO_INPUT_CHANGE":
    case "ED22_RADIO_INPUT_CHANGE":
    case "DA1-4_RADIO_INPUT_CHANGE":
    case "DA1-5_RADIO_INPUT_CHANGE":
    case "DA5-5_RADIO_INPUT_CHANGE":
    case "DA6-5_RADIO_INPUT_CHANGE":
    case "DA7-5-NEW_RADIO_INPUT_CHANGE":
    case "DA7-5-NEW1_RADIO_INPUT_CHANGE":
    case "DA8-5-NEW_RADIO_INPUT_CHANGE":
    case "DA8-5_RADIO_INPUT_CHANGE":
    case "DA9-5_RADIO_INPUT_CHANGE":
    case "DA10-5_RADIO_INPUT_CHANGE":
    case "DA11-5_RADIO_INPUT_CHANGE":
    case "DA12-5_RADIO_INPUT_CHANGE":
    case "DA13-5_RADIO_INPUT_CHANGE":
    case "DA14-5_RADIO_INPUT_CHANGE":
    case "DA15-5_RADIO_INPUT_CHANGE":
    case "DA16-5_RADIO_INPUT_CHANGE":
    case "LE-NEW106_RADIO_INPUT_CHANGE":
    case "LE-NEW107_RADIO_INPUT_CHANGE":
    case "FR-NEW20_RADIO_INPUT_CHANGE":
    case "FR23_RADIO_INPUT_CHANGE":
    case "FR25-AD_RADIO_INPUT_CHANGE":
    case "FR25-AD1_RADIO_INPUT_CHANGE":
    case "FR26-AD_RADIO_INPUT_CHANGE":
    case "FR26-AD1_RADIO_INPUT_CHANGE":
    case "FR27-AD_RADIO_INPUT_CHANGE":
    case "FR27-AD1_RADIO_INPUT_CHANGE":
    case "FR28-AD_RADIO_INPUT_CHANGE":
    case "FR28-AD1_RADIO_INPUT_CHANGE":
    case "FR29-AD_RADIO_INPUT_CHANGE":
    case "FR29-AD1_RADIO_INPUT_CHANGE":
    case "FR30-AD_RADIO_INPUT_CHANGE":
    case "FR30-AD1_RADIO_INPUT_CHANGE":
    case "FR31-AD_RADIO_INPUT_CHANGE":
    case "FR31-AD1_RADIO_INPUT_CHANGE":
    case "FR32-AD_RADIO_INPUT_CHANGE":
    case "FR32-AD1_RADIO_INPUT_CHANGE":
    case "FR33-AD_RADIO_INPUT_CHANGE":
    case "FR33-AD1_RADIO_INPUT_CHANGE":
    case "FR34-AD_RADIO_INPUT_CHANGE":
    case "FR34-AD1_RADIO_INPUT_CHANGE":
    case "FR35-AD_RADIO_INPUT_CHANGE":
    case "FR35-AD1_RADIO_INPUT_CHANGE":
    case "LE-NEW11_RADIO_INPUT_CHANGE":
    case "LE-NEW15_RADIO_INPUT_CHANGE":
    case "LE-NEW19_RADIO_INPUT_CHANGE":
    case "LE-NEW23_RADIO_INPUT_CHANGE":
    case "LE-NEW27_RADIO_INPUT_CHANGE":
    case "LE-NEW31_RADIO_INPUT_CHANGE":
    case "LE-NEW35_RADIO_INPUT_CHANGE":
    case "LE-NEW39_RADIO_INPUT_CHANGE":
    case "LE-NEW43_RADIO_INPUT_CHANGE":
    case "LE-NEW47_RADIO_INPUT_CHANGE":
    case "LE-NEW51_RADIO_INPUT_CHANGE":
    case "LE-NEW55_RADIO_INPUT_CHANGE":
    case "LE-NEW59_RADIO_INPUT_CHANGE":
    case "LE-NEW63_RADIO_INPUT_CHANGE":
    case "LE-NEW67_RADIO_INPUT_CHANGE":
    case "LE-NEW71_RADIO_INPUT_CHANGE":
    case "LE-NEW75_RADIO_INPUT_CHANGE":
    case "LE-NEW80_RADIO_INPUT_CHANGE":
    case "LE-NEW84_RADIO_INPUT_CHANGE":
    case "LE-NEW88_RADIO_INPUT_CHANGE":
    case "LE-NEW93_RADIO_INPUT_CHANGE":
    case "DEMO9-ADOL1_RADIO_INPUT_CHANGE":
    case "DEMO9-ADOL2_RADIO_INPUT_CHANGE":
    case "LE-NEW1-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW1-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW2-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW2-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW3-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW3-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW4-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW4-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW5-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW5-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW6-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW6-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW7-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW7-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW8-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW8-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW9-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW9-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW10-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW10-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW11-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW11-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW12-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW12-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW13-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW13-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW14-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW14-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW15-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW15-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW16-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW16-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW17-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW17-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW18-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW18-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW19-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW19-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW20-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW20-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW21-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW21-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW22-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW22-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW23-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW23-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW24-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW24-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW25-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW25-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW26-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW26-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW27-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW27-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW28-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW28-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW29-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW29-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW30-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW30-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW31-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW31-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW32-2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW32-3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW34-ADOL1_RADIO_INPUT_CHANGE":
    case "FR13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR14-ADOL1_RADIO_INPUT_CHANGE":
    case "FR18-ADOL1_RADIO_INPUT_CHANGE":
    case "FR19-ADOL1_RADIO_INPUT_CHANGE":
    case "FR22-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR22-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR23-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR23-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR24-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR24-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR25-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR25-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR26-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR26-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR27-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR27-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR28-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR28-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR29-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR29-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR30-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR30-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR31-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR31-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR32-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR32-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR33-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR33-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR34-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR34-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR35-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR35-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR36-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR36-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR37-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR37-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR38-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR38-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR39-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR39-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR40-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR40-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR41-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR41-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR42-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR42-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR43-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR43-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR44-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR44-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR45-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR45-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR46-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR46-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR47-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR47-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR48-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR48-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR49-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR49-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR50-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR50-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR51-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR51-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR52-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR52-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR53-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR53-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR54-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR55-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR56-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR57-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR58-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR59-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR60-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR61-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR62-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-1-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-5-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-7-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-9-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-11-ADOL1_RADIO_INPUT_CHANGE":
    case "FR63-13-ADOL1_RADIO_INPUT_CHANGE":
    case "FR64-ADOL1_RADIO_INPUT_CHANGE":
    case "FR65-ADOL1_RADIO_INPUT_CHANGE":
    case "FR66-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR66-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR67-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR67-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR68-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR68-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR69-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR69-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR70-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR70-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR71-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR71-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR72-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR72-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR73-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR73-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR74-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR74-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR75-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR75-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR76-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR76-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR77-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR77-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR78-ADOL1-PAST_RADIO_INPUT_CHANGE":
    case "FR78-ADOL1-OTHER_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST1_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER1_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST2_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER2_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST3_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER3_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST4_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER4_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST5_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER5_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-PAST6_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1-OTHER6_RADIO_INPUT_CHANGE":
    case "FR80-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR80-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR81-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR81-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR82-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR82-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR83-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR83-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR84-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR84-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR85-2-ADOL1_RADIO_INPUT_CHANGE":
    case "FR85-3-ADOL1_RADIO_INPUT_CHANGE":
    case "FR86-ADOL1_RADIO_INPUT_CHANGE":
    case "FR87-ADOL1_RADIO_INPUT_CHANGE":
    case "PS01-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS01-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS02-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS02-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS03-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS03-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS04-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS04-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS05-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS05-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS06-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS06-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS07-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS07-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS08-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS08-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS09-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS09-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS10-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS10-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS11-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS11-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS12-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS12-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS13-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS13-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS14-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS14-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS15-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS15-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS16-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS16-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS17-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS17-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS18-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS18-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS19-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS19-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS20-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS20-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS21-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS21-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS22-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS22-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS23-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS23-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS24-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS24-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS25-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS25-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS26-3-ADOL1_RADIO_INPUT_CHANGE":
    case "PS26-4-ADOL1_RADIO_INPUT_CHANGE":
    case "PS27-ADOL1_RADIO_INPUT_CHANGE":
    case "PS28-ADOL1_RADIO_INPUT_CHANGE":
    case "ED15-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED15-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED16-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED16-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED17-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED17-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED18-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED18-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED19-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED19-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED20-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED20-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED21-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED21-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED22-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED22-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED23-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED23-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ED24-2-ADOL1_RADIO_INPUT_CHANGE":
    case "ED24-3-ADOL1_RADIO_INPUT_CHANGE":
    case "DA17-2-ADOL1_RADIO_INPUT_CHANGE":
    case "DA17-3-ADOL1_RADIO_INPUT_CHANGE":
    case "DA18-2-ADOL1_RADIO_INPUT_CHANGE":
    case "DA18-3-ADOL1_RADIO_INPUT_CHANGE":
    case "DA19-2-ADOL1_RADIO_INPUT_CHANGE":
    case "DA19-3-ADOL1_RADIO_INPUT_CHANGE":
    case "ES5_RADIO_INPUT_CHANGE":
    case "GI15_RADIO_INPUT_CHANGE":
    // case "MS3_RADIO_INPUT_CHANGE":
    // case "MS4_RADIO_INPUT_CHANGE":
    // case "MS5_RADIO_INPUT_CHANGE":
    case "MS10_RADIO_INPUT_CHANGE":
    case "MS11_RADIO_INPUT_CHANGE":
    // case "ES3_RADIO_INPUT_CHANGE":
    // case "ES8_RADIO_INPUT_CHANGE":
    case "ES9_RADIO_INPUT_CHANGE":
    case "ES23_RADIO_INPUT_CHANGE":
    case "ES24_RADIO_INPUT_CHANGE":
    case "DS34_RADIO_INPUT_CHANGE":
    case "DS35_RADIO_INPUT_CHANGE":
    case "LS1_RADIO_INPUT_CHANGE":
    // case "LS2_RADIO_INPUT_CHANGE":
    // case "LS24_RADIO_INPUT_CHANGE":
    case "LS31_RADIO_INPUT_CHANGE":
    case "LS32_RADIO_INPUT_CHANGE":
    // case "FS0-1-1_RADIO_INPUT_CHANGE":
    // case "FS0-1-2_RADIO_INPUT_CHANGE":
    // case "FS0-1-3_RADIO_INPUT_CHANGE":
    // case "FS0-2-1_RADIO_INPUT_CHANGE":
    // case "FS0-2-2_RADIO_INPUT_CHANGE":
    // case "FS0-2-3_RADIO_INPUT_CHANGE":
    // case "FS0-3-1_RADIO_INPUT_CHANGE":
    // case "FS0-3-2_RADIO_INPUT_CHANGE":
    // case "FS0-3-3_RADIO_INPUT_CHANGE":
    // case "FS0-4-1_RADIO_INPUT_CHANGE":
    // case "FS0-4-2_RADIO_INPUT_CHANGE":
    // case "FS0-4-3_RADIO_INPUT_CHANGE":
    // case "FS0-5-1_RADIO_INPUT_CHANGE":
    // case "FS0-5-2_RADIO_INPUT_CHANGE":
    // case "FS0-5-3_RADIO_INPUT_CHANGE":
    // case "FS0-6-1_RADIO_INPUT_CHANGE":
    // case "FS0-6-2_RADIO_INPUT_CHANGE":
    // case "FS0-6-3_RADIO_INPUT_CHANGE":
    // case "FS0-7-1_RADIO_INPUT_CHANGE":
    // case "FS0-7-2_RADIO_INPUT_CHANGE":
    // case "FS0-7-3_RADIO_INPUT_CHANGE":
    // case "FS0-8-1_RADIO_INPUT_CHANGE":
    // case "FS0-8-2_RADIO_INPUT_CHANGE":
    // case "FS0-8-3_RADIO_INPUT_CHANGE":
    // case "FS0-9-1_RADIO_INPUT_CHANGE":
    // case "FS0-9-2_RADIO_INPUT_CHANGE":
    // case "FS0-9-3_RADIO_INPUT_CHANGE":
    // case "FS0-10-1_RADIO_INPUT_CHANGE":
    // case "FS0-10-2_RADIO_INPUT_CHANGE":
    // case "FS0-10-3_RADIO_INPUT_CHANGE":
    // case "FS0-11-1_RADIO_INPUT_CHANGE":
    // case "FS0-11-2_RADIO_INPUT_CHANGE":
    // case "FS0-11-3_RADIO_INPUT_CHANGE":
    // case "FS0-12-1_RADIO_INPUT_CHANGE":
    // case "FS0-12-2_RADIO_INPUT_CHANGE":
    // case "FS0-12-3_RADIO_INPUT_CHANGE":
    case "FS3_RADIO_INPUT_CHANGE":
    case "FS6_RADIO_INPUT_CHANGE":
    case "FS7_RADIO_INPUT_CHANGE":
    case "FS8_RADIO_INPUT_CHANGE":
    case "FS9_RADIO_INPUT_CHANGE":
    case "FS10_RADIO_INPUT_CHANGE":
    case "FS12_RADIO_INPUT_CHANGE":
    case "FS13_RADIO_INPUT_CHANGE":
    case "FS14_RADIO_INPUT_CHANGE":
    case "FS15_RADIO_INPUT_CHANGE":
    case "FS16_RADIO_INPUT_CHANGE":
    case "FS17_RADIO_INPUT_CHANGE":
    case "FS18-1_RADIO_INPUT_CHANGE":
    case "FS18-2_RADIO_INPUT_CHANGE":
    case "FS18-3_RADIO_INPUT_CHANGE":
    case "FS18-4_RADIO_INPUT_CHANGE":
    case "FS18-5_RADIO_INPUT_CHANGE":
    case "FS18-7_RADIO_INPUT_CHANGE":
    case "FS18-8_RADIO_INPUT_CHANGE":
    case "FS18-9_RADIO_INPUT_CHANGE":
    case "FS18-10_RADIO_INPUT_CHANGE":
    case "FS18-11_RADIO_INPUT_CHANGE":
    case "FS18-12_RADIO_INPUT_CHANGE":
    case "FS18-13_RADIO_INPUT_CHANGE":
    case "FS18-14_RADIO_INPUT_CHANGE":
    case "FS18-16_RADIO_INPUT_CHANGE":
    case "FS18-17_RADIO_INPUT_CHANGE":
    case "FS18-18_RADIO_INPUT_CHANGE":
    case "FS19-1_RADIO_INPUT_CHANGE":
    case "FS19-2_RADIO_INPUT_CHANGE":
    case "FS19-3_RADIO_INPUT_CHANGE":
    case "FS19-4_RADIO_INPUT_CHANGE":
    case "FS19-5_RADIO_INPUT_CHANGE":
    case "FS19-6_RADIO_INPUT_CHANGE":
    case "FS37_RADIO_INPUT_CHANGE":
    case "FS38_RADIO_INPUT_CHANGE":

    case "PSS3_RADIO_INPUT_CHANGE":
    // case "PSS3PLUS1_RADIO_INPUT_CHANGE":
    // case "PSS3PLUS2_RADIO_INPUT_CHANGE":
    case "PSS4-1_RADIO_INPUT_CHANGE":
    case "PSS5-1_RADIO_INPUT_CHANGE":
    case "PSS6-1_RADIO_INPUT_CHANGE":
    case "PSS7-1_RADIO_INPUT_CHANGE":
    case "PSS8-1_RADIO_INPUT_CHANGE":
    case "PSS9-1_RADIO_INPUT_CHANGE":
    case "PSS10-1_RADIO_INPUT_CHANGE":
    case "PSS11-1_RADIO_INPUT_CHANGE":
    case "PSS4-2_RADIO_INPUT_CHANGE":
    case "PSS5-2_RADIO_INPUT_CHANGE":
    case "PSS6-2_RADIO_INPUT_CHANGE":
    case "PSS7-2_RADIO_INPUT_CHANGE":
    case "PSS8-2_RADIO_INPUT_CHANGE":
    case "PSS9-2_RADIO_INPUT_CHANGE":
    case "PSS10-2_RADIO_INPUT_CHANGE":
    case "PSS11-2_RADIO_INPUT_CHANGE":

    case "PSS15_RADIO_INPUT_CHANGE":
    case "PSS16_RADIO_INPUT_CHANGE":
    case "PSS17_RADIO_INPUT_CHANGE":
    case "PSS18_RADIO_INPUT_CHANGE":
    case "PSS19_RADIO_INPUT_CHANGE":
    case "PSS20_RADIO_INPUT_CHANGE":
    case "PSS22_RADIO_INPUT_CHANGE":
    case "PSS23_RADIO_INPUT_CHANGE":
    // case "GI10_RADIO_INPUT_CHANGE":
    // case "FR07-NEW-ADOL1_RADIO_INPUT_CHANGE":
    case "PL1-ALCOHOL-USE-DISORDER-1_RADIO_INPUT_CHANGE":
    case "PL2-DRUG-USE-DISORDER-1_RADIO_INPUT_CHANGE":
    case "PL3-ABUSE-NEGLECT-1_RADIO_INPUT_CHANGE":
    case "PL4-ANGER-1_RADIO_INPUT_CHANGE":
    case "PL5-ANXIETY-1_RADIO_INPUT_CHANGE":
    case "PL6-DECISION-MAKING-1_RADIO_INPUT_CHANGE":
    case "PL7-BEHAVIOR-PROBLEMS-1_RADIO_INPUT_CHANGE":
    case "PL8-COMMUNICATION-SKILLS-1_RADIO_INPUT_CHANGE":
    case "PL9-CRIME-CRIMINAL-THINKING-1_RADIO_INPUT_CHANGE":
    case "PL10-DEPRESSION-1_RADIO_INPUT_CHANGE":
    case "PL11-EXPRESSION-OF-FEELINGS-1_RADIO_INPUT_CHANGE":
    case "PL12-FAMILY-CONFLICT-1_RADIO_INPUT_CHANGE":
    case "PL13-GRIEF-AND-LOSS-1_RADIO_INPUT_CHANGE":
    case "PL14-HARM-TO-SELF-OR-OTHERS-1_RADIO_INPUT_CHANGE":
    case "PL15-HEALTH-ISSUES-1_RADIO_INPUT_CHANGE":
    case "PL16-PARENTING-1_RADIO_INPUT_CHANGE":
    case "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-1_RADIO_INPUT_CHANGE":
    case "PL18-RELATIONSHIPS-1_RADIO_INPUT_CHANGE":
    case "PL19-SELF-IMAGE-1_RADIO_INPUT_CHANGE":
    case "PL20-SLEEP-PROBLEMS-1_RADIO_INPUT_CHANGE":
    case "PL21-SOCIAL-SKILLS-1_RADIO_INPUT_CHANGE":
    case "PL22-STRESS-1_RADIO_INPUT_CHANGE":
    case "PL23-SUICIDE-1_RADIO_INPUT_CHANGE":
    case "PL24-TRAUMA-1_RADIO_INPUT_CHANGE":
      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "PP2_RADIO_INPUT_CHANGE":
    case "PP4-0_RADIO_INPUT_CHANGE":
    // case "PP6_RADIO_INPUT_CHANGE":
    case "M0_RADIO_INPUT_CHANGE":
    case "M-NEW2_RADIO_INPUT_CHANGE":
    case "M-NEW4_RADIO_INPUT_CHANGE":
    case "M-NEW4-ADD_RADIO_INPUT_CHANGE":
    case "M-NEW5_RADIO_INPUT_CHANGE":
    case "M5_RADIO_INPUT_CHANGE":
    case "M5-ADD_RADIO_INPUT_CHANGE":
    case "M17_RADIO_INPUT_CHANGE":
    case "M7_RADIO_INPUT_CHANGE":
    case "M7-ADD_RADIO_INPUT_CHANGE":
    case "M8-PAIN_RADIO_INPUT_CHANGE":
    case "M10_RADIO_INPUT_CHANGE":
    case "DEMO7-ADD_RADIO_INPUT_CHANGE":
    case "DEMO9_FIELD_INPUT_CHANGE":
    case "DEMO11_RADIO_INPUT_CHANGE":
    case "DEMO11-NEW1_RADIO_INPUT_CHANGE":
    case "DEMO11-NEW2_RADIO_INPUT_CHANGE":
    case "ED-NEW3_RADIO_INPUT_CHANGE":
    case "ED5_RADIO_INPUT_CHANGE":
    case "ED5-ADD_RADIO_INPUT_CHANGE":
    case "ED5-ADD1_RADIO_INPUT_CHANGE":
    case "ED16A_RADIO_INPUT_CHANGE":
    case "ED16B_RADIO_INPUT_CHANGE":
    case "ED16C_RADIO_INPUT_CHANGE":
    case "ED16D_RADIO_INPUT_CHANGE":
    case "ED16E_RADIO_INPUT_CHANGE":
    case "ED-NEW32_RADIO_INPUT_CHANGE":
    case "ED17_RADIO_INPUT_CHANGE":
    case "ED21_RADIO_INPUT_CHANGE":
    case "ED28_RADIO_INPUT_CHANGE":
    case "ED30_RADIO_INPUT_CHANGE":
    case "DA2_RADIO_INPUT_CHANGE":
    case "DA16-NEW113_RADIO_INPUT_CHANGE":
    case "DA16-NEW113-ADD_RADIO_INPUT_CHANGE":
    case "DA16-NEW115_RADIO_INPUT_CHANGE":
    case "DA16-NEW117ADD_RADIO_INPUT_CHANGE":
    case "FR01-ADD_RADIO_INPUT_CHANGE":
    case "FR02-ADD_RADIO_INPUT_CHANGE":
    case "FR10_RADIO_INPUT_CHANGE":
    case "FR03-ADD1_RADIO_INPUT_CHANGE":
    case "FR04-ADD1_RADIO_INPUT_CHANGE":
    case "FR05-ADD1_RADIO_INPUT_CHANGE":
    case "FR05-NEW2-COUPLE_RADIO_INPUT_CHANGE":
    case "FR14_RADIO_INPUT_CHANGE":
    case "FR14A_RADIO_INPUT_CHANGE":
    case "FR20_RADIO_INPUT_CHANGE":
    case "FR20-2_RADIO_INPUT_CHANGE":
    case "FR21_RADIO_INPUT_CHANGE":
    case "FR21-2_RADIO_INPUT_CHANGE":
    case "FR22_RADIO_INPUT_CHANGE":
    case "FR22-2_RADIO_INPUT_CHANGE":
    case "FR18A_RADIO_INPUT_CHANGE":
    case "FR18B_RADIO_INPUT_CHANGE":
    case "FR18C_RADIO_INPUT_CHANGE":
    case "FR18D_RADIO_INPUT_CHANGE":
    case "FR18E_RADIO_INPUT_CHANGE":
    case "FR18F_RADIO_INPUT_CHANGE":
    case "FR18A-1_RADIO_INPUT_CHANGE":
    case "FR18B-1_RADIO_INPUT_CHANGE":
    case "FR18C-1_RADIO_INPUT_CHANGE":
    case "FR18D-1_RADIO_INPUT_CHANGE":
    case "FR18E-1_RADIO_INPUT_CHANGE":
    case "FR18F-1_RADIO_INPUT_CHANGE":
    case "FR19A_RADIO_INPUT_CHANGE":
    case "FR19B_RADIO_INPUT_CHANGE":
    case "FR19C_RADIO_INPUT_CHANGE":
    case "FR19D_RADIO_INPUT_CHANGE":
    case "FR19E_RADIO_INPUT_CHANGE":
    case "FR19F_RADIO_INPUT_CHANGE":
    case "FR19G_RADIO_INPUT_CHANGE":
    case "FR19H_RADIO_INPUT_CHANGE":
    case "FR19J_RADIO_INPUT_CHANGE":
    case "FR19A-1_RADIO_INPUT_CHANGE":
    case "FR19B-1_RADIO_INPUT_CHANGE":
    case "FR19C-1_RADIO_INPUT_CHANGE":
    case "FR19D-1_RADIO_INPUT_CHANGE":
    case "FR19E-1_RADIO_INPUT_CHANGE":
    case "FR19F-1_RADIO_INPUT_CHANGE":
    case "FR19G-1_RADIO_INPUT_CHANGE":
    case "FR19H-1_RADIO_INPUT_CHANGE":
    case "FR19J-1_RADIO_INPUT_CHANGE":
    case "FR05-TABLE_RADIO_INPUT_CHANGE":
    case "FR06-TABLE_RADIO_INPUT_CHANGE":
    case "PS3_RADIO_INPUT_CHANGE":
    case "PS15_RADIO_INPUT_CHANGE":
    case "PS13_RADIO_INPUT_CHANGE":
    case "PS5_RADIO_INPUT_CHANGE":
    case "PS4_RADIO_INPUT_CHANGE":
    case "PS11_RADIO_INPUT_CHANGE":
    case "PS10_RADIO_INPUT_CHANGE":
    case "PS6_RADIO_INPUT_CHANGE":
    case "PS9_RADIO_INPUT_CHANGE":
    case "PS8_RADIO_INPUT_CHANGE":
    case "PS12_RADIO_INPUT_CHANGE":
    case "PS7_RADIO_INPUT_CHANGE":
    case "PS5-1_RADIO_INPUT_CHANGE":
    case "PS4-1_RADIO_INPUT_CHANGE":
    case "PS11-1_RADIO_INPUT_CHANGE":
    case "PS10-1_RADIO_INPUT_CHANGE":
    case "PS6-1_RADIO_INPUT_CHANGE":
    case "PS9-1_RADIO_INPUT_CHANGE":
    case "PS8-1_RADIO_INPUT_CHANGE":
    case "PS12-1_RADIO_INPUT_CHANGE":
    case "PS7-1_RADIO_INPUT_CHANGE":
    case "LE-NEW1_RADIO_INPUT_CHANGE":
    case "LE-NEW4_RADIO_INPUT_CHANGE":
    case "LE3-ADD_RADIO_INPUT_CHANGE":
    case "LE-NEW8_RADIO_INPUT_CHANGE":
    case "LE-NEW12_RADIO_INPUT_CHANGE":
    case "LE-NEW16_RADIO_INPUT_CHANGE":
    case "LE-NEW20_RADIO_INPUT_CHANGE":
    case "LE-NEW24_RADIO_INPUT_CHANGE":
    case "LE-NEW28_RADIO_INPUT_CHANGE":
    case "LE-NEW32_RADIO_INPUT_CHANGE":
    case "LE-NEW36_RADIO_INPUT_CHANGE":
    case "LE-NEW40_RADIO_INPUT_CHANGE":
    case "LE-NEW44_RADIO_INPUT_CHANGE":
    case "LE-NEW48_RADIO_INPUT_CHANGE":
    case "LE-NEW52_RADIO_INPUT_CHANGE":
    case "LE-NEW56_RADIO_INPUT_CHANGE":
    case "LE-NEW60_RADIO_INPUT_CHANGE":
    case "LE-NEW64_RADIO_INPUT_CHANGE":
    case "LE-NEW68_RADIO_INPUT_CHANGE":
    case "LE-NEW72_RADIO_INPUT_CHANGE":
    case "LE-NEW76_RADIO_INPUT_CHANGE":
    case "LE-NEW81_RADIO_INPUT_CHANGE":
    case "LE-NEW85_RADIO_INPUT_CHANGE":
    case "LE-NEW89_RADIO_INPUT_CHANGE":
    case "LE-NEW94-0_RADIO_INPUT_CHANGE":
    case "LE-NEW97_RADIO_INPUT_CHANGE":
    case "LE-NEW101_RADIO_INPUT_CHANGE":
    case "LE-NEW105_RADIO_INPUT_CHANGE":
    case "ED6-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW1-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW2-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW3-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW4-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW5-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW6-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW7-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW8-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW9-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW10-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW11-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW12-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW13-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW14-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW15-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW16-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW17-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW18-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW19-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW20-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW21-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW22-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW23-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW24-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW25-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW26-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW27-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW28-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW29-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW30-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW31-ADOL1_RADIO_INPUT_CHANGE":
    case "LE-NEW32-ADOL1_RADIO_INPUT_CHANGE":
    case "FR09-ADOL1_RADIO_INPUT_CHANGE":
    case "FR22-ADOL1_RADIO_INPUT_CHANGE":
    case "FR23-ADOL1_RADIO_INPUT_CHANGE":
    case "FR24-ADOL1_RADIO_INPUT_CHANGE":
    case "FR25-ADOL1_RADIO_INPUT_CHANGE":
    case "FR26-ADOL1_RADIO_INPUT_CHANGE":
    case "FR27-ADOL1_RADIO_INPUT_CHANGE":
    case "FR28-ADOL1_RADIO_INPUT_CHANGE":
    case "FR29-ADOL1_RADIO_INPUT_CHANGE":
    case "FR30-ADOL1_RADIO_INPUT_CHANGE":
    case "FR31-ADOL1_RADIO_INPUT_CHANGE":
    case "FR32-ADOL1_RADIO_INPUT_CHANGE":
    case "FR33-ADOL1_RADIO_INPUT_CHANGE":
    case "FR34-ADOL1_RADIO_INPUT_CHANGE":
    case "FR35-ADOL1_RADIO_INPUT_CHANGE":
    case "FR36-ADOL1_RADIO_INPUT_CHANGE":
    case "FR37-ADOL1_RADIO_INPUT_CHANGE":
    case "FR38-ADOL1_RADIO_INPUT_CHANGE":
    case "FR39-ADOL1_RADIO_INPUT_CHANGE":
    case "FR40-ADOL1_RADIO_INPUT_CHANGE":
    case "FR41-ADOL1_RADIO_INPUT_CHANGE":
    case "FR42-ADOL1_RADIO_INPUT_CHANGE":
    case "FR43-ADOL1_RADIO_INPUT_CHANGE":
    case "FR44-ADOL1_RADIO_INPUT_CHANGE":
    case "FR45-ADOL1_RADIO_INPUT_CHANGE":
    case "FR46-ADOL1_RADIO_INPUT_CHANGE":
    case "FR47-ADOL1_RADIO_INPUT_CHANGE":
    case "FR48-ADOL1_RADIO_INPUT_CHANGE":
    case "FR49-ADOL1_RADIO_INPUT_CHANGE":
    case "FR50-ADOL1_RADIO_INPUT_CHANGE":
    case "FR51-ADOL1_RADIO_INPUT_CHANGE":
    case "FR52-ADOL1_RADIO_INPUT_CHANGE":
    case "FR53-ADOL1_RADIO_INPUT_CHANGE":
    case "FR66-ADOL1_RADIO_INPUT_CHANGE":
    case "FR67-ADOL1_RADIO_INPUT_CHANGE":
    case "FR68-ADOL1_RADIO_INPUT_CHANGE":
    case "FR69-ADOL1_RADIO_INPUT_CHANGE":
    case "FR70-ADOL1_RADIO_INPUT_CHANGE":
    case "FR71-ADOL1_RADIO_INPUT_CHANGE":
    case "FR72-ADOL1_RADIO_INPUT_CHANGE":
    case "FR73-ADOL1_RADIO_INPUT_CHANGE":
    case "FR74-ADOL1_RADIO_INPUT_CHANGE":
    case "FR75-ADOL1_RADIO_INPUT_CHANGE":
    case "FR76-ADOL1_RADIO_INPUT_CHANGE":
    case "FR77-ADOL1_RADIO_INPUT_CHANGE":
    case "FR80-ADOL1_RADIO_INPUT_CHANGE":
    case "FR81-ADOL1_RADIO_INPUT_CHANGE":
    case "FR82-ADOL1_RADIO_INPUT_CHANGE":
    case "FR83-ADOL1_RADIO_INPUT_CHANGE":
    case "FR84-ADOL1_RADIO_INPUT_CHANGE":
    case "FR85-ADOL1_RADIO_INPUT_CHANGE":
    case "PS01-ADOL1_RADIO_INPUT_CHANGE":
    case "PS02-ADOL1_RADIO_INPUT_CHANGE":
    case "PS03-ADOL1_RADIO_INPUT_CHANGE":
    case "PS04-ADOL1_RADIO_INPUT_CHANGE":
    case "PS05-ADOL1_RADIO_INPUT_CHANGE":
    case "PS06-ADOL1_RADIO_INPUT_CHANGE":
    case "PS07-ADOL1_RADIO_INPUT_CHANGE":
    case "PS08-ADOL1_RADIO_INPUT_CHANGE":
    case "PS09-ADOL1_RADIO_INPUT_CHANGE":
    case "PS10-ADOL1_RADIO_INPUT_CHANGE":
    case "PS11-ADOL1_RADIO_INPUT_CHANGE":
    case "PS12-ADOL1_RADIO_INPUT_CHANGE":
    case "PS13-ADOL1_RADIO_INPUT_CHANGE":
    case "PS14-ADOL1_RADIO_INPUT_CHANGE":
    case "PS15-ADOL1_RADIO_INPUT_CHANGE":
    case "PS16-ADOL1_RADIO_INPUT_CHANGE":
    case "PS17-ADOL1_RADIO_INPUT_CHANGE":
    case "PS18-ADOL1_RADIO_INPUT_CHANGE":
    case "PS19-ADOL1_RADIO_INPUT_CHANGE":
    case "PS20-ADOL1_RADIO_INPUT_CHANGE":
    case "PS21-ADOL1_RADIO_INPUT_CHANGE":
    case "PS22-ADOL1_RADIO_INPUT_CHANGE":
    case "PS23-ADOL1_RADIO_INPUT_CHANGE":
    case "PS24-ADOL1_RADIO_INPUT_CHANGE":
    case "PS25-ADOL1_RADIO_INPUT_CHANGE":
    case "PS26-ADOL1_RADIO_INPUT_CHANGE":
    case "M12-0-ADOL1_RADIO_INPUT_CHANGE":
    case "M12-0-ADOL2_RADIO_INPUT_CHANGE":
    case "M12-0-ADOL4_RADIO_INPUT_CHANGE":
    case "M13-0_RADIO_INPUT_CHANGE":
    case "ED15-ADOL1_RADIO_INPUT_CHANGE":
    case "ED16-ADOL1_RADIO_INPUT_CHANGE":
    case "ED17-ADOL1_RADIO_INPUT_CHANGE":
    case "ED18-ADOL1_RADIO_INPUT_CHANGE":
    case "ED19-ADOL1_RADIO_INPUT_CHANGE":
    case "ED20-ADOL1_RADIO_INPUT_CHANGE":
    case "ED21-ADOL1_RADIO_INPUT_CHANGE":
    case "ED22-ADOL1_RADIO_INPUT_CHANGE":
    case "ED23-ADOL1_RADIO_INPUT_CHANGE":
    case "ED24-ADOL1_RADIO_INPUT_CHANGE":
    case "DA17-ADOL1_RADIO_INPUT_CHANGE":
    case "DA18-ADOL1_RADIO_INPUT_CHANGE":
    case "DA19-ADOL1_RADIO_INPUT_CHANGE":
    case "DEMO9-ADOL3_RADIO_INPUT_CHANGE":
    case "DA16-ADOL6_RADIO_INPUT_CHANGE":
    case "DA16-ADOL8_RADIO_INPUT_CHANGE":
    case "DA16-ADOL9_RADIO_INPUT_CHANGE":
    case "DA16-ADOL10_RADIO_INPUT_CHANGE":
    case "DA16-ADOL12_RADIO_INPUT_CHANGE":
    case "DA16-ADOL13_RADIO_INPUT_CHANGE":
    case "DA16-ADOL14_RADIO_INPUT_CHANGE":
    case "DA16-ADOL16_RADIO_INPUT_CHANGE":
    case "DA16-ADOL17_RADIO_INPUT_CHANGE":
    case "DA16-ADOL18_RADIO_INPUT_CHANGE":
    case "DA16-ADOL20_RADIO_INPUT_CHANGE":
    case "DA16-ADOL21_RADIO_INPUT_CHANGE":
    case "DA16-ADOL22_RADIO_INPUT_CHANGE":
    case "DA16-ADOL24_RADIO_INPUT_CHANGE":
    case "DA16-ADOL25_RADIO_INPUT_CHANGE":
    case "DA16-ADOL26_RADIO_INPUT_CHANGE":
    case "DA16-ADOL28_RADIO_INPUT_CHANGE":
    case "DA16-ADOL29_RADIO_INPUT_CHANGE":
    case "DA16-ADOL30_RADIO_INPUT_CHANGE":
    case "DA16-ADOL32_RADIO_INPUT_CHANGE":
    case "DA16-ADOL33_RADIO_INPUT_CHANGE":
    case "DA16-ADOL34_RADIO_INPUT_CHANGE":
    case "DA16-ADOL36_RADIO_INPUT_CHANGE":
    case "DA16-ADOL37_RADIO_INPUT_CHANGE":
    case "DA16-ADOL38_RADIO_INPUT_CHANGE":
    case "DA16-ADOL40_RADIO_INPUT_CHANGE":
    case "DA16-ADOL41_RADIO_INPUT_CHANGE":
    case "DA16-ADOL42_RADIO_INPUT_CHANGE":
    case "DA16-ADOL44_RADIO_INPUT_CHANGE":
    case "DA16-ADOL45_RADIO_INPUT_CHANGE":
    case "DA16-ADOL46_RADIO_INPUT_CHANGE":
    case "DA16-ADOL48_RADIO_INPUT_CHANGE":
    case "DA16-ADOL49_RADIO_INPUT_CHANGE":
    case "FR08-ADOL1-RADIO_RADIO_INPUT_CHANGE":
    case "LE-NEW33-ADOL1_RADIO_INPUT_CHANGE":
    case "ES3_RADIO_INPUT_CHANGE":
    case "FS18-6_RADIO_INPUT_CHANGE":
    case "FS18-15_RADIO_INPUT_CHANGE":
    case "ES8_RADIO_INPUT_CHANGE":
    case "MS3_RADIO_INPUT_CHANGE":
    case "MS4_RADIO_INPUT_CHANGE":
    case "MS5_RADIO_INPUT_CHANGE":
    case "LS2_RADIO_INPUT_CHANGE":
    case "LS24_RADIO_INPUT_CHANGE":

    case "FS0-1-1_RADIO_INPUT_CHANGE":
    case "FS0-1-2_RADIO_INPUT_CHANGE":
    case "FS0-1-3_RADIO_INPUT_CHANGE":
    case "FS0-2-1_RADIO_INPUT_CHANGE":
    case "FS0-2-2_RADIO_INPUT_CHANGE":
    case "FS0-2-3_RADIO_INPUT_CHANGE":
    case "FS0-3-1_RADIO_INPUT_CHANGE":
    case "FS0-3-2_RADIO_INPUT_CHANGE":
    case "FS0-3-3_RADIO_INPUT_CHANGE":
    case "FS0-4-1_RADIO_INPUT_CHANGE":
    case "FS0-4-2_RADIO_INPUT_CHANGE":
    case "FS0-4-3_RADIO_INPUT_CHANGE":
    case "FS0-5-1_RADIO_INPUT_CHANGE":
    case "FS0-5-2_RADIO_INPUT_CHANGE":
    case "FS0-5-3_RADIO_INPUT_CHANGE":
    case "FS0-6-1_RADIO_INPUT_CHANGE":
    case "FS0-6-2_RADIO_INPUT_CHANGE":
    case "FS0-6-3_RADIO_INPUT_CHANGE":
    case "FS0-7-1_RADIO_INPUT_CHANGE":
    case "FS0-7-2_RADIO_INPUT_CHANGE":
    case "FS0-7-3_RADIO_INPUT_CHANGE":
    case "FS0-8-1_RADIO_INPUT_CHANGE":
    case "FS0-8-2_RADIO_INPUT_CHANGE":
    case "FS0-8-3_RADIO_INPUT_CHANGE":
    case "FS0-9-1_RADIO_INPUT_CHANGE":
    case "FS0-9-2_RADIO_INPUT_CHANGE":
    case "FS0-9-3_RADIO_INPUT_CHANGE":
    case "FS0-10-1_RADIO_INPUT_CHANGE":
    case "FS0-10-2_RADIO_INPUT_CHANGE":
    case "FS0-10-3_RADIO_INPUT_CHANGE":
    case "FS0-11-1_RADIO_INPUT_CHANGE":
    case "FS0-11-2_RADIO_INPUT_CHANGE":
    case "FS0-11-3_RADIO_INPUT_CHANGE":
    case "FS0-12-1_RADIO_INPUT_CHANGE":
    case "FS0-12-2_RADIO_INPUT_CHANGE":
    case "FS0-12-3_RADIO_INPUT_CHANGE":
      // case "DA1-ADOL1-0_RADIO_INPUT_CHANGE":
      //disable все остальное кром exception
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // console.log("el1=",el1,"act[0]=",act[0])
                    if (
                      /*action.payload !== el1.exception*/ !ArrInArr(
                        [action.payload],
                        el1.exception
                      )
                    ) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp_state=",tmp_state);
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });

    //========================================================================================
    /*                                                                                      *
     *                                       Adolscent                                      *
     *                                                                                      */
    //========================================================================================

    // case "DEMO9-ADOL1_RADIO_INPUT_CHANGE":
    // case "DEMO9-ADOL2_RADIO_INPUT_CHANGE":
    // case "DEMO9-ADOL3_RADIO_INPUT_CHANGE":
    //   tmp_state = Object.assign({}, state, {
    //     assessment_state: state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) => {
    //               if (el1.adolescent === "true") {
    //                 if (action.payload !== el1.exception) {
    //                   return { ...el1, disabled: "true", value: "" };
    //                 } else {
    //                   return { ...el1, disabled: "false" };
    //                 }
    //               } else {
    //                 return { ...el1 };
    //               }
    //             }),
    //           }
    //         : el
    //     ),
    //   });
    //   return Object.assign({}, tmp_state, {
    //     wasClick: state.wasClick + 1,
    //     assessment_state_changed: true,
    //     assessment_state: tmp_state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) =>
    //               el1.act === act[0] ? { ...el1, value: action.payload } : el1
    //             ),
    //           }
    //         : el
    //     ),
    //   });

    case "ED1_FIELD_INPUT_CHANGE":
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload !== el1.exception) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending1 === act[0]) {
                    if (action.payload === el1.exception1 || action.payload === "") {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state1, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "DEMO4_RADIO_INPUT_CHANGE": //Gender
      // Gender без сохранения
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload !== el1.exception && action.payload !== "Other") {
                      // console.log("disabled true=",el1)
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      // console.log("disabled false=",el1)
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "DEMO4-NEW0_FIELD_INPUT_CHANGE":
      // if (
      // 	(el1.act === "FR08" || el1.act === "FR09" || el1.act === "FR10") &&
      // 	action.payload === "married"
      // )

      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (
                    el1.act === "M-NEW15" ||
                    el1.act === "M-NEW16" ||
                    el1.act === "M14" ||
                    el1.act === "M-NEW18"
                  ) {
                    if (action.payload === "Female") return { ...el1, disabled: "false" };
                    else return { ...el1, disabled: "true", value: "" };
                  } else {
                    if (el1.act === "M-NEW19") {
                      if (action.payload === "Male") return { ...el1, disabled: "false" };
                      else return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1 };
                    }
                  }

                  // if (el1.act === "M-NEW19"  && action.payload === "Male"){
                  // 	return { ...el1, disabled: "false" };
                  // } else {
                  // 	if (el1.depending === act[0]) {
                  // 		if (action.payload !== el1.exception) {
                  // 			return { ...el1, disabled: "true", value: "" };
                  // 		} else {
                  // 			return { ...el1, disabled: "false" };
                  // 		}
                  // 	} else {
                  // 		return { ...el1 };
                  // 	}

                  // return { ...el1, disabled: "true", value: "" };
                  // }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    //позволять писать если то что в exception
    case "DEMO3_FIELD_INPUT_CHANGE":
    case "ED7_FIELD_INPUT_CHANGE":
    case "ED10_FIELD_INPUT_CHANGE":
    case "DEMO11-ADOL2_FIELD_INPUT_CHANGE":
    case "DA1-ADOL4_FIELD_INPUT_CHANGE":
    case "DA5-ADOL4_FIELD_INPUT_CHANGE":
    case "DA6-ADOL4_FIELD_INPUT_CHANGE":
    case "DA7-NEW-ADOL4_FIELD_INPUT_CHANGE":
    case "DA7-NEW1-ADOL4_FIELD_INPUT_CHANGE":
    case "DA8-NEW-ADOL4_FIELD_INPUT_CHANGE":
    case "DA8-ADOL4_FIELD_INPUT_CHANGE":
    case "DA9-ADOL4_FIELD_INPUT_CHANGE":
    case "DA10-ADOL4_FIELD_INPUT_CHANGE":
    case "DA11-ADOL4_FIELD_INPUT_CHANGE":
    case "DA12-ADOL4_FIELD_INPUT_CHANGE":
    case "DA13-ADOL4_FIELD_INPUT_CHANGE":
    case "DA14-ADOL4_FIELD_INPUT_CHANGE":
    case "DA15-ADOL4_FIELD_INPUT_CHANGE":
    case "DA16-ADOL4_FIELD_INPUT_CHANGE":
    case "DA1-1-ADOL1_FIELD_INPUT_CHANGE": //What was the first substances
    case "DA1-1-ADOL2_FIELD_INPUT_CHANGE": //With whom first
    case "DA1-1-ADOL3_FIELD_INPUT_CHANGE": //what was the second?
    case "DA1-1-ADOL4_FIELD_INPUT_CHANGE": //With whom second
    case "DA16-NEW117ADD-ADOL1_FIELD_INPUT_CHANGE": //shot-up
    case "PP8_FIELD_INPUT_CHANGE":
    case "PP4-ADOL1_FIELD_INPUT_CHANGE":
    case "ED1-ADOL2_FIELD_INPUT_CHANGE":
    case "ED27-ADOL1_FIELD_INPUT_CHANGE":
    case "ED1-ADOL3-1_FIELD_INPUT_CHANGE":
    case "FR78-ADOL1-SELECT_FIELD_INPUT_CHANGE":
    case "FR79-ADOL1-SELECT_FIELD_INPUT_CHANGE":
    case "ED1-ADOL1_FIELD_INPUT_CHANGE":
    case "PP7-1_FIELD_INPUT_CHANGE":
    case "GI19_FIELD_INPUT_CHANGE":
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // console.log("el1.id=", el1.id, "action.payload=",action.payload,"ArrInArr([action.payload], el1.exception)=",ArrInArr([action.payload], el1.exception))
                    // if (action.payload.indexOf(el1.exception) < 0) {
                    if (!ArrInArr([action.payload], el1.exception)) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      // console.log("el1.act=",el1.act)
                      if (el1.act === "ED1-ADOL1-1")
                        return { ...el1, disabled: "false", value: "due to " };
                      else return { ...el1, disabled: "false", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp_state=",tmp_state,"action.payload=",action.payload)
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    //SELECT disable если то что в exception или пробел
    case "DEMO2_RADIO_INPUT_CHANGE":
    case "M2_FIELD_INPUT_CHANGE":
    case "M19_FIELD_INPUT_CHANGE":
    case "ED32_FIELD_INPUT_CHANGE":
    case "ED33_FIELD_INPUT_CHANGE":
    case "FR15_FIELD_INPUT_CHANGE":
    case "PS1_FIELD_INPUT_CHANGE":
    case "PS2_FIELD_INPUT_CHANGE":
    case "PS16_FIELD_INPUT_CHANGE":
    case "DA16-NEW127_FIELD_INPUT_CHANGE":
    case "DA16-NEW130_FIELD_INPUT_CHANGE":
    case "LE-NEW36-ADOL1_FIELD_INPUT_CHANGE":
    case "LE-NEW100_FIELD_INPUT_CHANGE":
    case "LE-NEW103_FIELD_INPUT_CHANGE":
    case "FR39_FIELD_INPUT_CHANGE":
    case "FR04_FIELD_INPUT_CHANGE":
    case "AC01_FIELD_INPUT_CHANGE":
    case "AC02_FIELD_INPUT_CHANGE":
    case "AC03_FIELD_INPUT_CHANGE":
    case "AC04_FIELD_INPUT_CHANGE":
    case "AC05_FIELD_INPUT_CHANGE":
    case "AC06_FIELD_INPUT_CHANGE":
    case "AC01-V4_FIELD_INPUT_CHANGE":
    case "AC02-V4_FIELD_INPUT_CHANGE":
    case "AC03-V4_FIELD_INPUT_CHANGE":
    case "AC04-V4_FIELD_INPUT_CHANGE":
    case "AC05-V4_FIELD_INPUT_CHANGE":
    case "AC06-V4_FIELD_INPUT_CHANGE":
    case "FR88-ADOL1_FIELD_INPUT_CHANGE":
    case "ED32-ADOL1_FIELD_INPUT_CHANGE":
    case "ED33-ADOL1_FIELD_INPUT_CHANGE":
    case "M-NEW16_FIELD_INPUT_CHANGE":
    // case "GI19_FIELD_INPUT_CHANGE":
    case "PSS20-1_FIELD_INPUT_CHANGE":
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  // console.log("act[0]=", act[0],"el1.depending=",el1.depending,"el1=",el1);
                  if (el1.depending === act[0]) {
                    // console.log("act[0]=", act[0],"el1=",el1);
                    if (action.payload === el1.exception || action.payload === "") {
                      // console.log("action.payload=",action.payload,"el1.depending=",el1.depending,"el1.exception=",el1.exception)
                      if (el1.act === "ED-NEW52")
                        return { ...el1, disabled: "true", value: "due to " };
                      if (el1.act === "ED-NEW54")
                        return { ...el1, disabled: "true", value: "due to " };
                      if (el1.act === "ED1-ADOL1-1")
                        return { ...el1, disabled: "true", value: "due to " };
                      else return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    //disable if space never or less then digit
    // case "FR-NEW10_FIELD_INPUT_CHANGE":
    case "FR-NEW11_FIELD_INPUT_CHANGE":
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload === "never" || action.payload === "") {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      if (Number(action.payload) <= Number(el1.exception))
                        return { ...el1, disabled: "true", value: "" };
                      else return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    // case "ES2_FIELD_INPUT_CHANGE":
    case "FR11_FIELD_INPUT_CHANGE":
    case "LE-NEW94_FIELD_INPUT_CHANGE":
    case "DA16-NEW123_FIELD_INPUT_CHANGE":
    case "DS15_FIELD_INPUT_CHANGE":
    case "DS19_FIELD_INPUT_CHANGE":
    case "DS20_FIELD_INPUT_CHANGE":
    case "DS21_FIELD_INPUT_CHANGE":
    case "DS20_FIELD_INPUT_CHANGE":
    case "DS22_FIELD_INPUT_CHANGE":
    case "LS21_FIELD_INPUT_CHANGE":
    case "MS1_FIELD_INPUT_CHANGE":
      //disable если 0 или отрицательный
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (Number(action.payload) <= Number(el1.exception) || action.payload === "") {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "DS15_FIELD_INPUT_CHANGE_CONVERT_TO_ASI":
      //disable если 0 или отрицательный
      // console.log("DS15=",action.payload);
      let sum_l15 = 0;
      for (var i = 0; i < state.assessment_state.length; i++) {
        for (var j = 0; j < state.assessment_state[i].body.length; j++) {
          if (state.assessment_state[i].body[j].shortname === "ds15") {
            sum_l15 = Number(state.assessment_state[i].body[j].value);
          }
        }
      }
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // console.log("el1=",el1,"");
                    if (
                      sum_l15 + Number(action.payload) <= Number(el1.exception) ||
                      action.payload === ""
                    ) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0]
                    ? {
                        ...el1,
                        value:
                          Number(el1.value) === 0
                            ? Number(action.payload)
                            : Number(el1.value) + Number(action.payload),
                      }
                    : el1
                ),
              }
            : el
        ),
      });
    case "FR08-01_RADIO_INPUT_CHANGE":
    case "FR05-NEW1-ADD_RADIO_INPUT_CHANGE":
    case "FR03-ADD0_RADIO_INPUT_CHANGE":
    case "ED23_RADIO_INPUT_CHANGE":
    case "ED-NEW22_RADIO_INPUT_CHANGE":
    case "M9_RADIO_INPUT_CHANGE":
    // case "FR03_RADIO_INPUT_CHANGE":
    case "ED1-ADOL3-0_RADIO_INPUT_CHANGE":
    case "DA16-NEW121_RADIO_INPUT_CHANGE":
    case "FR10-0-ADOL1_RADIO_INPUT_CHANGE":
    case "FR07-NEW-ADOL1_RADIO_INPUT_CHANGE":
    case "PP6_RADIO_INPUT_CHANGE":
      arr_ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // if (el1.depending === act[0] /*&& el1.adolescent!="true"*/ && state.newAssessment.adult_type==="Adult" ||
                    //     el1.depending === act[0] /*&& el1.adolescent!="false"*/ && state.newAssessment.adult_type==="Adolescent") {
                    if (action.payload !== el1.exception) {
                      arr_.push(el1.act);
                      return {
                        ...el1,
                        disabled: "true",
                        value: returnClearValue(el1),
                      };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      MySet = new Set(arr_);
      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet.has(el1.depending)) {
                    return {
                      ...el1,
                      disabled: "true",
                      value: returnClearValue(el1),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state1, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "M15_FIELD_INPUT_CHANGE":
      arr_ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload === el1.exception || action.payload === "") {
                      arr_.push(el1.act);
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      MySet = new Set(arr_);

      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet.has(el1.depending)) {
                    return { ...el1, disabled: "true", value: "" };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state1, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "FR09_FIELD_INPUT_CHANGE":
      arr_ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (Number(action.payload) <= Number(el1.exception) || action.payload === "") {
                      arr_.push(el1.act);
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      MySet = new Set(arr_);
      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet.has(el1.depending)) {
                    return { ...el1, disabled: "true", value: "" };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state1, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "ED13_MONEY_INPUT_CHANGE":
    case "ED15_MONEY_INPUT_CHANGE":
    case "ED-NEW24A_MONEY_INPUT_CHANGE":
    case "ED-NEW26A_MONEY_INPUT_CHANGE":
    case "ED-NEW28A_MONEY_INPUT_CHANGE":
    case "ED-NEW29A_MONEY_INPUT_CHANGE":
    case "ED-NEW31A_MONEY_INPUT_CHANGE":
    case "ED-NEW33A_MONEY_INPUT_CHANGE":
    case "DA16-NEW118_MONEY_INPUT_CHANGE":
    case "DA16-NEW119_MONEY_INPUT_CHANGE":
    case "ES12_MONEY_INPUT_CHANGE":
    case "ES13_MONEY_INPUT_CHANGE":
    case "ES14_MONEY_INPUT_CHANGE":
    case "ES15_MONEY_INPUT_CHANGE":
    case "ES16_MONEY_INPUT_CHANGE":
    case "ES17_MONEY_INPUT_CHANGE":

    case "DS23_MONEY_INPUT_CHANGE":
    case "DS24_MONEY_INPUT_CHANGE": {
      // console.log("action.payload=",action.payload)
      let tmp_input = action.payload;
      //const replace_input = tmp_input.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return Object.assign({}, state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: /*action.payload*/ tmp_input } : el1
                ),
              }
            : el
        ),
      });
    }
    case "ED-NEW19_MONEY_INPUT_CHANGE":
    case "ED-NEW20_MONEY_INPUT_CHANGE":
      let tmp_input1 = action.payload;
      //const replace_input1 = tmp_input1.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending1 === act[0]) {
                    if (action.payload !== el1.exception1) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: tmp_input1 } : el1
                ),
              }
            : el
        ),
      });
    case "FR25_CHECKBOX_INPUT_CHANGE":
    case "FR26_CHECKBOX_INPUT_CHANGE":
    case "FR27_CHECKBOX_INPUT_CHANGE":
    case "FR28_CHECKBOX_INPUT_CHANGE":
    case "FR29_CHECKBOX_INPUT_CHANGE":
    case "FR30_CHECKBOX_INPUT_CHANGE":
    case "FR31_CHECKBOX_INPUT_CHANGE":
    case "FR32_CHECKBOX_INPUT_CHANGE":
    case "FR33_CHECKBOX_INPUT_CHANGE":
    case "FR34_CHECKBOX_INPUT_CHANGE":
    case "FR35_CHECKBOX_INPUT_CHANGE":
    case "DA0_CHECKBOX_INPUT_CHANGE":
    case "TEST_CHECKBOX_INPUT_CHANGE":
    case "SUM00_CHECKBOX_INPUT_CHANGE":
    case "SUM04RULE_CHECKBOX_INPUT_CHANGE":
    case "SUM04RES_CHECKBOX_INPUT_CHANGE":
    case "MSE1-3_CHECKBOX_INPUT_CHANGE":
    case "MSE1-4_CHECKBOX_INPUT_CHANGE":
    case "MSE1-5_CHECKBOX_INPUT_CHANGE":
    case "MSE1-6_CHECKBOX_INPUT_CHANGE":
    case "MSE1-7_CHECKBOX_INPUT_CHANGE":
    case "MSE1-8_CHECKBOX_INPUT_CHANGE":
    case "MSE1-9_CHECKBOX_INPUT_CHANGE":
    case "MSE1-10_CHECKBOX_INPUT_CHANGE":
    case "MSE1-11_CHECKBOX_INPUT_CHANGE":
    case "MSE1-12_CHECKBOX_INPUT_CHANGE":
    case "MSE1-13_CHECKBOX_INPUT_CHANGE":
    case "MSE2-BEHATT-1_CHECKBOX_INPUT_CHANGE": //Behavior/Attitude during the interview OK //Cooperative
    case "MSE2-BEHATT-4_CHECKBOX_INPUT_CHANGE": //Uncooperative
    case "MSE2-BEHATT-2_CHECKBOX_INPUT_CHANGE": //Good Eye Contact
    case "MSE2-BEHATT-6_CHECKBOX_INPUT_CHANGE": //Poor Eye Contact
    case "MSE2-BEHATT-7_CHECKBOX_INPUT_CHANGE": //Intermittent Eye Contact
    case "MSE2-BEHATT-8_CHECKBOX_INPUT_CHANGE": //Eye Contact Improved over Appointment
    case "MSE2-BEHATT-3_CHECKBOX_INPUT_CHANGE": //Normal Psychomotor Activity
    case "MSE2-BEHATT-9_CHECKBOX_INPUT_CHANGE": //Psychomotor Retarded
    case "MSE2-BEHATT-10_CHECKBOX_INPUT_CHANGE": //Elevated Psychomotor Activity
    case "MSE2-BEHATT-5_CHECKBOX_INPUT_CHANGE": //Withdrawn
    case "MSE2-BEHATT-11_CHECKBOX_INPUT_CHANGE": //Involuntary Movements
    case "MSE2-BEHATT-12_CHECKBOX_INPUT_CHANGE": //Unusual Mannerisms
    case "MSE2-BEHATT-13_CHECKBOX_INPUT_CHANGE": //Other
    case "MSE2-1_CHECKBOX_INPUT_CHANGE": //Mood OK
    case "MSE2-2_CHECKBOX_INPUT_CHANGE":
    case "MSE2-3_CHECKBOX_INPUT_CHANGE":
    case "MSE2-4_CHECKBOX_INPUT_CHANGE":
    case "MSE2-5_CHECKBOX_INPUT_CHANGE":
    case "MSE2-6_CHECKBOX_INPUT_CHANGE":
    case "MSE2-7_CHECKBOX_INPUT_CHANGE":
    case "MSE2-8_CHECKBOX_INPUT_CHANGE":
    case "MSE2-9_CHECKBOX_INPUT_CHANGE":
    case "MSE2-10_CHECKBOX_INPUT_CHANGE":
    case "MSE2-11_CHECKBOX_INPUT_CHANGE": //Affect OK
    case "MSE2-12_CHECKBOX_INPUT_CHANGE":
    case "MSE2-13_CHECKBOX_INPUT_CHANGE":
    case "MSE2-14_CHECKBOX_INPUT_CHANGE":
    case "MSE2-15_CHECKBOX_INPUT_CHANGE":
    case "MSE2-16_CHECKBOX_INPUT_CHANGE":
    case "MSE2-17_CHECKBOX_INPUT_CHANGE":
    case "MSE2-18_CHECKBOX_INPUT_CHANGE":
    case "MSE2-19_CHECKBOX_INPUT_CHANGE":
    case "MSE2-20_CHECKBOX_INPUT_CHANGE":
    case "MSE2-21_CHECKBOX_INPUT_CHANGE":
    case "MSE2-22_CHECKBOX_INPUT_CHANGE": //Speech OK //Normal Rate
    case "MSE2-23_CHECKBOX_INPUT_CHANGE": //Normal Amplitude
    case "MSE2-29-NORM_CHECKBOX_INPUT_CHANGE": //Normal Prosody
    case "MSE2-29_CHECKBOX_INPUT_CHANGE": //Abnormal Prosody
    case "MSE2-24_CHECKBOX_INPUT_CHANGE": //Rapid/Pressured
    case "MSE2-25_CHECKBOX_INPUT_CHANGE": //Slow
    case "MSE2-26_CHECKBOX_INPUT_CHANGE": //Loud
    case "MSE2-27_CHECKBOX_INPUT_CHANGE": //Soft
    case "MSE2-28_CHECKBOX_INPUT_CHANGE": //Slurred
    case "MSE2-30_CHECKBOX_INPUT_CHANGE": //Increased Latency
    case "MSE2-31_CHECKBOX_INPUT_CHANGE": //Dysarthria
    case "MSE2-32_CHECKBOX_INPUT_CHANGE": //Poverty of Speech
    case "MSE2-33_CHECKBOX_INPUT_CHANGE": //Other
    case "MSE3-2-NONE_CHECKBOX_INPUT_CHANGE": // Perceptual disorders/Hallucinations OK None
    case "MSE3-2_CHECKBOX_INPUT_CHANGE":
    case "MSE3-3_CHECKBOX_INPUT_CHANGE":
    case "MSE3-4_CHECKBOX_INPUT_CHANGE":
    case "MSE3-5_CHECKBOX_INPUT_CHANGE":
    case "MSE3-6_CHECKBOX_INPUT_CHANGE":
    case "MSE3-40_CHECKBOX_INPUT_CHANGE": //Orientation
    case "MSE3-41_CHECKBOX_INPUT_CHANGE":
    case "MSE3-42_CHECKBOX_INPUT_CHANGE":
    case "MSE3-7_CHECKBOX_INPUT_CHANGE": // Thought content
    case "MSE3-8_CHECKBOX_INPUT_CHANGE":
    case "MSE3-9_CHECKBOX_INPUT_CHANGE":
    case "MSE3-10_CHECKBOX_INPUT_CHANGE":
    case "MSE3-11_CHECKBOX_INPUT_CHANGE":
    case "MSE3-12_CHECKBOX_INPUT_CHANGE":
    case "MSE3-13_CHECKBOX_INPUT_CHANGE":
    case "MSE3-14_CHECKBOX_INPUT_CHANGE":
    case "MSE3-15_CHECKBOX_INPUT_CHANGE":
    case "MSE3-16_CHECKBOX_INPUT_CHANGE":
    case "MSE3-17_CHECKBOX_INPUT_CHANGE":
    case "MSE3-18_CHECKBOX_INPUT_CHANGE":
    case "MSE3-19_CHECKBOX_INPUT_CHANGE":
    case "MSE3-20_CHECKBOX_INPUT_CHANGE":
    case "MSE3-21_CHECKBOX_INPUT_CHANGE":
    case "MSE3-22_CHECKBOX_INPUT_CHANGE":
    case "MSE3-23_CHECKBOX_INPUT_CHANGE":
    case "MSE3-24_CHECKBOX_INPUT_CHANGE":
    case "MSE3-25_CHECKBOX_INPUT_CHANGE":
    case "MSE3-26_CHECKBOX_INPUT_CHANGE":
    case "MSE3-27_CHECKBOX_INPUT_CHANGE": // Sensorium Ok
    case "MSE3-28_CHECKBOX_INPUT_CHANGE":
    case "MSE3-29_CHECKBOX_INPUT_CHANGE":
    case "MSE3-30_CHECKBOX_INPUT_CHANGE":
    case "MSE3-31_CHECKBOX_INPUT_CHANGE":
    case "MSE3-39_CHECKBOX_INPUT_CHANGE":
    case "MSE4-1_CHECKBOX_INPUT_CHANGE": // Remote Memory OK
    case "MSE4-2_CHECKBOX_INPUT_CHANGE":
    case "MSE4-3_CHECKBOX_INPUT_CHANGE":
    case "MSE4-4_CHECKBOX_INPUT_CHANGE":
    case "MSE4-5_CHECKBOX_INPUT_CHANGE": // Recent Memory OK
    case "MSE4-6_CHECKBOX_INPUT_CHANGE":
    case "MSE4-7_CHECKBOX_INPUT_CHANGE":
    case "MSE4-8_CHECKBOX_INPUT_CHANGE":
    case "MSE4-9_CHECKBOX_INPUT_CHANGE": // Immediate Memory
    case "MSE4-10_CHECKBOX_INPUT_CHANGE":
    case "MSE4-11_CHECKBOX_INPUT_CHANGE":
    case "MSE4-12_CHECKBOX_INPUT_CHANGE":
    case "MSE4-13_CHECKBOX_INPUT_CHANGE": // Concentration and calculation
    case "MSE4-14_CHECKBOX_INPUT_CHANGE":
    case "MSE4-15_CHECKBOX_INPUT_CHANGE":
    case "MSE5-1_CHECKBOX_INPUT_CHANGE": // Information
    case "MSE5-2_CHECKBOX_INPUT_CHANGE":
    case "MSE5-3_CHECKBOX_INPUT_CHANGE":
    case "MSE5-4_CHECKBOX_INPUT_CHANGE": // Intelligence
    case "MSE5-5_CHECKBOX_INPUT_CHANGE":
    case "MSE5-6_CHECKBOX_INPUT_CHANGE":
    case "MSE5-7_CHECKBOX_INPUT_CHANGE":
    case "MSE5-8_CHECKBOX_INPUT_CHANGE": // Insight/Judgment ОК
    case "MSE5-9_CHECKBOX_INPUT_CHANGE":
    case "MSE5-10_CHECKBOX_INPUT_CHANGE":
    case "MSE5-11_CHECKBOX_INPUT_CHANGE":
    case "MSE5-12_CHECKBOX_INPUT_CHANGE":
    case "MSE5-13_CHECKBOX_INPUT_CHANGE": // Abstract Thinking
    case "MSE5-14_CHECKBOX_INPUT_CHANGE":
    case "MSE6-1_CHECKBOX_INPUT_CHANGE": //     Gait/Station OK
    case "MSE6-2_CHECKBOX_INPUT_CHANGE":
    case "MSE6-3_CHECKBOX_INPUT_CHANGE": // Muscle Strength OK
    case "MSE6-4_CHECKBOX_INPUT_CHANGE":
    case "MSE6-5_CHECKBOX_INPUT_CHANGE":
    case "MSE6-6_CHECKBOX_INPUT_CHANGE": // Muscle Tone OK
    case "MSE6-7_CHECKBOX_INPUT_CHANGE":
    case "MSE6-8_CHECKBOX_INPUT_CHANGE":
    case "MSE6-9_CHECKBOX_INPUT_CHANGE":
    case "MSE6-10_CHECKBOX_INPUT_CHANGE":
    case "MSE6-11_CHECKBOX_INPUT_CHANGE": // Other
    case "FR06-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR07-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR11-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR16-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR54-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR55-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR56-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR57-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR58-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR59-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR60-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR61-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR62-12-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-2-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-4-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-6-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-8-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-10-ADOL1_CHECKBOX_INPUT_CHANGE":
    case "FR63-12-ADOL1_CHECKBOX_INPUT_CHANGE":

    case "DS1_CHECKBOX_INPUT_CHANGE":

    case "PL1_CHECKBOX_INPUT_CHANGE":
    case "CMG1_CHECKBOX_INPUT_CHANGE":
      // case "PL1-ALCOHOL-USE-DISORDER-1_CHECKBOX_INPUT_CHANGE":
      // case "PL1-ALCOHOL-USE-DISORDER-2_CHECKBOX_INPUT_CHANGE":
      // case "PL1-ALCOHOL-USE-DISORDER-3_CHECKBOX_INPUT_CHANGE":
      // case "PL2-DRUG-USE-DISORDER-1_CHECKBOX_INPUT_CHANGE":
      // case "PL2-DRUG-USE-DISORDER-2_CHECKBOX_INPUT_CHANGE":
      // case "PL2-DRUG-USE-DISORDER-3_CHECKBOX_INPUT_CHANGE":
      // case "PL3-ABUSE-NEGLECT-1_CHECKBOX_INPUT_CHANGE":
      // case "PL3-ABUSE-NEGLECT-2_CHECKBOX_INPUT_CHANGE":
      // case "PL3-ABUSE-NEGLECT-3_CHECKBOX_INPUT_CHANGE":
      // case "PL4-ANGER-1_CHECKBOX_INPUT_CHANGE":
      // case "PL4-ANGER-2_CHECKBOX_INPUT_CHANGE":
      // case "PL4-ANGER-3_CHECKBOX_INPUT_CHANGE":
      // case "PL5-ANXIETY-1_CHECKBOX_INPUT_CHANGE":
      // case "PL5-ANXIETY-2_CHECKBOX_INPUT_CHANGE":
      // case "PL5-ANXIETY-3_CHECKBOX_INPUT_CHANGE":
      // case "PL6-DECISION-MAKING-1_CHECKBOX_INPUT_CHANGE":
      // case "PL6-DECISION-MAKING-2_CHECKBOX_INPUT_CHANGE":
      // case "PL6-DECISION-MAKING-3_CHECKBOX_INPUT_CHANGE":
      // case "PL7-BEHAVIOR-PROBLEMS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL7-BEHAVIOR-PROBLEMS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL7-BEHAVIOR-PROBLEMS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL8-COMMUNICATION-SKILLS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL8-COMMUNICATION-SKILLS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL8-COMMUNICATION-SKILLS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL9-CRIME-CRIMINAL-THINKING-1_CHECKBOX_INPUT_CHANGE":
      // case "PL9-CRIME-CRIMINAL-THINKING-2_CHECKBOX_INPUT_CHANGE":
      // case "PL9-CRIME-CRIMINAL-THINKING-3_CHECKBOX_INPUT_CHANGE":
      // case "PL10-DEPRESSION-1_CHECKBOX_INPUT_CHANGE":
      // case "PL10-DEPRESSION-2_CHECKBOX_INPUT_CHANGE":
      // case "PL10-DEPRESSION-3_CHECKBOX_INPUT_CHANGE":
      // case "PL11-EXPRESSION-OF-FEELINGS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL11-EXPRESSION-OF-FEELINGS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL11-EXPRESSION-OF-FEELINGS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL12-FAMILY-CONFLICT-1_CHECKBOX_INPUT_CHANGE":
      // case "PL12-FAMILY-CONFLICT-2_CHECKBOX_INPUT_CHANGE":
      // case "PL12-FAMILY-CONFLICT-3_CHECKBOX_INPUT_CHANGE":
      // case "PL13-GRIEF-AND-LOSS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL13-GRIEF-AND-LOSS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL13-GRIEF-AND-LOSS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL14-HARM-TO-SELF-OR-OTHERS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL14-HARM-TO-SELF-OR-OTHERS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL14-HARM-TO-SELF-OR-OTHERS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL15-HEALTH-ISSUES-1_CHECKBOX_INPUT_CHANGE":
      // case "PL15-HEALTH-ISSUES-2_CHECKBOX_INPUT_CHANGE":
      // case "PL15-HEALTH-ISSUES-3_CHECKBOX_INPUT_CHANGE":
      // case "PL16-PARENTING-1_CHECKBOX_INPUT_CHANGE":
      // case "PL16-PARENTING-2_CHECKBOX_INPUT_CHANGE":
      // case "PL16-PARENTING-3_CHECKBOX_INPUT_CHANGE":
      // case "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-1_CHECKBOX_INPUT_CHANGE":
      // case "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-2_CHECKBOX_INPUT_CHANGE":
      // case "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-3_CHECKBOX_INPUT_CHANGE":
      // case "PL18-RELATIONSHIPS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL18-RELATIONSHIPS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL18-RELATIONSHIPS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL19-SELF-IMAGE-1_CHECKBOX_INPUT_CHANGE":
      // case "PL19-SELF-IMAGE-2_CHECKBOX_INPUT_CHANGE":
      // case "PL19-SELF-IMAGE-3_CHECKBOX_INPUT_CHANGE":
      // case "PL20-SLEEP-PROBLEMS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL20-SLEEP-PROBLEMS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL20-SLEEP-PROBLEMS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL21-SOCIAL-SKILLS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL21-SOCIAL-SKILLS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL21-SOCIAL-SKILLS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL22-STRESS-1_CHECKBOX_INPUT_CHANGE":
      // case "PL22-STRESS-2_CHECKBOX_INPUT_CHANGE":
      // case "PL22-STRESS-3_CHECKBOX_INPUT_CHANGE":
      // case "PL23-SUICIDE-1_CHECKBOX_INPUT_CHANGE":
      // case "PL23-SUICIDE-2_CHECKBOX_INPUT_CHANGE":
      // case "PL23-SUICIDE-3_CHECKBOX_INPUT_CHANGE":
      // case "PL24-TRAUMA-1_CHECKBOX_INPUT_CHANGE":
      // case "PL24-TRAUMA-2_CHECKBOX_INPUT_CHANGE":
      // case "PL24-TRAUMA-3_CHECKBOX_INPUT_CHANGE":

      // case "DS1-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-1-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-1-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-1-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-2-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-2-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-2-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-3-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-3-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-3-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-4_CHECKBOX_INPUT_CHANGE":
      // case "DS1-4-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-4-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-4-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-5_CHECKBOX_INPUT_CHANGE":
      // case "DS1-5-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-5-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-5-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-6_CHECKBOX_INPUT_CHANGE":
      // case "DS1-6-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-6-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-6-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-7_CHECKBOX_INPUT_CHANGE":
      // case "DS1-7-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-7-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-7-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-8_CHECKBOX_INPUT_CHANGE":
      // case "DS1-8-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-8-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-8-3_CHECKBOX_INPUT_CHANGE":
      // case "DS1-9_CHECKBOX_INPUT_CHANGE":
      // case "DS1-9-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-9-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-9-3_CHECKBOX_INPUT_CHANGE":

      // case "DS1-10_CHECKBOX_INPUT_CHANGE":
      // case "DS1-10-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-10-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-10-3_CHECKBOX_INPUT_CHANGE":

      // case "DS1-11_CHECKBOX_INPUT_CHANGE":
      // case "DS1-11-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-11-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-11-3_CHECKBOX_INPUT_CHANGE":

      // case "DS1-12_CHECKBOX_INPUT_CHANGE":
      // case "DS1-12-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-12-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-12-3_CHECKBOX_INPUT_CHANGE":

      // case "DS1-13_CHECKBOX_INPUT_CHANGE":
      // case "DS1-13-1_CHECKBOX_INPUT_CHANGE":
      // case "DS1-13-2_CHECKBOX_INPUT_CHANGE":
      // case "DS1-13-3_CHECKBOX_INPUT_CHANGE":
      // console.log("state.newAssessment.adult_type=",state.newAssessment.adult_type);
      arr_ = []; //
      arr_exist_rank = [];
      arr_deep_route = [];
      bodyTmp = JSON.parse(
        JSON.stringify([
          ...state.assessment_state.filter((el) => el.body.find((el1) => el1.act === act[0])),
        ])
      );
      objectTmp = bodyTmp[0].body.find((el) => el.act === act[0]);
      valueTmp = objectTmp.value;
      //========================================================================================
      /*                                                                                      *
       *                                         PATCH                                        *
       *                                                                                      */
      //========================================================================================

      if (objectTmp.type === "checkbox" && objectTmp.value === "") {
        valueTmp = objectTmp.radio_options.map((el) => false);
      } else {
        valueTmp = objectTmp.value;
      }
      /*************************************************************************************** */
      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;

      //========================================================================================
      /*                                                                                      *
       *                                        SUM4RES                                       *
       *                                                                                      */
      //========================================================================================
      let commitAllChkBoxRule = false;
      let cancelAllChkBoxRule = false;
      //CHECK CHECKALL true or false
      objectTmp.radio_options.forEach((el, index) => {
        if (el.indexOf("font-weight:") >= 0 && objectTmp.value[index] && index == action.payload) {
          commitAllChkBoxRule = true;
        }
      });
      valueTmp = valueTmp.map((el) => (commitAllChkBoxRule ? true : el));
      //uncheck
      objectTmp.radio_options.forEach((el, index, objectTmpOrig) => {
        if (el.indexOf("font-weight:") < 0 && !objectTmp.value[index] && index == action.payload) {
          cancelAllChkBoxRule = true;
        }
      });

      objectTmp.radio_options.forEach((el, index) => {
        if (cancelAllChkBoxRule && el.indexOf("font-weight:") >= 0) valueTmp[index] = false;
      });

      //-----------------------------------
      let arr_1 = valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });
      MySet = new Set(arr_1);
      //-----------------------------------
      // console.log("state=",state.assessment_state,"arr_1=",arr_1)
      tmp_state0 = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // if ((ArrInArr(arr_1, el1.exception) && state.newAssessment.adult_type==="Adolescent") || (ArrInArr(arr_1, el1.exception)/* && el1.adolescent!=="true"*/ && state.newAssessment.adult_type==="Adult") ) {
                    if (ArrInArr(arr_1, el1.exception)) {
                      if (el1.type === "rank")
                        arr_exist_rank.push({
                          rank: el1.act,
                          value: el1.value,
                        });
                      if (routeOfAdmin.includes(el1.act)) {
                        arr_deep_route.push(el1.act);
                        return { ...el1, disabled: "false", value: [true] };
                      } else {
                        return { ...el1, disabled: "false" };
                      }
                    } else {
                      arr_.push(el1.act); //
                      // if(routeOfAdmin.includes(el1.act)){
                      //   console.log("el1.act=",el1.act)
                      //   return { ...el1, disabled: "true", value: [true] };
                      // } else
                      return { ...el1, disabled: "true", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      //========================================================================================
      /*                                                                                      *
       *                                   Accohol Route for Admin                                   *
       *                                                                                      */
      //========================================================================================

      MySet0 = new Set(arr_deep_route);
      tmp_state = Object.assign({}, tmp_state0, {
        assessment_state: tmp_state0.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet0.has(el1.depending)) {
                    // return { ...el1, disabled: "true", value: "" };
                    // console.log("el1=",el1);
                    return { ...el1, disabled: "false" };
                    // return { ...el1 };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      /*
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map(el =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map(el1 => {
                  if (el1.depending === act[0]) {
                    if (MySet.has(el1.exception)) {
                      return { ...el1, disabled: "false" };
                    } else {
                      arr_.push(el1.act); //
                      return { ...el1, disabled: "true", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                })
              }
            : el
        )
      });
*/
      // console.log("tmp_state=",tmp_state.assessment_state)
      MySet1 = new Set(arr_); //
      // console.log("MySet1=",MySet1);
      // console.log("arr_=",arr_);
      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet1.has(el1.depending)) {
                    // console.log("MySet1=",MySet1);
                    return { ...el1, disabled: "true", value: "" };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp_state1=",tmp_state1.assessment_state)
      tmp_state2 = Object.assign({}, tmp_state1, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: valueTmp } : el1
                ),
              }
            : el
        ),
      });

      //make rank 1,2,3
      arr_exist_rank = arr_exist_rank
        .sort((a, b) => a.value - b.value)
        .filter((el) => Number(el.value) > 0);
      for (let i = 0; i < arr_exist_rank.length; i++) {
        arr_exist_rank[i].value = (i + 1).toString();
      }

      tmp_state3 = Object.assign({}, tmp_state2, {
        assessment_state: tmp_state2.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.type === "rank" && contains_rank(arr_exist_rank, el1.act) !== "") {
                    return {
                      ...el1,
                      value: contains_rank(arr_exist_rank, el1.act),
                    };
                  } else {
                    return el1;
                  }
                }),
              }
            : el
        ),
      });

      sumRanks = 0;
      for (let i = 0; i < tmp_state3.assessment_state.length; i++) {
        let topic = tmp_state3.assessment_state[i];
        // console.log("topic=",topic)
        for (let j = 0; j < topic.body.length; j++) {
          let fieldObj = Object.assign({}, topic.body[j]);
          if (fieldObj.type === "rank" && fieldObj.disabled === "false") {
            sumRanks += Number(fieldObj.value);
          }
        }
      }

      return Object.assign({}, tmp_state3, { RankStatus: sumRanks });
    case "FR23-NEW_RADIO_INPUT_CHANGE":
    case "DA-NEW1_RADIO_INPUT_CHANGE":
    case "FR78-ADOL1_RADIO_INPUT_CHANGE":
    case "FR79-ADOL1_RADIO_INPUT_CHANGE":
    case "FR03_RADIO_INPUT_CHANGE":
    case "PL0_RADIO_INPUT_CHANGE":
    case "CMG0_RADIO_INPUT_CHANGE":
      arr_ = [];
      arr__ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (
                      !ArrInArr(
                        [action.payload],
                        el1.exception
                      ) /*|| (action.payload === el1.exception && state.newAssessment.adult_type==="Adult")*/
                    ) {
                      arr_.push(el1.act);
                      return {
                        ...el1,
                        disabled: "true",
                        value: returnClearValue(el1),
                      };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      MySet = new Set(arr_);
      tmp_state1 = Object.assign({}, tmp_state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet.has(el1.depending)) {
                    arr__.push(el1.act);
                    return {
                      ...el1,
                      disabled: "true",
                      value: returnClearValue(el1),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      MySet1 = new Set(arr__);
      tmp_state2 = Object.assign({}, tmp_state1, {
        assessment_state: tmp_state1.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (MySet1.has(el1.depending)) {
                    return {
                      ...el1,
                      disabled: "true",
                      value: returnClearValue(el1),
                    };
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state2, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state2.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "CI1_RADIO_INPUT_CHANGE":
      // case "FR78-ADOL1_RADIO_INPUT_CHANGE":
      // case "FR79-ADOL1_RADIO_INPUT_CHANGE":
      // tmp_state = Object.assign({}, state, {
      //   assessment_state: state.assessment_state.map((el) =>
      //     el.topic !== undefined
      //       ? {
      //           ...el,
      //           body: el.body.map((el1) => {
      //             if (el1.depending === act[0]) {
      //               if (action.payload !== el1.exception) {
      //                 return { ...el1, disabled: "true", value: "" };
      //               } else {
      //                 return { ...el1, disabled: "false" };
      //               }
      //             } else {
      //               return { ...el1 };
      //             }
      //           }),
      //         }
      //       : el
      //   ),
      // });

      arr_ = [];
      arr__ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload !== el1.exception) {
                      return { ...el1, disabled: "false" };
                    } else {
                      arr_.push(el1.act);
                      return { ...el1, disabled: "true", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      // console.log("tmp_state=",tmp_state,"arr_=",arr_)
      [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      while (arr_.length > 0) {
        [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      }
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "SUM-PRI1_FIELD_INPUT_CHANGE":
    case "SUM-PRI2_FIELD_INPUT_CHANGE":
    case "SUM-ADD1_FIELD_INPUT_CHANGE":
    case "SUM-ADD2_FIELD_INPUT_CHANGE":
    case "SUM-ADD3_FIELD_INPUT_CHANGE":
    case "SUM-ADD4_FIELD_INPUT_CHANGE":
    case "SUM-ADD5_FIELD_INPUT_CHANGE":
    case "SUM-ADD6_FIELD_INPUT_CHANGE":
    case "SUM-ADD7_FIELD_INPUT_CHANGE":
    case "SUM-ADD8_FIELD_INPUT_CHANGE":
    case "SUM-ADD9_FIELD_INPUT_CHANGE":
    case "SUM-ADD10_FIELD_INPUT_CHANGE":
    case "SUM-ADD11_FIELD_INPUT_CHANGE":
    case "SUM-ADD12_FIELD_INPUT_CHANGE":
    case "SUM-ADD13_FIELD_INPUT_CHANGE":
    case "SUM-ADD14_FIELD_INPUT_CHANGE":
    case "SUM-ADD15_FIELD_INPUT_CHANGE":
    case "SUM-ADD16_FIELD_INPUT_CHANGE":
    case "SUM-ADD17_FIELD_INPUT_CHANGE":
    case "SUM-ADD18_FIELD_INPUT_CHANGE":
    case "SUM-ADD19_FIELD_INPUT_CHANGE":
    case "SUM-ADD20_FIELD_INPUT_CHANGE":
    case "CI1-1_FIELD_INPUT_CHANGE":
    case "CI1-2_FIELD_INPUT_CHANGE":
    case "CI1-3_FIELD_INPUT_CHANGE":
    case "CI1-4_FIELD_INPUT_CHANGE":
    case "CI1-5_FIELD_INPUT_CHANGE":
    case "CI1-6_FIELD_INPUT_CHANGE":
    case "CI1-7_FIELD_INPUT_CHANGE":
    case "CI1-8_FIELD_INPUT_CHANGE":
    case "CI1-9_FIELD_INPUT_CHANGE":
      // case "M1_RADIO_INPUT_CHANGE":
      arr_ = [];
      arr__ = [];
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (action.payload !== el1.exception) {
                      return { ...el1, disabled: "false" };
                    } else {
                      arr_.push(el1.act);
                      return { ...el1, disabled: "true", value: "" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      while (arr_.length > 0) {
        [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      }
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    //   case "M1_RADIO_INPUT_CHANGE":
    //         arr_ = [];
    //         arr__ = [];
    //         const elemGender = jsonElem(state.assessment_state,"M0");
    //         // console.log("elemGender=",elemGender);
    //         const elemSex = jsonElem(state.assessment_state,"M-NEW14");
    //         // console.log("elemSex=",elemSex);
    //         // console.log("state.newAssessment=",state.newAssessment);
    //         const adult_type=state.newAssessment.adult_type;

    //         tmp_state = Object.assign({}, state, {
    //           assessment_state: state.assessment_state.map((el) =>
    //             el.topic !== undefined
    //               ? {
    //                   ...el,
    //                   body: el.body.map((el1) => {
    //                     if (el1.depending1 === act[0] && (el1.shortname!=="m-new14" || el1.shortname!=="m0")) {
    //                       // console.log("el1.id=",el1.id)
    // //========================================================================================
    // /*                                                                                      *
    //  *                                    // BLOCK MH.12 and m12-0-adol4                      *
    //  *                                                                                      */
    // //========================================================================================
    //                       if (action.payload !== el1.exception1 && ((el1.shortname==="m-new19" || el1.shortname==="m-new15" || el1.shortname==="m-new16" || el1.shortname==="m14" || el1.shortname==="m-new18" || (el1.shortname==="m12-0-adol4" &&  adult_type==="Adolescent") || (el1.shortname==="m-new15-adol1" &&  adult_type==="Adolescent")  )&&  (el1.exception===elemGender.value || el1.exception===elemSex.value && elemGender.value==="Other"))) {
    //                         return { ...el1, disabled: "false" };
    //                       } else {
    //                         // console.log("el1.exception=",el1.exception,"el1.depending=",el1.depending,"el1.act=",el1.act);
    //                         arr_.push(el1.act);
    //                         return { ...el1, disabled: "true" };
    //                       }
    //                     } else {
    //                       if (el1.depending === act[0]) {
    // //========================================================================================
    // /*                                                                                      *
    //  *                                       ALL OTHER                                      *
    //  *                                                                                      */
    // //========================================================================================
    //                         if (action.payload !== el1.exception && ((el1.shortname==="m12-0-adol1" || el1.shortname==="m12-0-adol2" || el1.shortname==="m12-adol3" || el1.shortname!=="m9" )&& adult_type==="Adolescent")
    //                             || (action.payload !== el1.exception && (el1.shortname!=="m12-0-adol1" && el1.shortname!=="m12-0-adol2" && el1.shortname!=="m12-adol3" &&  el1.shortname!=="m9" ))
    //                             || action.payload !== el1.exception && el1.shortname==="m9" && adult_type!=="Adolescent") {
    //                           // console.log("el1.id=",el1.id, "el1.exception =",el1.exception );
    //                           return { ...el1, disabled: "false" };
    //                         } else {
    //                           arr_.push(el1.act);
    //                           return { ...el1, disabled: "true", value: "" };
    //                         }
    //                       } else {
    //                         return { ...el1 };
    //                       }
    //                     }
    //                   }),
    //                 }
    //               : el
    //           ),
    //         });
    //         [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    //         while (arr_.length > 0) {
    //           [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    //         }
    //         return Object.assign({}, tmp_state, {
    //           wasClick: state.wasClick + 1,
    //           assessment_state_changed: true,
    //           assessment_state: tmp_state.assessment_state.map((el) =>
    //             el.topic !== undefined
    //               ? {
    //                   ...el,
    //                   body: el.body.map((el1) =>
    //                     el1.act === act[0] ? { ...el1, value: action.payload } : el1
    //                   ),
    //                 }
    //               : el
    //           ),
    //         });
    // case "M1_RADIO_INPUT_CHANGE":
    //   arr_ = [];
    //   arr__ = [];
    //   const elemGender = jsonElem(state.assessment_state,"M0");
    //   // console.log("elemGender=",elemGender);
    //   const elemSex = jsonElem(state.assessment_state,"M-NEW14");
    //   // console.log("elemSex=",elemSex);

    //   tmp_state = Object.assign({}, state, {
    //     assessment_state: state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) => {
    //               if (el1.depending1 === act[0] && (el1.shortname!=="m-new14" || el1.shortname!=="m0")) {
    //                 console.log("act[0]=",act[0],"el1=",el1)
    //                 // console.log("el1.shortname=",el1.shortname,"elemGender.value=",elemGender.value)
    //                 if (action.payload !== el1.exception1 && ((el1.shortname==="m-new19" || el1.shortname==="m-new15" || el1.shortname==="m-new16" || el1.shortname==="m14" || el1.shortname==="m-new18" )&&  (el1.exception===elemGender.value || el1.exception===elemSex.value && elemGender.value==="Other"))) {
    //                   return { ...el1, disabled: "false" };
    //                 } else {
    //                   arr_.push(el1.act);
    //                   return { ...el1, disabled: "true" };
    //                 }
    //               } else {
    //                 if (el1.depending === act[0]) {
    //                   if (action.payload !== el1.exception) {
    //                     return { ...el1, disabled: "false" };
    //                   } else {
    //                     arr_.push(el1.act);
    //                     return { ...el1, disabled: "true", value: "" };
    //                   }
    //                 } else {
    //                   return { ...el1 };
    //                 }
    //               }
    //             }),
    //           }
    //         : el
    //     ),
    //   });
    //   [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    //   while (arr_.length > 0) {
    //     [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    //   }
    //   return Object.assign({}, tmp_state, {
    //     wasClick: state.wasClick + 1,
    //     assessment_state_changed: true,
    //     assessment_state: tmp_state.assessment_state.map((el) =>
    //       el.topic !== undefined
    //         ? {
    //             ...el,
    //             body: el.body.map((el1) =>
    //               el1.act === act[0] ? { ...el1, value: action.payload } : el1
    //             ),
    //           }
    //         : el
    //     ),
    //   });
    case "M1_RADIO_INPUT_CHANGE":
      arr_ = [];
      arr__ = [];
      const elemGender = jsonElem(state.assessment_state, "M0");
      // console.log("elemGender=",elemGender);
      const elemSex = jsonElem(state.assessment_state, "M-NEW14"); //MH.12.1 Sex assigned at birth
      // console.log("elemSex=",elemSex);

      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  //m0: id: "MH.12" "Gender :"  m-new14:

                  if (
                    el1.depending1 === act[0] &&
                    (el1.shortname !== "m-new14" || el1.shortname !== "m0")
                  ) {
                    //gender block
                    // act[0] =M1
                    //"m-new14"  id: "MH.12.1",
                    //"m0"  id: "MH.12",
                    // console.log("act[0]=",act[0]);
                    // console.log("el1.depending1=",el1.depending1);
                    // console.log("el1.shortname=",el1.shortname,"elemGender.value=",elemGender.value)
                    if (
                      action.payload !== el1.exception1 &&
                      (el1.shortname === "m-new19" ||
                        el1.shortname === "m-new15" ||
                        el1.shortname === "m-new15-adol1" ||
                        el1.shortname ===
                          "m-new16") /*|| el1.shortname==="m14" */ /*|| el1.shortname==="m-new18"*/ &&
                      (el1.exception === elemGender.value ||
                        (el1.exception === elemSex.value && elemGender.value === "Other"))
                    ) {
                      console.log("action.payload=", action.payload);
                      console.log("el1.=", el1);
                      // "m-new19" "MH.12.6"
                      // "m-new15" "MH.12.2"
                      // "m-new15-adol1"  "MH.12.2-1(adolescent)"
                      // "m-new16" "MH.12.3"
                      // "m14"  "MH.12.4"
                      return { ...el1, disabled: "false" };
                    } else {
                      arr_.push(el1.act);
                      if (el1.shortname === "m-new14" || el1.shortname === "m0") {
                        return { ...el1, disabled: "true" };
                      } else {
                        return { ...el1, disabled: "true", value: "" };
                      }
                    }
                  } else {
                    if (el1.depending === act[0]) {
                      if (action.payload !== el1.exception) {
                        return { ...el1, disabled: "false" };
                      } else {
                        arr_.push(el1.act);
                        return { ...el1, disabled: "true", value: "" };
                      }
                    } else {
                      return { ...el1 };
                    }
                  }
                }),
              }
            : el
        ),
      });
      // console.log("---",tmp_state);
      // console.log("action.payload=",action.payload)
      [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      while (arr_.length > 0) {
        [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
      }
      // console.log("---",tmp_state);
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "SAVE_ASSESSMENT_BUTTON_DISABLE":
      return Object.assign({}, state, {
        assessment_state_changed: action.payload,
      });

    case "SAVE_STARTASSESSMENT_BUTTON_DISABLE":
      return Object.assign({}, state, {
        patient_info_changed: action.payload,
      });

    case "SAVE_ASSESSMENT_CONFIRMATION":
      return Object.assign({}, state, { assessment_confirm: action.payload });

    case "SAVE_STARTASSESSMENT_CONFIRMATION":
      return Object.assign({}, state, {
        startassessment_confirm: action.payload,
      });

    //========================================================================================
    /*                                                                                      *
     *                                    BLOCKS_FOR_DAH                                    *
     *                                                                                      */
    //========================================================================================

    case "ALCOHOL-BLOCK-7_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-11_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-15_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-19_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-23_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-27_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-31_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-35_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-39_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-43_FIELD_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-47_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-7_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-11_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-15_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-19_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-23_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-27_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-31_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-35_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-39_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-43_FIELD_INPUT_CHANGE":
    case "CANNABIS-BLOCK-47_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-7_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-11_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-15_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-19_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-23_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-27_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-31_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-35_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-39_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-43_FIELD_INPUT_CHANGE":
    case "COCAINE-BLOCK-47_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-7_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-11_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-15_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-19_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-23_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-27_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-31_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-35_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-39_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-43_FIELD_INPUT_CHANGE":
    case "CRACK-BLOCK-47_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-7_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-11_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-15_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-19_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-23_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-27_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-31_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-35_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-39_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-43_FIELD_INPUT_CHANGE":
    case "CRANK-BLOCK-47_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-7_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-11_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-15_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-19_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-23_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-27_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-31_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-35_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-39_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-43_FIELD_INPUT_CHANGE":
    case "ECSTASY-BLOCK-47_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-7_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-11_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-15_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-19_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-23_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-27_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-31_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-35_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-39_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-43_FIELD_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-47_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-7_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-11_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-15_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-19_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-23_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-27_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-31_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-35_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-39_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-43_FIELD_INPUT_CHANGE":
    case "HEROIN-BLOCK-47_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-7_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-11_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-15_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-19_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-23_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-27_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-31_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-35_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-39_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-43_FIELD_INPUT_CHANGE":
    case "INHALANTS-BLOCK-47_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-7_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-11_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-15_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-19_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-23_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-27_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-31_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-35_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-39_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-43_FIELD_INPUT_CHANGE":
    case "METH-BLOCK-47_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-7_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-11_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-15_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-19_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-23_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-27_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-31_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-35_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-39_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-43_FIELD_INPUT_CHANGE":
    case "METHADONE-BLOCK-47_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-7_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-11_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-15_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-19_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-23_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-27_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-31_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-35_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-39_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-43_FIELD_INPUT_CHANGE":
    case "OPIATES-BLOCK-47_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-7_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-11_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-15_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-19_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-23_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-27_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-31_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-35_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-39_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-43_FIELD_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-47_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-7_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-11_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-15_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-19_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-23_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-27_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-31_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-35_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-39_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-43_FIELD_INPUT_CHANGE":
    case "TOBACCO-BLOCK-47_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-7_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-11_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-15_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-19_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-23_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-27_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-31_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-35_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-39_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-43_FIELD_INPUT_CHANGE":
    case "OTHER-BLOCK-47_FIELD_INPUT_CHANGE":
      localPayload = action.payload === null ? "" : action.payload;
      actChanged = action.type.replace("_FIELD_INPUT_CHANGE", "");
      // console.log("hafreducer", actChanged);
      return Object.assign({}, state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: localPayload } : el1
                ),
              }
            : el
        ),
      });
    case "ALCOHOL-BLOCK-6_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-8_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-9_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-10_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-12_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-13_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-14_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-16_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-17_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-18_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-20_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-21_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-22_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-24_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-25_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-26_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-28_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-29_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-30_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-32_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-33_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-34_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-36_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-37_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-38_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-40_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-41_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-42_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-44_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-45_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-46_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-48_RADIO_INPUT_CHANGE":
    case "ALCOHOL-BLOCK-49_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-6_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-8_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-9_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-10_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-12_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-13_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-14_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-16_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-17_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-18_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-20_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-21_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-22_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-24_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-25_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-26_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-28_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-29_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-30_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-32_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-33_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-34_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-36_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-37_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-38_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-40_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-41_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-42_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-44_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-45_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-46_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-48_RADIO_INPUT_CHANGE":
    case "CANNABIS-BLOCK-49_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-6_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-8_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-9_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-10_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-12_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-13_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-14_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-16_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-17_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-18_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-20_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-21_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-22_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-24_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-25_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-26_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-28_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-29_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-30_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-32_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-33_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-34_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-36_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-37_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-38_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-40_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-41_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-42_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-44_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-45_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-46_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-48_RADIO_INPUT_CHANGE":
    case "COCAINE-BLOCK-49_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-6_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-8_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-9_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-10_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-12_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-13_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-14_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-16_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-17_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-18_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-20_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-21_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-22_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-24_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-25_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-26_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-28_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-29_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-30_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-32_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-33_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-34_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-36_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-37_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-38_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-40_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-41_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-42_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-44_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-45_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-46_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-48_RADIO_INPUT_CHANGE":
    case "CRACK-BLOCK-49_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-6_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-8_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-9_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-10_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-12_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-13_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-14_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-16_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-17_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-18_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-20_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-21_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-22_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-24_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-25_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-26_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-28_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-29_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-30_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-32_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-33_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-34_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-36_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-37_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-38_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-40_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-41_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-42_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-44_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-45_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-46_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-48_RADIO_INPUT_CHANGE":
    case "CRANK-BLOCK-49_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-6_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-8_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-9_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-10_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-12_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-13_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-14_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-16_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-17_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-18_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-20_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-21_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-22_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-24_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-25_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-26_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-28_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-29_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-30_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-32_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-33_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-34_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-36_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-37_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-38_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-40_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-41_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-42_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-44_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-45_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-46_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-48_RADIO_INPUT_CHANGE":
    case "ECSTASY-BLOCK-49_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-6_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-8_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-9_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-10_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-12_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-13_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-14_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-16_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-17_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-18_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-20_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-21_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-22_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-24_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-25_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-26_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-28_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-29_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-30_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-32_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-33_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-34_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-36_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-37_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-38_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-40_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-41_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-42_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-44_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-45_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-46_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-48_RADIO_INPUT_CHANGE":
    case "HALLUCINOGENS-BLOCK-49_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-6_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-8_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-9_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-10_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-12_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-13_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-14_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-16_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-17_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-18_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-20_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-21_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-22_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-24_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-25_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-26_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-28_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-29_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-30_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-32_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-33_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-34_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-36_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-37_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-38_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-40_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-41_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-42_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-44_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-45_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-46_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-48_RADIO_INPUT_CHANGE":
    case "HEROIN-BLOCK-49_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-6_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-8_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-9_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-10_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-12_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-13_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-14_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-16_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-17_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-18_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-20_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-21_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-22_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-24_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-25_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-26_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-28_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-29_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-30_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-32_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-33_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-34_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-36_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-37_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-38_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-40_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-41_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-42_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-44_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-45_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-46_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-48_RADIO_INPUT_CHANGE":
    case "INHALANTS-BLOCK-49_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-6_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-8_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-9_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-10_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-12_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-13_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-14_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-16_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-17_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-18_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-20_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-21_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-22_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-24_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-25_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-26_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-28_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-29_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-30_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-32_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-33_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-34_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-36_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-37_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-38_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-40_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-41_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-42_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-44_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-45_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-46_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-48_RADIO_INPUT_CHANGE":
    case "METH-BLOCK-49_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-6_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-8_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-9_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-10_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-12_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-13_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-14_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-16_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-17_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-18_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-20_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-21_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-22_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-24_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-25_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-26_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-28_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-29_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-30_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-32_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-33_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-34_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-36_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-37_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-38_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-40_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-41_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-42_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-44_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-45_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-46_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-48_RADIO_INPUT_CHANGE":
    case "METHADONE-BLOCK-49_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-6_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-8_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-9_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-10_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-12_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-13_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-14_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-16_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-17_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-18_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-20_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-21_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-22_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-24_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-25_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-26_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-28_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-29_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-30_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-32_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-33_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-34_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-36_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-37_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-38_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-40_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-41_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-42_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-44_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-45_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-46_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-48_RADIO_INPUT_CHANGE":
    case "OPIATES-BLOCK-49_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-6_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-8_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-9_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-10_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-12_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-13_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-14_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-16_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-17_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-18_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-20_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-21_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-22_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-24_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-25_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-26_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-28_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-29_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-30_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-32_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-33_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-34_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-36_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-37_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-38_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-40_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-41_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-42_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-44_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-45_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-46_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-48_RADIO_INPUT_CHANGE":
    case "SEDATIVE-BLOCK-49_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-6_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-8_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-9_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-10_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-12_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-13_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-14_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-16_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-17_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-18_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-20_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-21_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-22_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-24_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-25_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-26_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-28_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-29_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-30_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-32_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-33_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-34_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-36_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-37_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-38_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-40_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-41_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-42_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-44_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-45_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-46_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-48_RADIO_INPUT_CHANGE":
    case "TOBACCO-BLOCK-49_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-6_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-8_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-9_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-10_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-12_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-13_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-14_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-16_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-17_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-18_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-20_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-21_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-22_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-24_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-25_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-26_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-28_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-29_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-30_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-32_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-33_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-34_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-36_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-37_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-38_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-40_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-41_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-42_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-44_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-45_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-46_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-48_RADIO_INPUT_CHANGE":
    case "OTHER-BLOCK-49_RADIO_INPUT_CHANGE":
      // tmp_state = Object.assign({}, state, {
      //   assessment_state: state.assessment_state.map((el) =>
      //     el.topic !== undefined
      //       ? {
      //           ...el,
      //           body: el.body.map((el1) => {
      //             if (el1.depending === act[0]) {
      //               if (
      //                 /*action.payload !== el1.exception*/ !ArrInArr(
      //                   [action.payload],
      //                   el1.exception
      //                 )
      //               ) {
      //                 return { ...el1, disabled: "true", value: "" };
      //               } else {
      //                 return { ...el1, disabled: "false" };
      //               }
      //             } else {
      //               return { ...el1 };
      //             }
      //           }),
      //         }
      //       : el
      //   ),
      // });
      // console.log("act[0]=",act[0]);
      // console.log("objectTmp=",objectTmp);
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    if (!ArrInArr([action.payload], el1.exception)) {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                  // if(el1.meaningfulcount===)
                }),
              }
            : el
        ),
      });
      // if(/*action.payload===el1.meaningfulcount &&*/ objectTmp.subtopic_container_act === el1.subtopic_container_act){
      //   let arrayScope = bodyTmp[0].body.filter((el) => (el.subtopic_container_act === objectTmp.subtopic_container_act && el.type==="radio" /*&& el.value===el1.meaningfulcount*/));
      //   console.log("arrayScope=",arrayScope);
      //   console.log("el1.subtopic_container_act=",el1.subtopic_container_act);
      //   console.log("el1.meaningfulcount=",el1.meaningfulcount);
      //   console.log("act[0]=",act[0]);
      //   console.log("action.payload=",action.payload);
      // }
      tmp_state1 = Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
      // counting array
      bodyTmp = JSON.parse(
        JSON.stringify([
          ...tmp_state1.assessment_state.filter((el) => el.body.find((el1) => el1.act === act[0])),
        ])
      );
      // console.log("bodyTmp=",bodyTmp,"act[0]=",act[0]);
      objectTmp = bodyTmp[0].body.find((el) => el.act === act[0]);
      // console.log("objectTmp=",objectTmp);
      let arrayScope = bodyTmp[0].body
        .filter((el) => el.subtopic_container_act === objectTmp.subtopic_container_act)
        .map((el) => el.value);
      // console.log("arrayScope=",arrayScope);
      // console.log("bodyTmp[0]=",bodyTmp[0]);
      //counting meaningfulcount
      let meaningfulcountField = bodyTmp[0].body.find(
        (el) =>
          el.subtopic_container_act === objectTmp.subtopic_container_act &&
          el.hasOwnProperty("meaningfulcount")
      );
      // console.log("meaningfulcountField=",meaningfulcountField);
      let meaningfulcountValue =
        meaningfulcountField !== undefined
          ? arrayScope.filter((el) => el === meaningfulcountField.meaningfulcount).length
          : "0";
      // let meaningfulcountComment = ["Rank: Mild Disorder","Rank: Moderate Disorder","Rank: Severe Disorder"].find((el,index)=>meaningfulcountValue)
      let meaningfulcountComment =
        Number(meaningfulcountValue) >= 2 && Number(meaningfulcountValue) <= 3
          ? /*"Rank: Mild Disorder"*/ `Consider Mild ${meaningfulcountField.exception} Use Disorder`
          : Number(meaningfulcountValue) >= 4 && Number(meaningfulcountValue) <= 5
          ? /*"Rank: Moderate Disorder"*/ `Consider Moderate ${meaningfulcountField.exception} Use Disorder`
          : Number(meaningfulcountValue) >= 6 && Number(meaningfulcountValue) <= 11
          ? /*"Rank: Severe Disorder"*/ `Consider Severe ${meaningfulcountField.exception} Use Disorder`
          : `Consider no diagnosis for ${meaningfulcountField.exception}`;
      // console.log("meaningfulcountValue=",meaningfulcountValue);
      // finish update
      return meaningfulcountField !== undefined
        ? Object.assign({}, tmp_state1, {
            assessment_state: tmp_state1.assessment_state.map((el) =>
              el.topic !== undefined
                ? {
                    ...el,
                    body: el.body.map((el1) =>
                      el1.act === meaningfulcountField.act
                        ? {
                            ...el1,
                            value: meaningfulcountValue,
                            comment: meaningfulcountComment,
                          }
                        : el1
                    ),
                  }
                : el
            ),
          })
        : tmp_state1;
    case "LS3_FIELD_INPUT_CHANGE":
    case "LS4_FIELD_INPUT_CHANGE":
    case "LS5_FIELD_INPUT_CHANGE":
    case "LS6_FIELD_INPUT_CHANGE":
    case "LS7_FIELD_INPUT_CHANGE":
    case "LS8_FIELD_INPUT_CHANGE":
    case "LS9_FIELD_INPUT_CHANGE":
    case "LS10_FIELD_INPUT_CHANGE":
    case "LS11_FIELD_INPUT_CHANGE":
    case "LS12_FIELD_INPUT_CHANGE":
    case "LS13_FIELD_INPUT_CHANGE":
    case "LS14_FIELD_INPUT_CHANGE":
    case "LS15_FIELD_INPUT_CHANGE":
    case "LS16_FIELD_INPUT_CHANGE":
      localPayload = action.payload === null ? "" : action.payload;
      actChanged = action.type.replace("_FIELD_INPUT_CHANGE", "");
      // console.log("hafreducer", actChanged);
      tmp_state = Object.assign({}, state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: localPayload } : el1
                ),
              }
            : el
        ),
      });

      bodyTmp = JSON.parse(
        JSON.stringify([
          ...tmp_state.assessment_state.filter((el) => el.body.find((el1) => el1.act === act[0])),
        ])
      );
      // console.log("bodyTmp=",bodyTmp,"act[0]=",act[0]);
      objectTmp = bodyTmp[0].body.find((el) => el.act === act[0]);
      // console.log("objectTmp=",objectTmp);
      let arrFields_ls3_ls16 = bodyTmp[0].body
        .filter((el) => el.subtopic_container_act === objectTmp.subtopic_container_act)
        .map((el1) => Number(el1.value));
      let sumFields_ls3_ls16 = arrFields_ls3_ls16.reduce((a, b) => Number(a) + Number(b), 0);
      // console.log("arrFields_ls3_ls16=",arrFields_ls3_ls16,"sumFields_ls3_ls16=",sumFields_ls3_ls16);

      objectTmp = bodyTmp[0].body.find((el) => el.act === "LS18");
      // console.log("objectTmp=",objectTmp);
      let arrFields_ls18_ls20 = bodyTmp[0].body
        .filter((el) => el.subtopic_container_act === objectTmp.subtopic_container_act)
        .map((el1) => Number(el1.value));
      // let sumFields_ls18_ls20 = arrFields_ls18_ls20.reduce((a,b)=>Number(a)+Number(b),0)

      // tmp_state1=Object.assign({}, state, {
      //   assessment_state: tmp_state.assessment_state.map((el) =>
      //     el.topic !== undefined
      //       ? {
      //           ...el,
      //           body: el.body.map((el1) =>
      //             el1.shortname === "ls17" ? { ...el1, value: sumFields_ls3_ls16 } : el1.shortname === "ls23" ? { ...el1, value: sumFields_ls3_ls16+sumFields_ls18_ls20 } : el1
      //           ),
      //         }
      //       : el
      //   ),
      // });
      tmp_state1 = Object.assign({}, state, {
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map(
                  (el1) => {
                    if (el1.shortname === "ls17") {
                      if (sumFields_ls3_ls16 > 0)
                        return {
                          ...el1,
                          value: sumFields_ls3_ls16,
                          disabled: "false",
                        };
                      else
                        return {
                          ...el1,
                          value: sumFields_ls3_ls16,
                          disabled: "true",
                        };
                    } else {
                      // if(el1.shortname === "ls23"){
                      //   return { ...el1, value: sumFields_ls3_ls16+sumFields_ls18_ls20 }
                      // } else {
                      return el1;
                      // }
                    }
                  }
                  // el1.shortname === "ls17" ? { ...el1, value: sumFields_ls3_ls16 } : el1.shortname === "ls23" ? { ...el1, value: sumFields_ls3_ls16+sumFields_ls18_ls20 } : el1
                ),
              }
            : el
        ),
      });
      return tmp_state1;
    case "LS18_FIELD_INPUT_CHANGE":
    case "LS19_FIELD_INPUT_CHANGE":
    case "LS20_FIELD_INPUT_CHANGE":
      localPayload = action.payload === null ? "" : action.payload;
      actChanged = action.type.replace("_FIELD_INPUT_CHANGE", "");
      // console.log("hafreducer", actChanged);
      tmp_state = Object.assign({}, state, {
        field_last_changed: actChanged,
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: localPayload } : el1
                ),
              }
            : el
        ),
      });

      // bodyTmp = JSON.parse(
      //   JSON.stringify([
      //     ...tmp_state.assessment_state.filter((el) =>
      //       el.body.find((el1) => el1.act === act[0])
      //     ),
      //   ])
      // );
      // // console.log("bodyTmp=",bodyTmp,"act[0]=",act[0]);
      // objectTmp = bodyTmp[0].body.find((el) => el.act === act[0]);
      // // console.log("objectTmp=",objectTmp);
      // arrFields_ls18_ls20 = bodyTmp[0].body.filter((el) => (el.subtopic_container_act === objectTmp.subtopic_container_act )).map((el1=>Number(el1.value)))
      // sumFields_ls18_ls20 = arrFields_ls18_ls20.reduce((a,b)=>Number(a)+Number(b),0)
      // console.log("arrFields_ls18_ls20=",arrFields_ls18_ls20,"sumFields_ls18_ls20=",sumFields_ls18_ls20);

      // objectTmp = bodyTmp[0].body.find((el) => el.act === "LS3");
      // arrFields_ls3_ls16 = bodyTmp[0].body.filter((el) => (el.subtopic_container_act === objectTmp.subtopic_container_act )).map((el1=>Number(el1.value)))
      // sumFields_ls3_ls16 = arrFields_ls3_ls16.reduce((a,b)=>Number(a)+Number(b),0)
      // console.log("arrFields_ls3_ls16=",arrFields_ls3_ls16,"sumFields_ls3_ls16=",sumFields_ls3_ls16);

      // tmp_state1=Object.assign({}, state, {
      //   assessment_state: tmp_state.assessment_state.map((el) =>
      //     el.topic !== undefined
      //       ? {
      //           ...el,
      //           body: el.body.map((el1) =>
      //             el1.shortname === "ls23" ? { ...el1, value: sumFields_ls18_ls20 + sumFields_ls3_ls16} : el1
      //           ),
      //         }
      //       : el
      //   ),
      // });
      return tmp_state;
    case "ES4_RADIO_INPUT_CHANGE":
      //disable все остальное кром exception
      tmp_state = Object.assign({}, state, {
        assessment_state: state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) => {
                  if (el1.depending === act[0]) {
                    // console.log("el1=",el1,"act[0]=",act[0])
                    if (
                      /*action.payload !== el1.exception*/ ArrInArr([action.payload], el1.exception)
                    ) {
                      return { ...el1, disabled: "true", value: "No" };
                    } else {
                      return { ...el1, disabled: "false" };
                    }
                  } else {
                    return { ...el1 };
                  }
                }),
              }
            : el
        ),
      });
      return Object.assign({}, tmp_state, {
        wasClick: state.wasClick + 1,
        assessment_state_changed: true,
        assessment_state: tmp_state.assessment_state.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body.map((el1) =>
                  el1.act === act[0] ? { ...el1, value: action.payload } : el1
                ),
              }
            : el
        ),
      });
    case "SET_SHARED_TYPE":
      let tmp_1 = Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, shared_type: action.payload },
      });
      // console.log(">>>>>>tmp_1=",tmp_1);
      return tmp_1;
    case "SET_ALL_ASSESSMENT_STATE":
      return Object.assign({}, state, { assessment_state: action.payload });
    case "SET_NEWASSESSMENT_SHARED_CLASS": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, shared_class: action.payload },
      });
    }
    case "SET_NEWASSESSMENT_SHARED_GROUP": {
      return Object.assign({}, state, {
        newAssessment: { ...state.newAssessment, shared_group: action.payload },
      });
    }

    default:
      return state;
  }
};

export default hafreducer;
