const familyHistoryJSON = {
  // section: "FR",
  // topic: "Family History",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "FS",
  topic: "FAMILY HISTORY",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "",
      shortname: "fs0",
      name: "Have any of your blood-related relatives had what you would call a significant drinking, drug use or psychiatric problem? Specifically, was there a problem that did or should have led to treatment?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FS0",
      depending: "",
      exception: "",
    },
//========================================================================================
/*                                                                                      *
 *                                     Mother's side                                    *
 *                                                                                      */
//========================================================================================


//========================================================================================
/*                                                                                      *
 *                                      Grandmother                                     *
 *                                                                                      */
//========================================================================================

{
  id: "H1",
  shortname: "fs0-1",
  name: "",
  prevalue: "",
  value: "Grandmother?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-1",
  depending: "",
  exception: "",
  link: "FS0-1-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-MOTHER",
  subtopic_name: "Mother's side",
},
    // {
    //   id: "H1",
    //   shortname: "fs0-1",
    //   name: "",
    //   prevalue: "Grandmother?",
    //   value: [true],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [""],
    //   act: "FS0-1",
    //   depending: "",
    //   exception: "",
    //   link: "FS0-1-1",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-1",
      name: "Grandmother?",
      prevalue: "Alcohol",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-1-1",
      depending: "",
      exception: "",
      link: "FS0-1-1-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-1-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-1-1-C",
      // mandatory: "true",
      depending: "FS0-1-1",
      exception: "Yes",
      link: "FS0-1-2",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },

    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-2",
      name: "Grandmother?",
      prevalue: "Drug",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-1-2",
      depending: "",
      exception: "",
      link: "FS0-1-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-2-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-1-2-C",
      // mandatory: "true",
      depending: "FS0-1-2",
      exception: "Yes",
      link: "FS0-1-3",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-3",
      name: "Grandmother?",
      prevalue: "Psych",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-1-3",
      depending: "",
      exception: "",
      link: "FS0-1-3-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H1",
      extend: "true",
      shortname: "fs0-1-3-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-1-3-C",
      // mandatory: "true",
      depending: "FS0-1-3",
      exception: "Yes",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      Grandfather                                     *
     *                                                                                      */
    //========================================================================================
    {
      id: "H2",
      shortname: "fs0-2",
      name: "",
      prevalue: "",
      value: "Grandfather?",
      disabled: "false",
      type: "label",
      // radio_options: [""],
      act: "FS0-2",
      depending: "",
      exception: "",
      link: "FS0-2-1",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "H2",
    //   shortname: "fs0-2",
    //   name: "",
    //   prevalue: "Grandfather?",
    //   value: [true],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [""],
    //   act: "FS0-2",
    //   depending: "",
    //   exception: "",
    //   link: "FS0-2-1",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H2",
      shortname: "fs0-2-1",
      name: "Grandfather?",
      prevalue: "Alcohol",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-2-1",
      depending: "",
      exception: "",
      link: "FS0-2-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H2",
      extend: "true",
      shortname: "fs0-2-1-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-2-1-C",
      // mandatory: "true",
      depending: "FS0-2-1",
      exception: "Yes",
      link: "FS0-2-2",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H2",
      extend: "true",
      shortname: "fs0-2-2",
      name: "Grandfather?",
      prevalue: "Drug",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-2-2",
      depending: "",
      exception: "",
      link: "FS0-2-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H2",
      extend: "true",
      shortname: "fs0-2-2-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-2-2-C",
      // mandatory: "true",
      depending: "FS0-2-2",
      exception: "Yes",
      link: "FS0-2-3",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H2",
      extend: "true",
      shortname: "fs0-2-3",
      name: "Grandfather?",
      prevalue: "Psych",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-2-3",
      depending: "",
      exception: "",
      link: "FS0-2-3-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H2",
      extend: "true",
      shortname: "fs0-2-3-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-2-3-C",
      // mandatory: "true",
      depending: "FS0-2-3",
      exception: "Yes",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      Mother                                     *
     *                                                                                      */
    //========================================================================================
    // {
    //   id: "F0-3",
    //   shortname: "fs0-3",
    //   name: "",
    //   prevalue: "Mother?",
    //   value: [false, false, false],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: ["Alcohol", "Drug", "Psych"],
    //   act: "FS0-3",
    //   depending: "",
    //   exception: "",
    //   link: "",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H3",
      shortname: "fs0-3",
      name: "",
      prevalue: "",
      value: "Mother?",
      disabled: "false",
      type: "label",
      // radio_options: [""],
      act: "FS0-3",
      depending: "",
      exception: "",
      link: "FS0-3-1",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "H3",
    //   shortname: "fs0-3",
    //   name: "",
    //   prevalue: "Mother?",
    //   value: [true],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [""],
    //   act: "FS0-3",
    //   depending: "",
    //   exception: "",
    //   link: "FS0-3-1",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H3",
      shortname: "fs0-3-1",
      name: "Mother?",
      prevalue: "Alcohol",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-3-1",
      depending: "",
      exception: "",
      link: "FS0-3-1-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H3",
      extend: "true",
      shortname: "fs0-3-1-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-3-1-C",
      // mandatory: "true",
      depending: "FS0-3-1",
      exception: "Yes",
      link: "FS0-3-2",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H3",
      extend: "true",
      shortname: "fs0-3-2",
      name: "Mother?",
      prevalue: "Drug",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-3-2",
      depending: "",
      exception: "",
      link: "FS0-3-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H3",
      extend: "true",
      shortname: "fs0-3-2-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-3-2-C",
      // mandatory: "true",
      depending: "FS0-3-2",
      exception: "Yes",
      link: "FS0-3-3",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H3",
      extend: "true",
      shortname: "fs0-3-3",
      name: "Mother?",
      prevalue: "Psych",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-3-3",
      depending: "",
      exception: "",
      link: "FS0-3-3-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H3",
      extend: "true",
      shortname: "fs0-3-3-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-3-3-C",
      // mandatory: "true",
      depending: "FS0-3-3",
      exception: "Yes",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      Aunt                                     *
     *                                                                                      */
    //========================================================================================
    // {
    //   id: "F0-4",
    //   shortname: "fs0-4",
    //   name: "",
    //   prevalue: "Any Aunts?",
    //   value: [false, false, false],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: ["Alcohol", "Drug", "Psych"],
    //   act: "FS0-4",
    //   depending: "",
    //   exception: "",
    //   link: "",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H4",
      shortname: "fs0-4",
      name: "",
      prevalue: "",
      value: "Any Aunts?",
      disabled: "false",
      type: "label",
      // radio_options: [""],
      act: "FS0-4",
      depending: "",
      exception: "",
      link: "FS0-4-1",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "H4",
    //   shortname: "fs0-4",
    //   name: "",
    //   prevalue: "Any Aunts?",
    //   value: [true],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [""],
    //   act: "FS0-4",
    //   depending: "",
    //   exception: "",
    //   link: "FS0-4-1",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H4",
      shortname: "fs0-4-1",
      name: "Any Aunts?",
      prevalue: "Alcohol",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-4-1",
      depending: "",
      exception: "",
      link: "FS0-4-1-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H4",
      extend: "true",
      shortname: "fs0-4-1-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-4-1-C",
      // mandatory: "true",
      depending: "FS0-4-1",
      exception: "Yes",
      link: "FS0-4-2",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H4",
      extend: "true",
      shortname: "fs0-4-2",
      name: "Any Aunts?",
      prevalue: "Drug",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-4-2",
      depending: "",
      exception: "",
      link: "FS0-4-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H4",
      extend: "true",
      shortname: "fs0-4-2-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-4-2-C",
      // mandatory: "true",
      depending: "FS0-4-2",
      exception: "Yes",
      link: "FS0-4-3",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H4",
      extend: "true",
      shortname: "fs0-4-3",
      name: "Any Aunts?",
      prevalue: "Psych",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-4-3",
      depending: "",
      exception: "",
      link: "FS0-4-3-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H4",
      extend: "true",
      shortname: "fs0-4-3-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-4-3-C",
      // mandatory: "true",
      depending: "FS0-4-3",
      exception: "Yes",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      Uncle                                     *
     *                                                                                      */
    //========================================================================================
    // {
    //   id: "F0-5",
    //   shortname: "fs0-5",
    //   name: "",
    //   prevalue: "Any Uncles?",
    //   value: [false, false, false],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: ["Alcohol", "Drug", "Psych"],
    //   act: "FS0-5",
    //   depending: "",
    //   exception: "",
    //   link: "",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H5",
      shortname: "fs0-5",
      name: "",
      prevalue: "",
      value: "Any Uncles?",
      disabled: "false",
      type: "label",
      // radio_options: [""],
      act: "FS0-5",
      depending: "",
      exception: "",
      link: "FS0-5-1",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "H5",
    //   shortname: "fs0-5",
    //   name: "",
    //   prevalue: "Any Uncles?",
    //   value: [true],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [""],
    //   act: "FS0-5",
    //   depending: "",
    //   exception: "",
    //   link: "FS0-5-1",
    //   subtopic_container_act: "FS0",
    //   suptopic_code: "FS0-MOTHER",
    //   subtopic_name: "Mother's side",
    // },
    {
      id: "H5",
      shortname: "fs0-5-1",
      name: "Any Uncles?",
      prevalue: "Alcohol",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-5-1",
      depending: "",
      exception: "",
      link: "FS0-5-1-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H5",
      extend: "true",
      shortname: "fs0-5-1-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-5-1-C",
      // mandatory: "true",
      depending: "FS0-5-1",
      exception: "Yes",
      link: "FS0-5-2",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H5",
      extend: "true",
      shortname: "fs0-5-2",
      name: "Any Uncles?",
      prevalue: "Drug",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-5-2",
      depending: "",
      exception: "",
      link: "FS0-5-2-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H5",
      extend: "true",
      shortname: "fs0-5-2-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-5-2-C",
      // mandatory: "true",
      depending: "FS0-5-2",
      exception: "Yes",
      link: "FS0-5-3",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H5",
      extend: "true",
      shortname: "fs0-5-3",
      name: "Any Uncles?",
      prevalue: "Psych",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
      act: "FS0-5-3",
      depending: "",
      exception: "",
      link: "FS0-5-3-C",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
    {
      id: "H5",
      extend: "true",
      shortname: "fs0-5-3-c",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FS0-5-3-C",
      // mandatory: "true",
      depending: "FS0-5-3",
      exception: "Yes",
      subtopic_container_act: "FS0",
      suptopic_code: "FS0-MOTHER",
      subtopic_name: "Mother's side",
    },
//========================================================================================
/*                                                                                      *
 *                                     Father's side                                    *
 *                                                                                      */
//========================================================================================

//========================================================================================
/*                                                                                      *
 *                                      Grandmother                                     *
 *                                                                                      */
//========================================================================================
{
  id: "H6",
  shortname: "fs0-6",
  name: "",
  prevalue: "",
  value: "Grandmother?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-6",
  depending: "",
  exception: "",
  link: "FS0-6-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
// {
//   id: "H6",
//   shortname: "fs0-6",
//   name: "",
//   prevalue: "Grandmother?",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-6",
//   depending: "",
//   exception: "",
//   link: "FS0-6-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-1",
  name: "Grandmother?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-6-1",
  depending: "",
  exception: "",
  link: "FS0-6-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-6-1-C",
  // mandatory: "true",
  depending: "FS0-6-1",
  exception: "Yes",
  link: "FS0-6-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-2",
  name: "Grandmother?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-6-2",
  depending: "",
  exception: "",
  link: "FS0-6-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-6-2-C",
  // mandatory: "true",
  depending: "FS0-6-2",
  exception: "Yes",
  link: "FS0-6-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-3",
  name: "Grandmother?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-6-3",
  depending: "",
  exception: "",
  link: "FS0-6-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H6",
  extend: "true",
  shortname: "fs0-6-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-6-3-C",
  // mandatory: "true",
  depending: "FS0-6-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
//========================================================================================
/*                                                                                      *
 *                                      Grandfather                                     *
 *                                                                                      */
//========================================================================================
{
  id: "H7",
  shortname: "fs0-7",
  name: "",
  prevalue: "",
  value: "Grandfather?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-7",
  depending: "",
  exception: "",
  link: "FS0-7-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
// {
//   id: "H7",
//   shortname: "fs0-7",
//   name: "",
//   prevalue: "Grandfather?",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-7",
//   depending: "",
//   exception: "",
//   link: "FS0-7-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H7",
  shortname: "fs0-7-1",
  name: "Grandfather?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-7-1",
  depending: "",
  exception: "",
  link: "FS0-7-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H7",
  extend: "true",
  shortname: "fs0-7-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-7-1-C",
  // mandatory: "true",
  depending: "FS0-7-1",
  exception: "Yes",
  link: "FS0-7-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H7",
  extend: "true",
  shortname: "fs0-7-2",
  name: "Grandfather?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-7-2",
  depending: "",
  exception: "",
  link: "FS0-7-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H7",
  extend: "true",
  shortname: "fs0-7-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-7-2-C",
  // mandatory: "true",
  depending: "FS0-7-2",
  exception: "Yes",
  link: "FS0-7-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H7",
  extend: "true",
  shortname: "fs0-7-3",
  name: "Grandfather?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-7-3",
  depending: "",
  exception: "",
  link: "FS0-7-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H7",
  extend: "true",
  shortname: "fs0-7-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-7-3-C",
  // mandatory: "true",
  depending: "FS0-7-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
//========================================================================================
/*                                                                                      *
 *                                      Father                                     *
 *                                                                                      */
//========================================================================================
// {
//   id: "F0-3",
//   shortname: "fs0-3",
//   name: "",
//   prevalue: "Father?",
//   value: [false, false, false],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: ["Alcohol", "Drug", "Psych"],
//   act: "FS0-3",
//   depending: "",
//   exception: "",
//   link: "",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H8",
  shortname: "fs0-8",
  name: "",
  prevalue: "",
  value: "Father?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-8",
  depending: "",
  exception: "",
  link: "FS0-8-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
// {
//   id: "H8",
//   shortname: "fs0-8",
//   name: "",
//   prevalue: "Father?",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-8",
//   depending: "",
//   exception: "",
//   link: "FS0-8-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H8",
  shortname: "fs0-8-1",
  name: "Father?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-8-1",
  depending: "",
  exception: "",
  link: "FS0-8-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H8",
  extend: "true",
  shortname: "fs0-8-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-8-1-C",
  // mandatory: "true",
  depending: "FS0-8-1",
  exception: "Yes",
  link: "FS0-8-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H8",
  extend: "true",
  shortname: "fs0-8-2",
  name: "Father?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-8-2",
  depending: "",
  exception: "",
  link: "FS0-8-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H8",
  extend: "true",
  shortname: "fs0-8-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-8-2-C",
  // mandatory: "true",
  depending: "FS0-8-2",
  exception: "Yes",
  link: "FS0-8-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H8",
  extend: "true",
  shortname: "fs0-8-3",
  name: "Father?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-8-3",
  depending: "",
  exception: "",
  link: "FS0-8-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H8",
  extend: "true",
  shortname: "fs0-8-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-8-3-C",
  // mandatory: "true",
  depending: "FS0-8-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
//========================================================================================
/*                                                                                      *
 *                                      Aunt                                     *
 *                                                                                      */
//========================================================================================
// {
//   id: "F0-4",
//   shortname: "fs0-4",
//   name: "",
//   prevalue: "Any Aunts?",
//   value: [false, false, false],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: ["Alcohol", "Drug", "Psych"],
//   act: "FS0-4",
//   depending: "",
//   exception: "",
//   link: "",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H9",
  shortname: "fs0-9",
  name: "",
  prevalue: "",
  value: "Any Aunts?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-9",
  depending: "",
  exception: "",
  link: "FS0-9-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
// {
//   id: "H9",
//   shortname: "fs0-9",
//   name: "",
//   prevalue: "Any Aunts?",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-9",
//   depending: "",
//   exception: "",
//   link: "FS0-9-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H9",
  shortname: "fs0-9-1",
  name: "Any Aunts?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-9-1",
  depending: "",
  exception: "",
  link: "FS0-9-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H9",
  extend: "true",
  shortname: "fs0-9-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-9-1-C",
  // mandatory: "true",
  depending: "FS0-9-1",
  exception: "Yes",
  link: "FS0-9-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H9",
  extend: "true",
  shortname: "fs0-9-2",
  name: "Any Aunts?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-9-2",
  depending: "",
  exception: "",
  link: "FS0-9-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H9",
  extend: "true",
  shortname: "fs0-9-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-9-2-C",
  // mandatory: "true",
  depending: "FS0-9-2",
  exception: "Yes",
  link: "FS0-9-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H9",
  extend: "true",
  shortname: "fs0-9-3",
  name: "Any Aunts?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-9-3",
  depending: "",
  exception: "",
  link: "FS0-9-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H9",
  extend: "true",
  shortname: "fs0-9-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-9-3-C",
  // mandatory: "true",
  depending: "FS0-9-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
//========================================================================================
/*                                                                                      *
 *                                      Uncle                                     *
 *                                                                                      */
//========================================================================================
// {
//   id: "F0-5",
//   shortname: "fs0-5",
//   name: "",
//   prevalue: "Any Uncles?",
//   value: [false, false, false],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: ["Alcohol", "Drug", "Psych"],
//   act: "FS0-5",
//   depending: "",
//   exception: "",
//   link: "",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H10",
  shortname: "fs0-10",
  name: "",
  prevalue: "",
  value: "Any Uncles?",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-10",
  depending: "",
  exception: "",
  link: "FS0-10-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
// {
//   id: "H10",
//   shortname: "fs0-10",
//   name: "",
//   prevalue: "Any Uncles?",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-10",
//   depending: "",
//   exception: "",
//   link: "FS0-10-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-FATHER",
//   subtopic_name: "Father's side",
// },
{
  id: "H10",
  shortname: "fs0-10-1",
  name: "Any Uncles?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-10-1",
  depending: "",
  exception: "",
  link: "FS0-10-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H10",
  extend: "true",
  shortname: "fs0-10-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-10-1-C",
  // mandatory: "true",
  depending: "FS0-10-1",
  exception: "Yes",
  link: "FS0-10-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H10",
  extend: "true",
  shortname: "fs0-10-2",
  name: "Any Uncles?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-10-2",
  depending: "",
  exception: "",
  link: "FS0-10-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H10",
  extend: "true",
  shortname: "fs0-10-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-10-2-C",
  // mandatory: "true",
  depending: "FS0-10-2",
  exception: "Yes",
  link: "FS0-10-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H10",
  extend: "true",
  shortname: "fs0-10-3",
  name: "Any Uncles?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-10-3",
  depending: "",
  exception: "",
  link: "FS0-10-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
{
  id: "H10",
  extend: "true",
  shortname: "fs0-10-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-10-3-C",
  // mandatory: "true",
  depending: "FS0-10-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-FATHER",
  subtopic_name: "Father's side",
},
//========================================================================================
/*                                                                                      *
  *                                      Sibling                                     *
  *                                                                                      */
//========================================================================================
//========================================================================================
/*                                                                                      *
 *                                      Brother                                     *
 *                                                                                      */
//========================================================================================

// {
//   id: "F0-11",
//   shortname: "fs0-11",
//   name: "",
//   prevalue: "Brother",
//   value: [false, false, false],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: ["Alcohol", "Drug", "Psych"],
//   act: "FS0-11",
//   depending: "",
//   exception: "",
//   link: "",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-SIBLINGS",
//   subtopic_name: "Any Siblings?",
// },
{
  id: "H11",
  shortname: "fs0-11",
  name: "",
  prevalue: "",
  value: "Brother",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-11",
  depending: "",
  exception: "",
  link: "FS0-11-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
// {
//   id: "H11",
//   shortname: "fs0-11",
//   name: "",
//   prevalue: "Brother",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-11",
//   depending: "",
//   exception: "",
//   link: "FS0-11-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-SIBLINGS",
//   subtopic_name: "Any Siblings?",
// },
{
  id: "H11",
  shortname: "fs0-11-1",
  name: "Brother?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-11-1",
  depending: "",
  exception: "",
  link: "FS0-11-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H11",
  extend: "true",
  shortname: "fs0-11-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-11-1-C",
  // mandatory: "true",
  depending: "FS0-11-1",
  exception: "Yes",
  link: "FS0-11-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H11",
  extend: "true",
  shortname: "fs0-11-2",
  name: "Brother?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-11-2",
  depending: "",
  exception: "",
  link: "FS0-11-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H11",
  extend: "true",
  shortname: "fs0-11-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-11-2-C",
  // mandatory: "true",
  depending: "FS0-11-2",
  exception: "Yes",
  link: "FS0-11-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H11",
  extend: "true",
  shortname: "fs0-11-3",
  name: "Brother?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-11-3",
  depending: "",
  exception: "",
  link: "FS0-11-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H11",
  extend: "true",
  shortname: "fs0-11-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-11-3-C",
  // mandatory: "true",
  depending: "FS0-11-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},

//========================================================================================
/*                                                                                      *
 *                                      Sister                                     *
 *                                                                                      */
//========================================================================================

// {
//   id: "F0-12",
//   shortname: "fs0-12",
//   name: "",
//   prevalue: "Sister",
//   value: [false, false, false],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: ["Alcohol", "Drug", "Psych"],
//   act: "FS0-12",
//   depending: "",
//   exception: "",
//   link: "",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-SIBLINGS",
//   subtopic_name: "Any Siblings?",
// },
{
  id: "H12",
  shortname: "fs0-12",
  name: "",
  prevalue: "",
  value: "Sister",
  disabled: "false",
  type: "label",
  // radio_options: [""],
  act: "FS0-12",
  depending: "",
  exception: "",
  link: "FS0-12-1",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
// {
//   id: "H12",
//   shortname: "fs0-12",
//   name: "",
//   prevalue: "Sister",
//   value: [true],
//   disabled: "false",
//   type: "checkbox",
//   radio_options: [""],
//   act: "FS0-12",
//   depending: "",
//   exception: "",
//   link: "FS0-12-1",
//   subtopic_container_act: "FS0",
//   suptopic_code: "FS0-SIBLINGS",
//   subtopic_name: "Any Siblings?",
// },
{
  id: "H12",
  shortname: "fs0-12-1",
  name: "Sister?",
  prevalue: "Alcohol",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-12-1",
  depending: "",
  exception: "",
  link: "FS0-12-1-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H12",
  extend: "true",
  shortname: "fs0-12-1-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-12-1-C",
  // mandatory: "true",
  depending: "FS0-12-1",
  exception: "Yes",
  link: "FS0-12-2",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H12",
  extend: "true",
  shortname: "fs0-12-2",
  name: "Sister?",
  prevalue: "Drug",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-12-2",
  depending: "",
  exception: "",
  link: "FS0-12-2-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H12",
  extend: "true",
  shortname: "fs0-12-2-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-12-2-C",
  // mandatory: "true",
  depending: "FS0-12-2",
  exception: "Yes",
  link: "FS0-12-3",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H12",
  extend: "true",
  shortname: "fs0-12-3",
  name: "Sister?",
  prevalue: "Psych",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No", "Uncertain or don't know", "Never was a relative"],
  act: "FS0-12-3",
  depending: "",
  exception: "",
  link: "FS0-12-3-C",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "H12",
  extend: "true",
  shortname: "fs0-12-3-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS0-12-3-C",
  // mandatory: "true",
  depending: "FS0-12-3",
  exception: "Yes",
  subtopic_container_act: "FS0",
  suptopic_code: "FS0-SIBLINGS",
  subtopic_name: "Any Siblings?",
},
{
  id: "F-COMMENT",
  shortname: "fs-comment",
  access: "private",
  name: "FAMILY HISTORY COMMENTS",
  prevalue: "(Include the question number with your notes))",
  value: "",
  disabled: "false",
  type: "editor",
  act: "FS-COMMENT",
  depending: "",
  exception: "",
},
// {
//   id: "F1",
//   shortname: "fr08-00",
//   name: "Marital Status:",
//   prevalue: "",
//   value: "",
//   disabled: "true",
//   type: "text",
//   act: "FR08-00",
//   link: "FR08-00-C",
//   depending: "",
//   exception: "",
// },
{
  id: "F1",
  shortname: "demo1",
  name: "Marital Status:",
  prevalue: "",
  value: "",
  disabled: "true",
  type: "text",
  act: "DEMO1",
  link: "FR08-00-C",
  depending: "",
  exception: "",
},
{
  id: "F1.c",
  extend: "true",
  shortname: "fr08-00-c",
  name: "",
  prevalue: "Comment:",
  value: "",
  disabled: "false",
  type: "text",
  act: "FR08-00-C",
  // mandatory: "true",
  depending: "DEMO1",
  exception: "common-law marriage",
},
{
  id: "F2",
  onlyasi: "", //F2
  shortname: "fs2",
  name: "How long have you been in this marital status? Refers to F1. If never married, then since age 18.",
  prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
  value: "",
  value2: "",
  disabled: "false",
  type: "year_month",
  act: "FS2",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F3",
  shortname: "fs3",
  name: "Are you satisfied with this situation?",
  prevalue: "Satisfied=client generally liking the situation. Refers to F1 and F2.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No","Indifferent","Yes"],
  act: "FS3",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F4",
  shortname: "fs4",
  name: "Usual living arrangements (past 3 years)",
  prevalue: "Choose arrangements most representative of the past 3 years. If there is an even split in time between these arrangements, choose the most recent arrangemen.",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "1 - With sexual partner & children",
    "2 - With sexual partner alone",
    "3 - With children alone",
    "4 - With parents",
    "5 - With family",
    "6 - With friends",
    "7 - Alone",
    "8 - Controlled environment",
    "9 - No stable arrangement",
  ],
  act: "FS4",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F5",
  onlyasi: "",  //F5
  shortname: "fs5",
  name: "How long have you lived in these arrangements? If with parents or family, since age 18. Code years and months living in arrangements from F4.",
  prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
  value: "",
  value2: "",
  disabled: "false",
  type: "year_month",
  act: "FS5",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F6",
  shortname: "fs6",
  name: "Are you satisfied with these arrangements?",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No","Indifferent","Yes"],
  act: "FS6",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "",
  shortname: "fs7-fs8-subtopic",
  name: "Do you live with anyone who:",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FS7-FS8-ACT",
  depending: "",
  exception: "",
},
{
  id: "F7",
  shortname: "fs7",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "FS7",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS7-FS8-ACT",
  suptopic_code: "FS7-CODE",
  subtopic_name:
    "Has a current alcohol problem?<br/><br/>",
},
{
  id: "F8",
  shortname: "fs8",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "FS8",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS7-FS8-ACT",
  suptopic_code: "FS8-CODE",
  subtopic_name:
    "Uses non-prescribed drugs? (or abuses prescription drugs) ",
},
{
  id: "F9",
  shortname: "fs9",
  name: "With whom do you spend most of your free time?",
  prevalue: "If a girlfriend/boyfriend is considered as family by patient, then they must refer to them as family throughout this section, not a friend",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Family","Friends","Alone"],
  act: "FS9",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F10",
  shortname: "fs10",
  name: "Are you satisfied with spending your free time this way?",
  prevalue: "A satisfied response must indicate that the person generally likes the situation. Refers to F9",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No","Indifferent","Yes"],
  act: "FS10",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "F11",
  onlyasi: "",  //F11
  shortname: "fs11",
  name: "How many close friends do you have?",
  prevalue: "Stress that you mean close. Exclude family members. These are “reciprocal” relationships or mutually supportive relationships.",
  value: "",
  disabled: "false",
  type: "number",
  act: "FS11",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "",
  onlyasi: "",  //subs F12 - F17
  shortname: "fs12-fs17",
  name: "Would you say you have had a close reciprocal relationship with any of the following people:",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FS12-FS17-ACT",
  depending: "",
  exception: "",
},
{
  id: "F12",
  onlyasi: "",  //F12
  shortname: "fs12",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS12",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS12-CODE",
  subtopic_name: "Mother",
},
{
  id: "F13",
  onlyasi: "",  //F13
  shortname: "fs13",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS13",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS13-CODE",
  subtopic_name: "Father",
},
{
  id: "F14",
  onlyasi: "",  //F14
  shortname: "fs14",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS14",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS14-CODE",
  subtopic_name: "Brothers/Sisters",
},
{
  id: "F15",
  onlyasi: "",  //F15
  shortname: "fs15",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS15",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS15-CODE",
  subtopic_name: "Sexual Partner/Spouse",
},
{
  id: "F16",
  onlyasi: "",  //F16
  shortname: "fs16",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS16",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS16-CODE",
  subtopic_name: "Children",
},
{
  id: "F17",
  onlyasi: "",  //F17
  shortname: "fs17",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Clearly NO for all in class", "Clearly YES for any in class", "Uncertain or don't know", "Never was a relative"],
  act: "FS17",
  depending: "",
  exception: "",
  subtopic_container_act: "FS12-FS17-ACT",
  suptopic_code: "FS17-CODE",
  subtopic_name: "Friends",
},
//========================================================================================
/*                                                                                      *
 *                                        "FS18"                                        *
 *                                                                                      */
//========================================================================================
{
  id: "F18",
  shortname: "fh18-subtopic",
  name: "Have you had significant periods in which you have experienced serious problems getting along with:...“Serious problems” mean those that endangered the relationship. A 'problem' requires contact of some sort, either by telephone or in person. If no contact, code NN.",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FH18-SUBTOPIC",
  depending: "",
  exception: "",
},
// A
{
  id: "F18",
  shortname: "fs18-1",
  name: "Past 30 day",
  prevalue: "Mother",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-1",
  depending: "",
  exception: "",
  link: "FS18-2",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
// B
{
  id: "F19",
  extend: "true",
  shortname: "fs18-2",
  name: "",
  prevalue: "Father",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-2",
  depending: "",
  exception: "",
  link: "FS18-3",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F20",
  extend: "true",
  shortname: "fs18-3",
  name: "",
  prevalue: "Brothers/Sisters",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-3",
  depending: "",
  exception: "",
  link: "FS18-4",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F21",
  extend: "true",
  shortname: "fs18-4",
  name: "",
  prevalue: "Sexual Partner/Spouse",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-4",
  depending: "",
  exception: "",
  link: "FS18-5",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F22",
  shortname: "fs18-5",
  name: "",
  prevalue: "Children",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-5",
  depending: "",
  exception: "",
  link: "FS18E-YES",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F23",
  extend: "true",
  shortname: "fs18-6",
  name: "",
  prevalue: "Other significant family",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-6",
  depending: "",
  exception: "",
  link: "FS18-6-1",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F23.1",
  extend: "true",
  shortname: "fs18-6-1",
  name: "",
  prevalue: "Specify",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS18-6-1",
  depending: "FS18-6",
  exception: "Yes",
  link: "FS18-7",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F24",
  extend: "true",
  shortname: "fs18-7",
  name: "",
  prevalue: "Close Friends",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-7",
  depending: "",
  exception: "",
  link: "FS18-8",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F25",
  extend: "true",
  shortname: "fs18-8",
  name: "",
  prevalue: "Neighbors",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-8",
  depending: "",
  exception: "",
  link: "FS18-9",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F26",
  extend: "true",
  shortname: "fs18-9",
  name: "",
  prevalue: "Co-Workers",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-9",
  depending: "",
  exception: "",
  // link: "FS18J-YES",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_30DAYS",
  subtopic_name: "Past 30 day",
},
//========================================================================================
/*                                                                                      *
 *                                      In lifetime                                     *
 *                                                                                      */
//========================================================================================
//A
{
  id: "F18",
  shortname: "fs18-10",
  name: "In lifetime",
  prevalue: "Mother",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-10",
  depending: "",
  exception: "",
  link: "FS18-11",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F19",
  extend: "true",
  shortname: "fs18-11",
  name: "",
  prevalue: "Father",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-11",
  depending: "",
  exception: "",
  link: "FS18-12",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F20",
  extend: "true",
  shortname: "fs18-12",
  name: "",
  prevalue: "Brothers/Sisters",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-12",
  depending: "",
  exception: "",
  link: "FS18-13",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F21",
  extend: "true",
  shortname: "fs18-13",
  name: "",
  prevalue: "Sexual Partner/Spouse",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-13",
  depending: "",
  exception: "",
  link: "FS18-14",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F22",
  shortname: "fs18-14",
  name: "",
  prevalue: "Children",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-14",
  depending: "",
  exception: "",
  link: "FS18-15",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F23",
  extend: "true",
  shortname: "fs18-15",
  name: "",
  prevalue: "Other significant family",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-15",
  depending: "",
  exception: "",
  link: "FS18-15-1",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F23.1",
  extend: "true",
  shortname: "fs18-15-1",
  name: "",
  prevalue: "Specify",
  value: "",
  disabled: "true",
  type: "text",
  act: "FS18-15-1",
  depending: "FS18-15",
  exception: "Yes",
  link: "FS18-16",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F24",
  extend: "true",
  shortname: "fs18-16",
  name: "",
  prevalue: "Close Friends",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-16",
  depending: "",
  exception: "",
  link: "FS18-17",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F25",
  extend: "true",
  shortname: "fs18-17",
  name: "",
  prevalue: "Neighbors",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-17",
  depending: "",
  exception: "",
  link: "FS18-18",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F26",
  extend: "true",
  shortname: "fs18-18",
  name: "",
  prevalue: "Co-Workers",
  value: "",
  disabled: "false",
  type: "radio",
  // radio_options: ["Yes", "No", "n/a"],
  radio_options: ["Yes", "No", "No contact"],
  act: "FS18-18",
  depending: "",
  exception: "",
  subtopic_container_act: "FH18-SUBTOPIC",
  suptopic_code: "FS18_LIFETIME",
  subtopic_name: "In lifetime",
},

//========================================================================================
/*                                                                                      *
 *                                        "FS27-FS29"                                        *
 *                                                                                      */
//========================================================================================
{
  id: "F27",
  onlyasi: "", // subs F27 - F29
  shortname: "fh19-subtopic",
  name: "Has anyone ever abused you?",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FS27-FS29-SUBTOPIC",
  depending: "",
  exception: "",
},
// A
{
  id: "F27",
  onlyasi: "",  //F27
  shortname: "fs19-1",
  name: "Past 30 day",
  prevalue: "Emotionally?(Make you feel bad through harsh words)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-1",
  depending: "",
  exception: "",
  link: "FS19-2",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F28",
  onlyasi: "",  //F28
  extend: "true",
  shortname: "fs19-2",
  name: "Past 30 day",
  prevalue: "Physically? (Caused you physical harm)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-2",
  depending: "",
  exception: "",
  link: "FS19-3",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F29",
  onlyasi: "",  //F29
  extend: "true",
  shortname: "fs19-3",
  name: "Past 30 day",
  prevalue: "Sexually? (Force sexual advances/acts)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-3",
  depending: "",
  exception: "",
  // link: "FS19-3",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "F27",
  onlyasi: "",  //F27
  shortname: "fs19-4",
  name: "In lifetime",
  prevalue: "Emotionally?(Make you feel bad through harsh words)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-4",
  depending: "",
  exception: "",
  link: "FS19-5",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F28",
  onlyasi: "",  //F28
  extend: "true",
  shortname: "fs19-5",
  name: "In lifetime",
  prevalue: "Physically? (Caused you physical harm)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-5",
  depending: "",
  exception: "",
  link: "FS19-6",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  id: "F29",
  onlyasi: "",  //F29
  extend: "true",
  shortname: "fs19-6",
  name: "In lifetime",
  prevalue: "Sexually? (Force sexual advances/acts)",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "FS19-6",
  depending: "",
  exception: "",
  // link: "FS19-3",
  subtopic_container_act: "FS27-FS29-SUBTOPIC",
  suptopic_code: "FS27_LIFETIME",
  subtopic_name: "In lifetime",
},
//========================================================================================
/*                                                                                      *
 *                For Questions F32 - F35, ask patient to use the Patient               *
 *                                                                                      */
//========================================================================================

{
  id: "",
  shortname: "fh19-subtopic",
  name: "For Questions F32 - F35, ask patient to use the Patient. Patient rating should refer to dissatisfaction, conflicts, or other serious problems.",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FS32-FS35-SUBTOPIC",
  depending: "",
  exception: "",
},
// A
{
  id: "F32",
  shortname: "fs32",
  name: "",
  prevalue: "Family problems",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "FS32",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS32-FS35-SUBTOPIC",
  suptopic_code: "FS32_30DAYS",
  subtopic_name: "Rating Scale How troubled or bothered have you been in the past 30 days by:",
},
{
  id: "F33",
  shortname: "fs33",
  name: "",
  prevalue: "Social problems",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "FS33",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS32-FS35-SUBTOPIC",
  suptopic_code: "FS32_30DAYS",
  subtopic_name: "Rating Scale How troubled or bothered have you been in the past 30 days by:",
},
{
  id: "F34",
  shortname: "fs34",
  name: "",
  prevalue: "Family problems",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "FS34",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS32-FS35-SUBTOPIC",
  suptopic_code: "FS34_HOW_IMPORTANT",
  subtopic_name: "How important to you now is treatment or counseling for these:<br/><br/>",
},
{
  id: "F35",
  shortname: "fs35",
  name: "",
  prevalue: "Social problems",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "FS35",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS32-FS35-SUBTOPIC",
  suptopic_code: "FS34_HOW_IMPORTANT",
  subtopic_name: "How important to you now is treatment or counseling for these:<br/><br/>",
},
//========================================================================================
/*                                                                                      *
 *                              INTERVIEWER SEVERITY RATING                             *
 *                                                                                      */
//========================================================================================

{
  id: "F36",
  onlyasi: "",  //F36
  shortname: "fs36",
  access: "private",
  name: "INTERVIEWER SEVERITY RATING",
  prevalue: "How would you rate the patient’s need for family and/or or social counseling?",
  value: "",
  disabled: "false",
  type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
  act: "FS36",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "",
  shortname: "fs37-fs38-subtopic",
  access: "private",
  name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "FS37-FS38-ACT",
  depending: "",
  exception: "",
},
{
  id: "F37",
  shortname: "fs37",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "FS37",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS37-FS38-ACT",
  suptopic_code: "FS37-CODE",
  subtopic_name:
    "Client's misrepresentation?",
},
{
  id: "F38",
  shortname: "fs38",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "FS38",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "FS37-FS38-ACT",
  suptopic_code: "FS38-CODE",
  subtopic_name:
    "Client’s inability to understand?",
},
{
  id: "F1-COMMENT",
  shortname: "fs1-comment",
  access: "private",
  name: "FAMILY/SOCIAL COMMENTS:",
  prevalue: "(Include the question number with your notes)",
  value: "",
  disabled: "false",
  type: "editor",
  act: "FS1-COMMENT",
  depending: "",
  exception: "",
},
  ],
};

export default familyHistoryJSON;
